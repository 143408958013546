// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";

// import antd
import { Form, Input, Button, Checkbox, message } from "antd";

// import assests
import Logo from "assets/images/logo.png";
import LoginImg from "assets/images/login-img.png";

// import models
import User from "models/user/user";

// import utilities
import {
  deleteQueryParam,
  handleError,
  redirectToUrl,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";

// import libraries
import qs from "qs";
import md5 from "md5";
import { gapi } from "gapi-script";
import { GoogleLogin } from "@leecheuk/react-google-login";
import { SubscriptionStatus } from "utilities/generalConstants";

export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [remember, setRemember] = useState(false);
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      });
    });
  }, []);

  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const userLogin = async (values) => {
    console.log({ remember });
    const encryptedPass = md5(values.password);
    try {
      const user = await dispatch(
        User.loginCall(values.email, encryptedPass, remember)
      );
      // const { from } = location.state || { from: { path: "/" } };
      // redirectToUrl(user.data.tenant.domainPrefix, "/dashboard");
      message.success(user?.message);
      redirectToUrl(
        User.isSuperAdmin() ? "app" : user?.data?.tenant?.domainPrefix,
        User.isSuperAdmin()
          ? "/admin-dashboard"
          : [
              SubscriptionStatus.PAUSED,
              SubscriptionStatus.CANCELLED,
              SubscriptionStatus.EXPIRED,
            ].includes(User.getSubscriptionStatus())
          ? "/manage-payments"
          : "/dashboard"
      );
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const handleLoginWithGoogle = async (response) => {
    const { tokenId } = response;
    const data = { googleTokenId: tokenId };

    try {
      const user = await dispatch(User.loginWithGoogle(data));

      redirectToUrl(
        User.isSuperAdmin() ? "app" : user?.data?.tenant?.domainPrefix,
        User.isSuperAdmin()
          ? "/admin-dashboard"
          : [
              SubscriptionStatus.PAUSED,
              SubscriptionStatus.CANCELLED,
              SubscriptionStatus.EXPIRED,
            ].includes(User.getSubscriptionStatus())
          ? "/manage-payments"
          : "/dashboard"
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleFail = (googleData) => {};
  return (
    <React.Fragment>
      <div className="auth-container login-wrap">
        <div className="login-img">
          <img src={LoginImg} />
        </div>
        <div className="login-card">
          <div className="lc-logo">
            <img src={Logo} />
          </div>
          <h4>Sign In</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={userLogin}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                type="example@example.com"
                suffix={<i className="icon-email"></i>}
                placeholder="Email"
                size="large"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item className="remember-checkbox">
              <Form.Item name="rememberMe" noStyle>
                <Checkbox
                  onChange={(e) => {
                    setRemember(e.target.checked);
                  }}
                >
                  Remember me
                </Checkbox>
                {/* <div>{}</div> */}
              </Form.Item>

              <Link to="/forgot-password" href="">
                Forgot your password?
              </Link>
            </Form.Item>
            <Form.Item>
              <Button block size="large" type="primary" htmlType="submit">
                Sign In
              </Button>
            </Form.Item>
            <Form.Item>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Sign In with Google"
                className="google-account-btn w-100"
                onSuccess={handleLoginWithGoogle}
                onFailure={handleFail}
                cookiePolicy="single_host_origin"
              ></GoogleLogin>
            </Form.Item>
            <p className="text-center mb-0">
              Don’t have an Account?
              <Link to="/signup">
                <strong>Sign up</strong>
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
// button move here
// <Button
// block
// className="google-account-btn"
// size="large"
// htmlType="submit"
// icon={<Image preview={false} src={GoogleLogo} alt="" />}
// >
// Sign In with Google
// </Button>
