import Pusher from "pusher-js";

import { useDispatch } from "react-redux";

import { useEffect } from "react";
import {
  fetchNotificationByScroll,
  fetchNotificationList,
  incrementNotificationCount,
} from "redux/notification/notificationSlice";

export default function PusherNotification() {
  const dispatch = useDispatch();

  const pusherFetch = () => {
    dispatch(incrementNotificationCount());
    dispatch(
      fetchNotificationList({
        page: 1,
        pageSize: 5,
      })
    );
    dispatch(
      fetchNotificationByScroll({
        page: 1,
        pageSize: 10,
      })
    );
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channelNotification = pusher.subscribe(`channel-admin`);

    channelNotification.bind("push-notification", (data) => {
      pusherFetch();
      console.log({ data });
    });

    return () => {
      pusher.unsubscribe("channel-admin");

      pusher.disconnect();
    };
  }, []);
}
