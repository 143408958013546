// import react & redux
import React, { useState } from "react";

// import component
import InfractionsListing from "features/common/infractionsListing";
import CountInfractionListing from "features/common/countInfractionListing";

// import antd
import { Card, Button } from "antd";

// import styles
import "features/managInfractions/manageInfractions.scss";

// import constants
import "features/managInfractions/manageInfractions.scss";
import "features/dashboard/dashboard.scss";

// import modals
import MarkInfractionModal from "features/common/markInfractionModal";
import AccommodateInfactionModal from "features/common/accomodateModal";
import EscalationModal from "features/common/escalationModal";
import generalConstants from "utilities/generalConstants";

export default function ManageInfractions() {
  const [modalMarkInfractionOpen, setModalMarkInfractionOpen] = useState(false);
  const [modalCountInfractionOpen, setCountInfractionOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [modalAccomodateOpen, setModalAccomodateOpen] = useState(false);
  const [infractionId, setinfractionId] = useState();
  const [modalEsclateOpen, setModalEsclateOpen] = useState(false);
  const [InfractionTypeId, setInfractionTypeId] = useState();

  return (
    <>
      <Card
        title="All Infractions"
        extra={
          <>
            <Button
              type="primary"
              onClick={() => setModalMarkInfractionOpen(true)}
              // onClick={() => setCountInfractionOpen(true)}
            >
              Mark Infraction
            </Button>
          </>
        }
        className="app-card manage-infractions-card"
      >
        <InfractionsListing markMode={false} />
      </Card>
      {modalMarkInfractionOpen && (
        <MarkInfractionModal
          open={modalMarkInfractionOpen}
          setOpen={setModalMarkInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          setEmployeeId={setEmployeeId}
          setinfractionId={setinfractionId}
          setInfractionTypeId={setInfractionTypeId}
        />
      )}
      {modalCountInfractionOpen && (
        <CountInfractionListing
          modalCountInfractionOpen={modalCountInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          employeeId1={employeeId}
          infractionId={infractionId}
          setModalAccomodateOpen={setModalAccomodateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
        />
      )}
      {modalAccomodateOpen && (
        <AccommodateInfactionModal
          statusId={infractionId}
          type={1}
          filters={generalConstants.defaultFilters}
          setModalAccomodateOpen={setModalAccomodateOpen}
          modalAccomodateOpen={modalAccomodateOpen}
        ></AccommodateInfactionModal>
      )}

      {modalEsclateOpen && (
        <EscalationModal
          modalEsclateOpen={modalEsclateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
          infractionTypeId={InfractionTypeId}
          infractionId={infractionId}
          filters={generalConstants.defaultFilters}
        ></EscalationModal>
      )}
    </>
  );
}
