// import react & redux
import React, { useEffect } from "react";
import { useState } from "react";

// import antd
import { PlusOutlined, CameraOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, message, Upload } from "antd";

const EmployeeProfileImage = ({ setImageFile, imageFile }) => {
  // states, variables & hooks
  const [imageUrl, setImageUrl] = useState();

  // functions
  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = (info) => {
    const imageSizeLimit = 2 * 1024 * 1024;
    const isLt2M = info.file.size < imageSizeLimit;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    } else {
      getBase64(info.file).then((value) => {
        setImageFile(info?.file);
        setImageUrl(value);
      });
    }
  };

  // useEffects
  useEffect(() => {
    imageFile && setImageUrl(imageFile);
  }, []);
  return (
    <>
      <div className="add-employee-profile-wrap">
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={() => {
            return false;
          }}
          accept="image/png, image/jpeg, image/jpg, image/svg, image/gif"
          onChange={handleChange}
        >
          {imageUrl ? (
            <Avatar size={80} src={imageUrl} />
          ) : (
            <Avatar size={80} icon={<UserOutlined />} />
          )}

          <Button
            shape="circle"
            type="primary"
            size="small"
            className="camera-icon-btn"
            icon={<CameraOutlined />}
          ></Button>
        </Upload>
      </div>
    </>
  );
};
export default EmployeeProfileImage;
