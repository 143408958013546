import React from "react";
import { Layout, Space } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import User from "models/user/user";

export default function Footer() {
  const { Footer } = Layout;
  return (
    <Footer
      style={{
        textAlign: "center",
        background: User.isTokenAvailable() ? "#e5e5e5" : "transparent",
      }}
    >
      <Space>
        <Link to="/terms-and-conditions" style={{ fontWeight: 700 }}>
          Terms And Conditions
        </Link>
        <Link to="/privacy-policy" style={{ fontWeight: 700 }}>
          Privacy Policy
        </Link>
      </Space>
      <br />
      Parallax &copy;{moment().year()}, All rights reserved
    </Footer>
  );
}
