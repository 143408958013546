import { Avatar, Badge, Card, Empty, Menu, Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import { BellOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";

import {
  allNotificationsByScroll,
  fetchNotificationByScroll,
  fetchNotificationList,
  hasMore,
} from "redux/notification/notificationSlice";
import { epochToDateFormat } from "utilities/dateUtility";
import notificationServices from "network/services/notificationService";
import NetworkCall from "network/networkCall";
import { useNavigate } from "react-router-dom";

export default function Notifications() {
  const { Link, Title } = Typography;
  const dispatch = useDispatch();
  const notificationList = useSelector(allNotificationsByScroll);
  const navigate = useNavigate();

  const hasmoreFlag = useSelector(hasMore);
  const [isLoading, setIsLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const pageNumber = useRef(0);
  console.log({ notificationList });
  const getNotification = () => {
    setIsLoading(true);
    pageNumber.current = pageNumber.current + 1;

    const body = {
      page: pageNumber.current,
      pageSize: 10,
    };

    dispatch(fetchNotificationByScroll(body));
    setIsLoading(false);
  };

  const markAllRead = async () => {
    try {
      const res = await NetworkCall.fetch(
        notificationServices.markAllAsRead({}),
        false
      );
      console.log({ res });
      dispatch(
        fetchNotificationList({
          page: 1,
          pageSize: 5,
        })
      );
      dispatch(
        fetchNotificationByScroll({
          page: 1,
          pageSize: 10,
        })
      );
      setUnreadCount(0);
    } catch (error) {}
  };

  const unReadCount = async () => {
    try {
      const res = await NetworkCall.fetch(
        notificationServices.getUnreadCount(),
        false
      );
      setUnreadCount(res?.data);
      console.log({ res });
    } catch (error) {}
  };

  const NotificationTitle = (
    <>
      <BellOutlined />
      Notifications
    </>
  );

  const markAsRead = async (id) => {
    try {
      const res = await NetworkCall.fetch(
        notificationServices.markRead({
          nrIdList: [id],
        }),
        false
      );
      console.log({ res });
      dispatch(
        fetchNotificationList({
          page: 1,
          pageSize: 5,
        })
      );
    } catch (error) {}
  };
  useEffect(() => {
    getNotification();
    unReadCount();
  }, []);

  return (
    <>
      <Card
        className="notifications-card"
        title={NotificationTitle}
        extra={
          <Link
            disabled={unreadCount === 0}
            onClick={() => {
              markAllRead();
            }}
          >
            Mark all as read
          </Link>
        }
      >
        {!isLoading ? (
          <div id="scrollableDiv">
            <InfiniteScroll
              className="card-scroll height-with-notif"
              dataLength={notificationList}
              next={getNotification}
              hasMore={hasmoreFlag}
              height={400}
              scrollableTarget="scrollableDiv"
              loader={<h4>Loading...</h4>}
            >
              <Menu className="notification-dropdwon">
                {notificationList && notificationList?.length > 0 ? (
                  notificationList.map((e) => {
                    return (
                      <Menu.Item
                        key={e?.notification?.id}
                        style={{ height: "auto" }}
                        onClick={() => {
                          markAsRead(e?.id);
                          navigate(
                            e?.notification?.type === "user"
                              ? "/manage-invite"
                              : `/admin-manage-companies/admin-company-detail/${e?.notification?.entityId}`
                          );
                        }}
                      >
                        <div className="menu-items">
                          <div>
                            {e?.entityData?.profileImage ||
                            e?.entityData?.logo ? (
                              <Avatar
                                src={
                                  e?.notification?.type === "user"
                                    ? e?.entityData?.profileImage
                                    : e?.entityData?.logo
                                }
                              />
                            ) : (
                              <Avatar icon={<UserOutlined />} />
                            )}
                          </div>
                          <div className="notification-info">
                            {" "}
                            <Title level={4}>
                              {e?.notification?.title}{" "}
                              {e?.isRead === 0 && <Badge color={"blue"} />}
                            </Title>
                            <p>{e?.notification?.text}</p>
                            <small>
                              {epochToDateFormat(e?.notification?.createdAt)}
                            </small>
                          </div>
                        </div>
                      </Menu.Item>
                    );
                  })
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Notification Found"
                  />
                )}
              </Menu>
            </InfiniteScroll>
          </div>
        ) : (
          <Spin />
        )}
      </Card>
    </>
  );
}
