import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import Request from "network/request";
import inviteServices from "network/services/inviteServices";
import { setCookie } from "utilities/generalUtility";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    allIndustries: [],
    userDetails: [],
    userRoles: [],
    invitedUserDetails: [],
    searchFilterIndicator: false,
    sortFilterIndicator: false,
    userGlobalDetail: null,
  },
  reducers: {
    saveAllIndustries: (state, action) => {
      state.allIndustries = [...action.payload];
    },
    saveUserData: (state, action) => {
      state.userDetails = { ...action.payload };
    },
    saveRoles: (state, action) => {
      state.userRoles = [...action.payload];
    },
    saveInvitedUserData: (state, action) => {
      state.invitedUserDetails = [...action.payload];
    },
    saveSearchFilterIndicator: (state, action) => {
      state.searchFilterIndicator = action.payload;
    },
    saveSortFilterIndicator: (state, action) => {
      state.sortFilterIndicator = action.payload;
    },
    saveUserGlobalDetail: (state, action) => {
      state.userGlobalDetail = action.payload;
    },
  },
});
export const fetchIndustries = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(Request.getIndustries(), false);
      const newData = response?.data;
      dispatch(saveAllIndustries(newData));
    } catch (error) {}
  };
};

export const fetchAllUsers = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(inviteServices.getUsers());
      const newData = response?.data;
      dispatch(saveInvitedUserData(newData));
    } catch (error) {}
  };
};

export const fetchRoles = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(inviteServices.getRoles());
      const newData = response?.data;
      dispatch(saveRoles(newData));
    } catch (error) {
      console.log({ error });
    }
  };
};

export const changeUserGlobalDetail = (userGlobalData, sourceObject) => {
  return async (dispatch, getState) => {
    let updatedUserGlobalData = {
      ...userGlobalData,
      data: { ...userGlobalData?.data, ...sourceObject },
    };
    setCookie(updatedUserGlobalData);
    dispatch(saveUserGlobalDetail(updatedUserGlobalData));
  };
};

export const {
  saveAllIndustries,
  saveUserData,
  saveRoles,
  saveInvitedUserData,
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
  saveUserGlobalDetail,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const allIndustries = (state) => state.user.allIndustries;
export const userDetails = (state) => state.user.userDetails;
export const allRoles = (state) => state.user.userRoles;
export const invitedUserDetails = (state) => state.user.invitedUserDetails;
export const searchFilterIndicator = (state) =>
  state.user.searchFilterIndicator;
export const sortFilterIndicator = (state) => state.user.sortFilterIndicator;
export const userGlobalDetail = (state) => state.user.userGlobalDetail;

export default userSlice.reducer;
