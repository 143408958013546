import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import { message } from "antd";
import { handleError } from "utilities/generalUtility";
import settingServices from "network/services/settingServices";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    timeframe: {},
    companypolicy: {},
  },
  reducers: {
    saveTimeframe: (state, action) => {
      state.timeframe = { ...action?.payload };
    },
    saveCompanyPolicy: (state, action) => {
      state.companypolicy = action?.payload;
    },
  },
});

export const getTimeframe = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(settingServices.getTimeframe());
      const result = response?.data;
      dispatch(saveTimeframe(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const editTimeframe = (body) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        settingServices.editTimeframe(body)
      );
      message.success("Timeframe updated successfully");
      dispatch(getTimeframe());
    } catch (error) {
      handleError(error);
    }
  };
};

export const getCompanyPolicy = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        settingServices.getCompanyPolicy()
      );
      const result = response?.data;
      dispatch(saveCompanyPolicy(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const { saveTimeframe, saveCompanyPolicy } = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const timeframe = (state) => state?.settings?.timeframe;
export const timeFrameMethod = (state) => state?.settings?.timeframe.timeframe;
export const companypolicy = (state) => state?.settings?.companypolicy;

export default settingsSlice.reducer;
