// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allRoles,
  fetchAllUsers,
  fetchRoles,
  invitedUserDetails,
} from "redux/user/userSlice";
import NetworkCall from "network/networkCall";
import inviteServices from "network/services/inviteServices";
// import antd
import {
  Card,
  Input,
  Form,
  Select,
  Button,
  Table,
  message,
  Tooltip,
  Modal,
} from "antd";

// import models
import Invite from "models/invite/Invite";
import {
  handleError,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import User from "models/user/user";

export default function SupervisorInvite() {
  // states, hooks and variables
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const roles = useSelector(allRoles);
  const userData = useSelector(invitedUserDetails);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [deleteData, setdeleteData] = useState();

  // const isSuper = User.isSuperAdmin();
  const loggedInUserId = User.getUserId();

  const removeUserId = async (id, status) => {
    try {
      const response = await NetworkCall.fetch(
        inviteServices.removeUserById(id)
      );
      message.success(response.message);
      dispatch(fetchAllUsers());
      setModalDeleteOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const columns = [
    {
      dataIndex: "email",
      key: "email",
      render: (_, data) => (
        <>
          {data?.email}
          {"  "}
          {data?.isVerified ? (
            ""
          ) : (
            <span className="invitation-text">
              (Invitation awaiting response)
            </span>
          )}
        </>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        const loggedInUser = data?.id === loggedInUserId;
        return (
          <div className="admin-invite-action">
            <Button
              disabled={loggedInUser}
              style={{
                backgroundColor: loggedInUser ? "darkgrey" : "",
                color: loggedInUser ? "white" : "",
              }}
              size="small"
              type={
                data?.role?.name
                  ? data?.role?.name === roles[0]?.name
                    ? "primary"
                    : "ghost"
                  : null
              }
              onClick={() => updateUser(data, 0)}
            >
              {roles[0]?.name}
            </Button>
            <Button
              disabled={loggedInUser}
              size="small"
              type={
                data?.role?.name
                  ? data?.role?.name === roles[1]?.name
                    ? "primary"
                    : "ghost"
                  : null
              }
              onClick={() => updateUser(data, 1)}
            >
              {roles[1]?.name}
            </Button>
            <Tooltip title={"Remove User"}>
              <Button
                disabled={loggedInUser}
                size="small"
                type="link"
                icon={
                  <i
                    className="icon-times-circle"
                    style={{
                      color: loggedInUser ? "darkgrey" : "",
                    }}
                  ></i>
                }
                onClick={() => {
                  setModalDeleteOpen(true);
                  setdeleteData(data);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // functions
  const onFinish = async (values) => {
    try {
      setBtnLoading(true);
      const res = await Invite.addUserApi(values);
      message.success(res.message);
      dispatch(fetchAllUsers());
      setBtnLoading(false);
      form.resetFields();
    } catch (err) {
      setFieldErrorsFromServer(err, form, values);
      setBtnLoading(false);
    }
  };

  const updateUser = async (values, btnId) => {
    try {
      const check = values?.role?.id === roles[btnId]?.id ? false : true;

      if (check) {
        const body = {
          roleId: roles[btnId]?.id,
        };

        const res = await Invite.updateUser(body, values?.id);
        message.success(res?.message);
        dispatch(fetchAllUsers());
      }
    } catch (err) {
      handleError(err);
    }
  };

  // useEffects
  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchAllUsers());
  }, []);

  return (
    <>
      <Card
        className="manage-invite-card app-card"
        title="Invite Admins"
        bordered={false}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            name={"email"}
            rules={[
              {
                type: "email",
                message: "The Input is not valid Email",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
          >
            <Input
              placeholder="Enter Email to Invite"
              size="middle"
              type="email"
            />
          </Form.Item>
          <Form.Item
            name={"roleId"}
            className="select-role"
            rules={[{ required: true, message: "Select any role" }]}
          >
            <Select
              size="middle"
              defaultValue="Select Role"
              // getPopupContainer={(triggerNode) => {
              //   return triggerNode.parentNode;
              // }}
              options={roles.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            loading={btnLoading}
          >
            Add
          </Button>
        </Form>
        <Table
          className="admin-invite-table"
          dataSource={userData}
          columns={columns}
          pagination={false}
          showHeader={false}
          scroll={{ x: 575 }}
        />
      </Card>
      <Modal
        title="Delete Invite"
        centered
        width={800}
        okText="Yes"
        cancelText="No"
        open={modalDeleteOpen}
        onOk={() => removeUserId(deleteData?.id, deleteData?.role?.name)}
        onCancel={() => setModalDeleteOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal accomodate-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <p>Are you sure you want to delete this Invite?</p>
      </Modal>
    </>
  );
}
