const K = {
  Network: {
    URL: {
      Base: process.env.REACT_APP_BASE_URL,
      BaseAPI: process.env.REACT_APP_BASE_API_URL,
      DomainName: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
      Timeout: process.env.REACT_APP_TIMEOUT,
      TenantURL: (domainPrefix = "") => {
        return (
          process.env.REACT_APP_PROTOCOL +
          "://" +
          domainPrefix +
          process.env.REACT_APP_TENANT_PARTIAL_URL
        );
      },
      Client: {
        BaseHost: process.env.REACT_APP_CLIENT_BASE_HOST,
        BasePort: process.env.REACT_APP_CLIENT_BASE_PORT,
        BaseTenantHost: process.env.REACT_APP_CLIENT_BASE_URL,
      },
      Protocol: process.env.REACT_APP_PROTOCOL,

      // Tenant
      Tenants: {
        GetTenant: "/tenant/get",
        SuggestDomainPrefix: "/tenants/suggest-domain-prefix",
        VerifyDomainPrefix: "/tenants/verify-domain-prefix",
      },

      // Auth
      Auth: {
        LoginUser: "/auth/login",
        ForgotPassword: "/auth/forgot-password",
        ResetPassword: "/auth/reset-password",
        SignUp: "/auth/signup",
        ChangePassword: "/auth/change-password",
        LoginWithGoogle: "/auth/google-login",
        CheckEmail: "/auth/check-email",
        SendOTP: "/auth/send-otp",
        VerifyOTP: "/auth/verify-otp",
      },

      // Invites
      Invite: {
        AddUser: "/invites/add",
        UsersList: "/invites/get",
        GetRoles: "/roles/get-all",
        RemoveUser: "/invites/delete/",
        UpdateUser: "/invites/update",
      },

      // Employees
      Employee: {
        EmployeeDetail: "/employees/get-one/",
        AddEmployee: "/employees/add-single",
        EditEmployee: "/employees/update/",
        GetEmployeeStatuses: "/employees/employment-statuses/get-all",
        GetAllEmployees: "/employees/get-all",
        markResignStatus: "/employees/mark-resigned/",
        companyPolicyStatus: "/employees/update/",
        DownloadSampleCsv: "/employees/sample-bulk-upload",
        UploadEmployees: "/employees/bulk-upload",
        GetAllEmployeeName: "/employees/get-employees-name",
      },

      History: {
        GetAllHistory: "/activity-logs/list",
        DownloadHistory: "/activity-logs/download-csv",
      },

      // Infractions
      Infraction: {
        ViewEscalation: "/infractions/get",
        GetInfractionList: "/infractions/list",
        GetInfractionTypeList: "/infraction-type/get-all",
        CreateInfraction: "/infractions/create",
        EditInfraction: "/infractions/update",
        DeleteInfraction: "/infractions/delete",
        GetEmployeeInfractionsCount:
          "/infractions/get-employee-infractions-count",
        InfractionCompare: "/infractions/compare",
        ChangeinfractionStatus: "/infractions/status",
      },
      //Plans and Payments

      Plans: {
        GetPlans: "/plans-and-payments/plans",
        GetActivePlan: "/plans-and-payments/active-plan",
        GetInvoice: "/plans-and-payments/invoice",
        CustomerPortal: "/plans-and-payments/create-customer-billing-session",
        CancelSubscription: "/plans-and-payments/cancel-subscription",
        PaymentCheckout: "/plans-and-payments/create-checkout-link",
        GetPermissions: "/plans-and-payments/get-all-permissions",
      },
      //Settings

      Settings: {
        ViewEditTimeframe: "/settings/timeframe",
        BasicCompanySetting: "/settings/get-company-settings",
        EditCompanySetting: "/settings/edit-company-settings",
        ViewCompanyPolicy: "/settings/get-document",
        UploadCompanyPolicy: "/settings/add-document",
        AddCompanyLogo: "/settings/edit-company-logo",
        ViewPersonalSettings: "/settings/get-personal-settings",
        EditPersonalSettings: "/settings/edit-personal-settings",
        EditColorBranding: "/settings/edit-branding",
        EditProfileImage: "/settings/edit-profile-image",
        SendFeedback: "/settings/send-feedback-form",
      },

      // Dashboard
      Dashboard: {
        TopOffenders: "/dashboard/top-offenders",
        CompanyAnalytics: "/dashboard/company-analytics",
        InfractionGraph: "/dashboard/company-infraction-graph",
        CompareAllInfractions: "/dashboard/compare-company-infraction",
      },

      // Admin
      // Company
      Company: {
        GetAllCompaniesList: "/super-admin/tenants/get-companies-listing",
        ExtendFreeTrial: "/super-admin/tenants/free-trial-extension",
        GetCompanyById: "/super-admin/tenants/details",
        GetCompanyRevenueData: "/super-admin/tenants/revenue-generated",
        ToggleStatus: "/super-admin/tenants/toggle-status",
        SuperAdminCompanySetting: "/super-admin/tenants/edit-company-settings",
      },

      AdminDashboard: {
        GetAdminDashboard: "/super-admin/dashboard",
      },

      // Industries
      GetIndustry: "/industries/get-all",
      GetTenant: "/tenant/get",

      Notice: {
        GetVariables: "/infraction-type/get-template-variables",
        CreateInfractionType: "/infraction-type/create",
        GetInfractionNotice: "/infraction-type/get",
        EditInfractionNotice: "/infraction-type/update",
        GenerateNotice: "/infractions/generate-notice",
        MarkEscalation: "/infractions/mark-escalaion",
        ToggleArchiveInfractionType: "/infraction-type/toggle-archive",
      },

      Notification: {
        getAll: "/notifications/get-all",
        UnseenCount: "/notifications/unseen-count",
        MarkAllseen: "/notifications/mark-all-seen",
        MarkAllRead: "/notifications/mark-all-read",
        UnreadCount: "/notifications/unread-count",
        MarkRead: "/notifications/mark-read",
      },
    },
    Method: {
      GET: "GET",
      PUT: "PUT",
      POST: "POST",
      PATCH: "PATCH",
      DELETE: "DELETE",
    },
    Header: {
      ContentType: "Content-Type",
      ApplicationJson: "application/json",
      Default: (token = "") => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      }),
      Authorization: (token = "") => ({
        Authorization: "Bearer " + token,
      }),
      Type: {
        Json: "json",
        File: "multipart/form-data",
      },
    },
    StatusCode: {
      Unauthorized: 401,
    },
  },
  Cookie: {
    Key: {
      User: "user",
      EncryptionKey: "logged_in_user",
      Token: "token",
    },
  },
  Roles: {
    Admin: "Admin",
    User: "User",
  },
  GoogleKeys: {
    ClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    SecretId: process.env.REACT_APP_GOOGLE_SECRET_ID,
  },
};

export default K;
