// IMPORT REACT & REDUX
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// IMPORT ANTD
import { Button, DatePicker, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// IMPORT UTITLITIES
import {
  dateToEpochFormat,
  endDateToEpochFormat,
  epochToDateFormat,
  epochToMomentTime,
} from "utilities/dateUtility";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
  searchFilterIndicator,
  sortFilterIndicator,
  userGlobalDetail,
} from "redux/user/userSlice";
// import { createKeybindingConfig } from "@ant-design/charts";

// RANGE PICKER
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

export default function GenericTable({
  data,
  columns,
  apiCall,
  total,
  tableProps,
  idKey,
  idValue,
  markMode,
  dashboardMode,
  startDate,
  endDate,
}) {
  // STATES, HOOKS & VARIABLES
  const dispatch = useDispatch();
  const searchIndicator = useSelector(searchFilterIndicator);
  const sortIndicator = useSelector(sortFilterIndicator);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const userGlobalData = useSelector(userGlobalDetail);
  const [clearSearchFilterFieldIndicator, setClearSearchFilterFieldIndicator] =
    useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: markMode ? 5 : 10,
    sortBy: "id",
    sortDirectionAsc: false,
    searchArray: [
      {
        key: idKey,
        value: idValue,
        type: "integer",
      },
      {
        key: dashboardMode ? "infractionStatusName" : undefined,
        value: "Pending Escalation",
        type: "string",
      },
      {
        key: dashboardMode ? "infractionDate" : undefined,
        fromValue: startDate,
        toValue: endDate,
        type: "range",
      },
    ].filter((x) => x.key),
  });
  // FUNCTIONS
  // STRING AND INTEGER TYPE FILTER PANEL WILL BE RETURNED
  const getColumnSearchProps = (dataIndex, title, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${title}`}
          value={clearSearchFilterFieldIndicator ? [] : selectedKeys[0]}
          onChange={(e) => {
            setClearSearchFilterFieldIndicator(false);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, type, close)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, type, close)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(
                clearFilters,
                selectedKeys,
                confirm,
                dataIndex,
                type,
                close
              )
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i
        className="icon-table-filter"
        style={{
          color:
            appliedFilters.includes(dataIndex) && searchIndicator
              ? userGlobalData?.data?.tenant?.themeColor || "#1a56a5"
              : "",
        }}
      ></i>
    ),
  });

  // RANGE(DATE) TYPE FILTER PANEL WILL BE RETURED
  const getColumnDateProps = (dataIndex, title, type) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <RangePicker
          format={dateFormat}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          value={
            clearSearchFilterFieldIndicator
              ? []
              : [
                  epochToMomentTime(selectedKeys[0]),
                  epochToMomentTime(selectedKeys[1]),
                ]
          }
          onChange={(date) => {
            setClearSearchFilterFieldIndicator(false);
            let dateArray = [
              dateToEpochFormat(date[0]),
              endDateToEpochFormat(date[1]),
            ];
            setSelectedKeys(date ? dateArray : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, type, close)
          }
          style={{ marginBottom: 8 }}
        />
        <br />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, type, close)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(
                clearFilters,
                selectedKeys,
                confirm,
                dataIndex,
                type,
                close
              )
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i
        className="icon-table-filter"
        style={{
          color:
            appliedFilters.includes(dataIndex) && searchIndicator
              ? userGlobalData?.data?.tenant?.themeColor || "#1a56a5"
              : "",
        }}
      ></i>
    ),
    render: (epochSeconds) => epochToDateFormat(epochSeconds),
  });

  // GET FILTERPANEL FOR COLUMNS
  function getProps(type, dataIndex, title) {
    if (type === "string" || type === "integer") {
      return getColumnSearchProps(dataIndex, title, type);
    }
    if (type === "range") {
      return getColumnDateProps(dataIndex, title, type);
    }
  }

  // SETTING FILTER OBJECT ON PAGINATION CHANGE
  const onPaginationChange = (page, pageSize) => {
    setFilters((prev) => ({ ...prev, page, pageSize }));
  };

  // SET SORTING FILTERS
  const onTableChange = (pagination, filter, sorter, extra) => {
    if (extra?.action === "sort") {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        sortBy: sorter?.column?.dataIndex,
        sortDirectionAsc: sorter?.order === "ascend" ? true : false,
      }));
      dispatch(saveSortFilterIndicator(true));
    }
  };

  // SETTING FILTERS OBJECT ON FILTER APPLIED
  const manageFilters = (
    selectedKeys,
    confirm,
    dataIndex,
    columnType,
    close
  ) => {
    let currentElement = {};
    if (columnType === "range") {
      currentElement = {
        key: dataIndex,
        fromValue: selectedKeys[0],
        toValue: selectedKeys[1],
        type: columnType,
      };
    } else {
      currentElement = {
        key: dataIndex,
        value: selectedKeys[0],
        type: columnType,
      };
    }

    let newArray = filters.searchArray;
    let index = newArray.findIndex((obj) => obj["key"] === dataIndex);
    if (selectedKeys.length === 0) {
      newArray.splice(index, 1);
    } else if (index < 0) {
      newArray.push(currentElement);
    } else {
      newArray[index] = currentElement;
    }

    // newArray IS NOW MODIFIED ARRAY
    setFilters((prev) => ({
      ...prev,
      page: 1,
      searchArray: newArray,
    }));

    close(); // CLOSING THE PANEL
  };

  // SEARCH BUTTON IS CLICKED
  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    columnType,
    close
  ) => {
    // SET THE COLOR FLAG TRUE TO SHOW COLOR OF ICON
    if (selectedKeys.length > -1) {
      dispatch(saveSearchFilterIndicator(true));
    }

    // MANAGE FILTERS
    manageFilters(selectedKeys, confirm, dataIndex, columnType, close);
    if (selectedKeys.length > 0) {
      // ADD THE DATAINDEX TO ARRAY, SO THAT THE COLOR OF FILTER ICON CHANGES
      setAppliedFilters((prevFilters) => {
        if (prevFilters.indexOf(dataIndex) < 0) {
          console.log("notExist");
          return [...prevFilters, dataIndex];
        } else {
          console.log("Exist");
          return [...prevFilters];
        }
      });
    }
  };

  // RESET BUTTON IS CLICKED
  const handleReset = (
    clearFilters,
    selectedKeys,
    confirm,
    dataIndex,
    columnType,
    close
  ) => {
    // REMOVE THE DATAINDEX FROM ARRAY, SO THAT THE COLOR OF FILTER ICON CHANGES
    let newAppliedFiltersArray = appliedFilters;
    let index = newAppliedFiltersArray.indexOf(dataIndex);
    if (index > -1) {
      newAppliedFiltersArray.splice(index, 1);
    }
    setAppliedFilters(newAppliedFiltersArray);
    clearFilters();
    selectedKeys = [];
    // MANAGE FILTERS
    manageFilters(selectedKeys, confirm, dataIndex, columnType, close);
  };

  // GET SORT ORDER IN CASE OF NO SORTING APPLIED TO CHANGE THE COLOR OF ICON
  const getColumnSortOrder = (flag) => {
    return flag ? {} : { sortOrder: null };
  };

  // UPDATED COLUMNS ARRAY WITH CUTSOM FILTERS
  const updatedColumns = columns.map((column) => {
    return {
      ...column,
      ...getProps(column.type, column.dataIndex, column.title),
      ...getColumnSortOrder(sortIndicator),
    };
  });

  // USE-EFFECTS
  useEffect(() => {
    if (markMode === true) {
      // MARK MODE IS TRUE IN CASE OF SETTING THE INFRACTION STATE FOR POPUP
      dispatch(apiCall(filters, markMode));
    } else {
      dispatch(apiCall(filters));
    }
  }, [filters]);

  useEffect(() => {
    if (searchIndicator === false) {
      setClearSearchFilterFieldIndicator(true);
    }
  }, [searchIndicator]);

  return (
    <Fragment>
      <Table
        className="app-table employees-table"
        columns={updatedColumns}
        sortDirections={["ascend", "descend", "ascend"]}
        dataSource={data} // REDUX DATA(LIST) PASSED FROM PARENT COMPONENT TO RENDER
        scroll={900}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 10,
          current: filters.page,
          pageSize: filters.pageSize,
          total: total,
          onChange: onPaginationChange,
        }}
        onChange={onTableChange}
        {...tableProps} // TABLE PROPS IF SOME ADDITIONAL PROPS REQUIRED, OTHERWISE NO ADDITIONAL PROPS WILL BE RENDERED
      />
    </Fragment>
  );
}
