import K from "utilities/constants";
import Request from "../request";

export default class notificationServices {
  static getAllNotifications(body) {
    return new Request(
      K.Network.URL.Notification.getAll,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getUnseenCount() {
    return new Request(
      K.Network.URL.Notification.UnseenCount,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static markAllSeen(body) {
    return new Request(
      K.Network.URL.Notification.MarkAllseen,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static markAllAsRead(body) {
    return new Request(
      K.Network.URL.Notification.MarkAllRead,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getUnreadCount() {
    return new Request(
      K.Network.URL.Notification.UnreadCount,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static markRead(body) {
    return new Request(
      K.Network.URL.Notification.MarkRead,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
