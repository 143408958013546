// import react & redux
import React, { useState } from "react";
import { Link } from "react-router-dom";

// import antd
import { Form, Button, message, Steps, Tooltip } from "antd";

// import libraries
import md5 from "md5";

// import utilities
import {
  handleError,
  redirectToUrl,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";

// import models
import User from "models/user/user";
import Auth from "models/auth/auth";

// import components
import PersonalInfo from "./Components/PersonalInfo";
import CompanyInfo from "./Components/CompanyInfo";
import PrimaryPersonInfo from "./Components/PrimaryPersonInfo";
import OneTimePass from "features/oneTimePass/oneTimePass";

// import assets
import LoginImg from "assets/images/login-img.png";

// import Network
import NetworkCall from "network/networkCall";
import authServices from "network/services/authServices";

export default function Signup() {
  // states, hooks and variables
  const [form] = Form.useForm();
  const [companyInfo, setCompanyInfo] = useState({
    0: {},
    1: {},
    2: {},
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [timeReset, setTimeReset] = useState(true);
  const [resendFlag, setResendFlag] = useState(true);
  const steps = [
    {
      title: "Personal Info",
      content: <PersonalInfo form={form} />,
    },
    {
      title: "Company Info",
      content: <CompanyInfo form={form} />,
    },
    // {
    //   title: "Primary Person Info",
    //   content: <PrimaryPersonInfo form={form} />,
    // },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  // functions
  const validateFields = () => {
    form.validateFields().then(async (values) => {
      setCompanyInfo({ ...companyInfo, [currentStep]: values });
      if (currentStep === 0) {
        setEmail(values?.email);
        try {
          await NetworkCall.fetch(authServices.verifyEmail(values?.email));
          setCurrentStep(currentStep + 1);
        } catch (error) {
          setFieldErrorsFromServer(error, form, values);
        }
      } else {
        if (currentStep === 1) {
          if (sendOTP()) {
            setCurrentStep(currentStep + 1);
          }
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    });
  };

  const next = async () => {
    validateFields();
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const sendOTP = async () => {
    setResendFlag(true);
    setTimeReset(!timeReset);
    try {
      const response = await Auth.sendOTP({ email });
      message.success(response?.message);
      setAccessToken(response?.data?.accessToken);
      return true;
    } catch (error) {
      handleError(error);
    }
  };

  const signUpCall = async () => {
    let { confirmpassword, password, ...companyInfo0 } = companyInfo[0];
    password = md5(password);
    let companyData = {
      password,
      ...companyInfo0,
      ...companyInfo[1],
      // ...companyInfo[2],
    };
    let form_data = new FormData();
    for (let key in companyData) {
      form_data.append(
        key,
        key === "firstName" || key === "lastName"
          ? companyData[key].trim()
          : companyData[key]
      );
    }
    try {
      const response = await User.register(form_data);
      message.success(`Company Registered Successfully`);
      // redirectToUrl(null, "/dashboard");
      redirectToUrl(response?.data?.tenant?.domainPrefix, "/dashboard");
    } catch (error) {
      handleError(error);
    }
  };

  const signUp = async () => {
    const otp = form.getFieldValue("otp");
    if (!otp) {
      message.error("OTP is required");
    } else if (otp?.length < 6) {
      message.error("Invalid OTP");
    } else {
      try {
        await Auth.verifyOTP({ otp }, accessToken);
        signUpCall();
      } catch (error) {
        handleError(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="auth-container login-wrap">
        <div className="login-img">
          <img src={LoginImg} />
        </div>
        <div className="login-card">
          <div className="lc-logo">
            <img src="images/site-logo.png" alt="" />
          </div>
          <h4>Sign Up</h4>
          {currentStep !== 2 ? (
            <>
              <Steps
                size="large"
                direction="horizontal"
                current={currentStep}
                items={items}
                className="signup-steps"
              />
              <div className="steps-content">{steps[currentStep].content}</div>
            </>
          ) : (
            <>
              <OneTimePass
                form={form}
                timeReset={timeReset}
                setResendFlag={setResendFlag}
              />
            </>
          )}
          <div className="steps-action">
            {currentStep < steps.length && (
              <Button type="primary" size="large" onClick={() => next()}>
                Next
              </Button>
            )}
            {currentStep === steps.length && (
              <Tooltip
                title={
                  !resendFlag
                    ? "Your OTP has been expired, Please click on Resend"
                    : ""
                }
              >
                <Button
                  type="primary"
                  onClick={() => signUp()}
                  size="large"
                  disabled={!resendFlag}
                  block
                >
                  Sign Up
                </Button>
              </Tooltip>
            )}
            {currentStep > 0 && currentStep < 2 ? (
              <Button onClick={() => prev()} size="large">
                Back
              </Button>
            ) : null}
          </div>
          {currentStep !== 2 ? (
            <p className="text-center mb-0">
              Already have an account?
              <Link to="/login">
                <strong>Sign In</strong>
              </Link>
            </p>
          ) : (
            <p className="text-center mb-0">
              Didn't received it yet?
              <Button
                className="px-0 ml-1"
                type="link"
                disabled={resendFlag}
                onClick={() => sendOTP()}
              >
                <strong>Resend</strong>
              </Button>
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
