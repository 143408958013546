import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import dashboardServices from "network/services/dashboardServices";
import { handleError } from "utilities/generalUtility";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    compareInfractions: [],
    topOffenders: [],
    analytics: [],
    graphData: [],
    totalEmployee: 0,
  },
  reducers: {
    saveCompareInfractions: (state, action) => {
      state.compareInfractions = [...action.payload];
    },
    saveTopOffenders: (state, action) => {
      state.topOffenders = [...action.payload];
    },
    saveAnalytics: (state, action) => {
      state.analytics = [...action.payload];
    },
    saveGraphData: (state, action) => {
      state.graphData = [...action.payload];
    },
    saveTotal: (state, action) => {
      state.totalEmployee = action.payload;
    },
  },
});

export const fetchCompareInfractions = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        dashboardServices.compareAllInfractions(payload)
      );
      const result = response?.data;
      dispatch(saveCompareInfractions(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchTopOffenders = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        dashboardServices.getTopOffenders(payload)
      );
      dispatch(
        saveTopOffenders(
          response?.data.sort((a, b) => b.InfractionCount - a.InfractionCount)
        )
      );
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchCompanyAnalytics = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        dashboardServices.getCompanyAnalytics(payload)
      );
      dispatch(saveAnalytics(response?.data));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchGraphData = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        dashboardServices.getDashboardGraph(payload)
      );
      dispatch(saveGraphData(response?.data?.companyInfractionGraph));
      dispatch(saveTotal(response?.data?.totalEmployees));
    } catch (error) {
      handleError(error);
    }
  };
};

export const {
  saveCompareInfractions,
  saveTopOffenders,
  saveAnalytics,
  saveTotal,
  saveGraphData,
} = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const compareInfractions = (state) => state.dashboard.compareInfractions;
export const topOffenders = (state) => state.dashboard.topOffenders;
export const analytics = (state) => state.dashboard.analytics;
export const graphData = (state) => state.dashboard.graphData;
export const totalEmployee = (state) => state.dashboard.totalEmployee;

export default dashboardSlice.reducer;
