import K from "utilities/constants";
import Request from "../request";

export default class historyServices {
  static getAllHistoryList(body) {
    return new Request(
      K.Network.URL.History.GetAllHistory,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
