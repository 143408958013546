import K from "utilities/constants";
import Request from "../request";

export default class employeeServices {
  static addSingleEmployee(body) {
    return new Request(
      K.Network.URL.Employee.AddEmployee,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static editSingleEmployee(id, body) {
    return new Request(
      K.Network.URL.Employee.EditEmployee + id,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static getEmployeeStatuses() {
    return new Request(
      K.Network.URL.Employee.GetEmployeeStatuses,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getAllEmployeesList(body) {
    return new Request(
      K.Network.URL.Employee.GetAllEmployees,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getEmployeeDetailById(id) {
    return new Request(
      K.Network.URL.Employee.EmployeeDetail + id,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      -{},
      false
    );
  }

  static employeeResignStatus(employeeId, body) {
    return new Request(
      K.Network.URL.Employee.markResignStatus + employeeId,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static markCompanyPolicyStatus(employeeId, body) {
    return new Request(
      K.Network.URL.Employee.companyPolicyStatus + employeeId,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static downloadEmployeeHistory(body) {
    return new Request(
      K.Network.URL.History.DownloadHistory,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static downloadSampleCsv() {
    return new Request(
      K.Network.URL.Employee.DownloadSampleCsv,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static uploadBulkEmployees(body) {
    return new Request(
      K.Network.URL.Employee.UploadEmployees,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static getEmployeeNames() {
    return new Request(
      K.Network.URL.Employee.GetAllEmployeeName,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
