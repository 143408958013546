// import react & redux
import React, { useState } from "react";

// import antd
import { Form, Input, Row, Col, Popover } from "antd";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";

// import utitlities
import {
  onlyAlphabetsAndSpecialChars,
  passwordMessage,
  passwordRegex,
  phoneRegex,
} from "utilities/regexUtility";
import PasswordValidation from "./PasswordValidation";

export default function PersonalInfo({ form }) {
  const [password, setPassword] = useState();
  return (
    <React.Fragment>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              name={"firstName"}
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input first name",
                },

                { min: 3, message: "First name must be minimum 3 characters." },
                {
                  max: 14,
                  message: "First name should be less than 15 character",
                },
                {
                  pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                  message: "Field does not accept numbers and leading spaces",
                },
              ]}
            >
              <Input
                suffix={<UserOutlined />}
                placeholder="Brandon"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              name={"lastName"}
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input last name",
                },
                {
                  pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                  message: "Field does not accept numbers and leading spaces",
                },
                { min: 3, message: "Last name must be minimum 3 characters." },
                {
                  max: 14,
                  message: "Last name should be less than 15 character",
                },
              ]}
            >
              <Input
                type="name"
                suffix={<UserOutlined />}
                placeholder="Carder"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "Email must be valid",
                },
              ]}
            >
              <Input
                type="email"
                suffix={<i className="icon-email"></i>}
                placeholder="example@example.com"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input phone number",
                },
                {
                  pattern: new RegExp(phoneRegex),
                  message: "Phone number must be valid and without dashes",
                },
              ]}
            >
              <Input
                type="phone"
                suffix={<PhoneOutlined />}
                placeholder="202-555-2849"
                size="large"
              />
            </Form.Item>
          </Col>
          {/*  */}

          {/*  */}

          <Col className="gutter-row" xs={24} sm={12}>
            <Popover
              content={<PasswordValidation password={password} />}
              trigger={"focus"}
              placement="leftTop"
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password",
                  },
                  {
                    pattern: new RegExp(passwordRegex),
                    message: "", //passwordMessage,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  autoComplete="false"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            </Popover>
          </Col>

          {/*  */}
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              label="Confirm Password"
              name="confirmpassword"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                // {
                //   pattern: new RegExp(passwordRegex),
                //   message: passwordMessage,
                // },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password does not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                size="large"
                autoComplete="false"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}
