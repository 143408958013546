// import react & redux
import React from "react";

// import antd
import { Card, Button, Input, Form, message } from "antd";

// import styles
import "features/settings/companyFeedback/companyFeedback.scss";
import { useForm } from "antd/lib/form/Form";
import { setFieldErrorsFromServer } from "utilities/generalUtility";
import Settings from "models/setting/setting";
import { feadbackRegex } from "utilities/regexUtility";

const { TextArea } = Input;

export default function CompanyFeedback() {
  const [form] = useForm();
  const handleSubmitFeedback = async (values) => {
    try {
      const response = await Settings.submitFeedback(values);
      message.success(response?.message);
      form.resetFields();
    } catch (error) {
      setFieldErrorsFromServer(form, error, values);
    }
  };
  return (
    <>
      <Card title="Feedback" className="app-card feedback-card">
        <Form layout="vertical" form={form} onFinish={handleSubmitFeedback}>
          <Form.Item
            name={"feedback"}
            rules={[
              {
                required: true,
                message: "Feedback Required !",
              },
              {
                pattern: new RegExp(feadbackRegex),
                message:
                  "Minimum 5 characters feedback required, no leading or trailing spaces.",
              },
            ]}
          >
            <TextArea
              style={{ height: "230px", fontSize: "16px" }}
              placeholder="Enter your feedback here ..."
            />
          </Form.Item>
        </Form>
        <div className="form-btns">
          <Button ghost onClick={() => form.resetFields()}>
            Clear
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Submit Feedback
          </Button>
        </div>
      </Card>
    </>
  );
}
