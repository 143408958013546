// import react & redux
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allInfractionsType,
  getInfractionsType,
  infractionPaginationData,
} from "redux/infraction/infractionSlice";

// import components
import GenericTable from "features/common/genericTable";

// import styles
import "features/settings/infractionsList/infractionsList.scss";

// import antd
import { Card, Input, Modal, Button, message, Tag, Tooltip } from "antd";
import { handleError } from "utilities/generalUtility";
import Notice from "models/notice/notice";
import generalConstants from "utilities/generalConstants";
import { UndoOutlined } from "@ant-design/icons";

const { Search } = Input;
export default function InfractionsList() {
  // states, variables & hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false);
  const [infractionTypeId, setInfractionTypeId] = useState(null);
  const [isArchive, setIsArchive] = useState(false);
  const data = useSelector(allInfractionsType);
  const infractionPagination = useSelector(infractionPaginationData);

  const tableProps = {
    style: { cursor: "pointer" },
    className: "app-table infraction-list-table",
    scroll: {
      x: 900,
    },
    onRow: (record, i) => {
      return {
        onClick: async (event) => {
          navigate(`/settings/infractions-list/view-infraction/${record.id}`);
        },
      };
    },
  };

  const infractionListCol = [
    {
      title: "Infraction Type",
      dataIndex: "name",
      type: "string",
      sorter: true,
    },
    {
      title: "No. of Templates",
      dataIndex: "templateCount",
      align: "center",
      type: "string",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      type: "range",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "isArchive",
      sorter: true,
      render: (isArchive) => (
        <Tag color={isArchive ? "red" : "green"}>
          {isArchive ? "Archived" : "Active"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",

      render: (_, data) => (
        <div className="inf-btn-col">
          <Tooltip
            title={`${data?.isArchive ? "Restore" : "Archive"} Infraction Type`}
          >
            <Button type="text">
              {!data?.isArchive ? (
                <i
                  className="icon-archive-alt"
                  style={{ color: "green", fontSize: 15 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalArchiveOpen(true);
                    setInfractionTypeId(data?.id);
                    setIsArchive(data?.isArchive);
                  }}
                ></i>
              ) : (
                <UndoOutlined
                  style={{ color: "red", fontSize: 15 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalArchiveOpen(true);
                    setInfractionTypeId(data?.id);
                    setIsArchive(data?.isArchive);
                  }}
                />
              )}
            </Button>
          </Tooltip>
          <div className="ant-btn-text">
            <Tooltip title={`Edit Infraction Type`}>
              <i
                className="icon-edit-alt"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`view-infraction/${data.id}/edit-infraction`);
                }}
              ></i>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  const handleToggleArchiveInfractionType = async () => {
    let payload = {
      infractionId: infractionTypeId,
      isArchive: !isArchive,
    };
    try {
      const response = await Notice.toggleArchiveInfractionType(payload);
      setModalArchiveOpen(false);
      dispatch(getInfractionsType(generalConstants.defaultFilters));
      message.success(response.message);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Card
        title="Infractions Type List"
        className="app-card"
        extra={
          <>
            <Link className="ant-btn ant-btn-primary" to="create-infraction">
              Create Infraction Type
            </Link>
          </>
        }
      >
        <GenericTable
          data={data}
          columns={infractionListCol}
          apiCall={getInfractionsType}
          total={infractionPagination?.totalCount}
          tableProps={tableProps}
        />
      </Card>
      <Modal
        title={`${isArchive ? "Restore" : "Archive"} Infraction`}
        centered
        okText="Yes"
        cancelText="No"
        width={800}
        open={modalArchiveOpen}
        onOk={handleToggleArchiveInfractionType}
        onCancel={() => setModalArchiveOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal accomodate-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <p>
          Are you sure you want to {isArchive ? "Restore" : "Archive"} this
          infraction?
        </p>
      </Modal>
    </>
  );
}
