import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  Select,
  Divider,
  Tag,
} from "antd";
import "features/settings/timeframe/timeframe.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  editTimeframe,
  getTimeframe,
  timeframe,
} from "redux/settings/settingsSlice";

import { capitalToPascal } from "utilities/generalUtility";
import { TimeframeOptions } from "utilities/generalConstants";
import { onlyPositive } from "utilities/regexUtility";
const { Option } = Select;

export default function Timeframe() {
  const dispatch = useDispatch();
  const timeframeData = useSelector(timeframe);
  const [disabled, setDisabled] = useState([]);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getTimeframe());
  }, [cancelClicked]);

  const initialValues = {
    timeframe: timeframeData?.timeframe,
    ...timeframeData?.infractionTypes?.reduce((acc, infraction) => {
      return {
        ...acc,
        [infraction.name]: infraction.allowedInfractions,
      };
    }, {}),
    ...timeframeData?.infractionTypes?.reduce((acc, infraction) => {
      return {
        ...acc,
        [`${infraction.name}-checkbox`]: infraction.allowedInfractions === null,
      };
    }, {}),
  };

  const handleCheckboxChange = (fieldName, checked, idx) => {
    form.setFieldsValue({
      [fieldName]: checked
        ? null
        : timeframeData?.infractionTypes?.[idx]?.allowedInfractions === null
        ? 0
        : timeframeData?.infractionTypes?.[idx]?.allowedInfractions,
    });

    setDisabled({
      ...disabled,
      [fieldName]: checked
        ? null
        : timeframeData?.infractionTypes?.[idx]?.allowedInfractions === null
        ? 0
        : timeframeData?.infractionTypes?.[idx]?.allowedInfractions,
    });
  };

  const formSubmit = (values) => {
    const timeframe = values.timeframe;
    delete values.timeframe;

    const infractionData = Object.keys(values).reduce((acc, key) => {
      if (!key.endsWith("-checkbox")) {
        const infractionId = timeframeData?.infractionTypes.find(
          (infraction) => infraction.name === key
        )?.id;
        return [...acc, { id: infractionId, allowedInfractions: values[key] }];
      }
      return acc;
    }, []);
    const body = { timeframe, infractionData };
    dispatch(editTimeframe(body));
  };

  useEffect(() => {
    timeframeData &&
      setDisabled({
        ...timeframeData?.infractionTypes?.reduce((acc, infraction) => {
          return {
            ...acc,
            [infraction.name]: infraction.allowedInfractions,
          };
        }, {}),
      });
  }, [timeframeData]);

  return (
    timeframeData?.timeframe && (
      <>
        <Card title="Infraction Timeframe" className="app-card time-frame-card">
          <Form
            form={form}
            layout="vertical"
            className="profile-settings"
            initialValues={initialValues}
            onFinish={formSubmit}
          >
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="timeframe"
                  label={
                    <>
                      <div className="label-input">Set Timeframe:</div>
                    </>
                  }
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    suffix={<i className="icon-dropdown"></i>}
                    size="middle"
                    placeholder="Please Select"
                  >
                    {Object.values(TimeframeOptions).map((option) => (
                      <Option key={option} value={option}>
                        {capitalToPascal(option)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Divider
                orientation="left"
                orientationMargin="0"
                className="mt-5"
              >
                No. of Allowed Infractions per Infraction Types for the Set
                Timeframe
              </Divider>

              {timeframeData?.infractionTypes?.map((infraction, idx) => (
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={infraction?.name}
                    label={
                      <>
                        <span className="mr-2">{infraction?.name}</span>

                        {infraction.isArchive && (
                          <Tag className="mr-2" color={"red"}>
                            Archived
                          </Tag>
                        )}
                      </>
                    }
                    rules={[
                      {
                        required: !form.getFieldValue(
                          `${infraction?.name}-checkbox`
                        ),
                        message: "Please input a number",
                      },
                      {
                        pattern: new RegExp(onlyPositive),
                        message: "Number must be positive",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Allowed Infractions"
                      size="middle"
                      disabled={
                        disabled[`${infraction?.name}`] === null ||
                        infraction.isArchive
                          ? true
                          : false
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={[`${infraction?.name}-checkbox`]}
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox
                      disabled={infraction.isArchive ? true : false}
                      onChange={(e) =>
                        handleCheckboxChange(
                          `${infraction?.name}`,
                          e.target.checked,
                          idx
                        )
                      }
                    >
                      No Limit
                    </Checkbox>
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <div className="form-btns">
              <Button
                ghost
                onClick={() => {
                  form.resetFields();
                  setCancelClicked(!cancelClicked);
                }}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card>
      </>
    )
  );
}
