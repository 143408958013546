import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "features/counter/counterSlice";
import userReducer from "redux/user/userSlice";
import employeeReducer from "redux/employee/employeeSlice";
import historyReducer from "redux/history/historySlice";
import logger from "redux-logger";
import infractionReducer from "redux/infraction/infractionSlice";
import settingsReducer from "redux/settings/settingsSlice";
import plansReducer from "redux/plan/planSlice";
import companyReducer from "redux/admin/companySlice";
import dashboardReducer from "redux/dashboard/dasboardSlice";
import notificationReducer from "redux/notification/notificationSlice";
export default configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    employee: employeeReducer,
    history: historyReducer,
    infraction: infractionReducer,
    settings: settingsReducer,
    plans: plansReducer,
    company: companyReducer,
    dashboard: dashboardReducer,
    notifications: notificationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
