import React, { useEffect } from "react";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="privacy-terms-wrapper">
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\t[data-custom-class='body'], [data-custom-class='body'] * {\n\tbackground: transparent !important;\n\t}\n\t[data-custom-class='title'], [data-custom-class='title'] * {\n\tfont-family: Arial !important;\n\tfont-size: 26px !important;\n\tcolor: #000000 !important;\n\t}\n\t[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n\tfont-family: Arial !important;\n\tcolor: #595959 !important;\n\tfont-size: 14px !important;\n\t}\n\t[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n\tfont-family: Arial !important;\n\tfont-size: 19px !important;\n\tcolor: #000000 !important;\n\t}\n\t[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n\tfont-family: Arial !important;\n\tfont-size: 17px !important;\n\tcolor: #000000 !important;\n\t}\n\t[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n\tcolor: #595959 !important;\n\tfont-size: 14px !important;\n\tfont-family: Arial !important;\n\t}\n\t[data-custom-class='link'], [data-custom-class='link'] * {\n\tcolor: #3030F1 !important;\n\tfont-size: 14px !important;\n\tfont-family: Arial !important;\n\tword-break: break-word !important;\n\t}\n",
        }}
      />
      <div data-custom-class="body">
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="title"
            style={{ lineHeight: "1.5" }}
          >
            <bdt className="block-component">
              <span style={{ fontSize: "19px" }}></span>
            </bdt>
            <bdt className="question">
              <strong>TERMS AND CONDITIONS</strong>
            </bdt>
            <bdt className="statement-end-if-in-editor" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ lineHeight: "1.5" }}
          >
            <strong>Last updated</strong>
            <bdt className="question">
              <strong>July 27, 2023</strong>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <strong>
              <span data-custom-class="heading_1">
                AGREEMENT TO OUR LEGAL TERMS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            id="agreement"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_6aa3gkhykvst" />
          </div>
        </div>
        <div align="center" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We are
              <bdt
                className="block-container question question-in-editor"
                data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                data-type="question"
              >
                CorpGuidance LLC
              </bdt>
              <bdt className="block-component" />
              (
              <bdt className="block-component" />"<strong>Company</strong>," "
              <strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>
              "
              <bdt className="statement-end-if-in-editor" />)
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="question">
                      <bdt className="block-component">.</bdt>
                    </bdt>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </div>
        </div>
        <div align="center" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We operate <bdt className="block-component" />
              the website{" "}
              <bdt className="question">
                <a
                  href="https://app.corpguidance.net"
                  target="_blank"
                  data-custom-class="link"
                >
                  https://app.corpguidance.net
                </a>
              </bdt>{" "}
              (the <bdt className="block-component" />"<strong>Site</strong>"
              <bdt className="statement-end-if-in-editor" />)
              <bdt className="block-component" />,{" "}
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />
              the mobile application <bdt className="question">
                Parallax
              </bdt>{" "}
              (the <bdt className="block-component" />"<strong>App</strong>"
              <bdt className="statement-end-if-in-editor" />)
              <bdt className="statement-end-if-in-editor" />, as well as any
              other related products and services that refer or link to these
              legal terms (the <bdt className="block-component" />"
              <strong>Legal Terms</strong>"
              <bdt className="statement-end-if-in-editor" />) (collectively, the{" "}
              <bdt className="block-component" />"<strong>Services</strong>"
              <bdt className="statement-end-if-in-editor" />
              ).
              <bdt className="block-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You can contact us by <bdt className="block-component" />
              phone at <bdt className="question">(+1)7816573317</bdt>, email at{" "}
              <bdt className="question">info@corpguidance.net</bdt>
              <bdt className="block-component" />,
              <bdt className="statement-end-if-in-editor" /> or by mail to{" "}
              <bdt className="question">28 Everett St</bdt>
              <bdt className="block-component" />,{" "}
              <bdt className="question">Norfolk</bdt>
              <bdt className="block-component" />
              <bdt className="block-component" />,{" "}
              <bdt className="question">MA</bdt>
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />
              <bdt className="block-component" />{" "}
              <bdt className="question">02056</bdt>
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />
              <bdt className="block-component">, </bdt>
              <bdt className="question">United States</bdt>
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (
              <bdt className="block-component" />"<strong>you</strong>"
              <bdt className="statement-end-if-in-editor" />
              ), and <bdt className="question">CorpGuidance LLC</bdt>,
              concerning your access to and use of the Services. You agree that
              by accessing the Services, you have read, understood, and agreed
              to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
              ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
              USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
              <bdt className="block-component" />
              <bdt className="block-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We will provide you with prior notice of any scheduled changes to
              the Services you are using. The modified Legal Terms will become
              effective upon posting or notifying you by{" "}
              <bdt className="question">info@corpguidance.net</bdt>, as stated
              in the email message. By continuing to use the Services after the
              effective date of any changes, you agree to be bound by the
              modified terms.
              <bdt className="block-component" />
              <bdt className="statement-end-if-in-editor" />
            </span>
          </div>
        </div>
        <div align="center" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              id="a2595956-7028-dbe5-123e-d3d3a93ed076"
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component" />
                    The Services are intended for users who are at least 18
                    years old. Persons under the age of 18 are not permitted to
                    use or register for the Services.
                  </span>
                </bdt>
              </bdt>
              <bdt data-type="conditional-block">
                <bdt className="block-component" />
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            We recommend that you print a copy of these Legal Terms for your
            records.
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            style={{ lineHeight: "1.5" }}
          >
            <strong>TABLE OF CONTENTS</strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a href="#services">
              <span data-custom-class="link">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">1. OUR SERVICES</span>
                </span>
              </span>
            </a>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#ip">
              <span data-custom-class="body_text">
                2. INTELLECTUAL PROPERTY RIGHTS
              </span>
            </a>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#userreps" />
            <a data-custom-class="link" href="#userreps">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  3. USER REPRESENTATIONS
                </span>
              </span>
            </a>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
            <a data-custom-class="link" href="#userreg">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">4. USER REGISTRATION</span>
              </span>
            </a>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>{" "}
            <a data-custom-class="link" href="#products" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#products">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#purchases" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#purchases">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  5. PURCHASES AND PAYMENT
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>{" "}
            <a data-custom-class="link" href="#free" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#free">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  6. FREE TRIAL
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#cancel" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#cancel">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">7. CANCELLATION</span>
              </span>
            </a>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>{" "}
            <a data-custom-class="link" href="#software" />{" "}
            <a data-custom-class="link" href="#software" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#software">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#prohibited" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#prohibited">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  8. PROHIBITED ACTIVITIES
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#ugc" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#ugc">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  9. USER GENERATED CONTRIBUTIONS
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#license" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#license">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  10. CONTRIBUTION <bdt className="block-component" />
                  LICENSE
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#reviews" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#reviews">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#mobile" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#mobile">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  11. MOBILE APPLICATION <bdt className="block-component" />
                  LICENSE
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#socialmedia" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#socialmedia">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#thirdparty" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#thirdparty">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  12. THIRD-PARTY WEBSITES AND CONTENT
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#advertisers" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#advertisers">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#sitemanage" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#sitemanage">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  13. SERVICES MANAGEMENT
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#ppyes" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#ppyes">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  14. PRIVACY POLICY
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#ppno" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#ppno">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#dmca" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#dmca">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </span>{" "}
            <a data-custom-class="link" href="#terms" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#terms">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  15. TERM AND TERMINATION
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#modifications" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#modifications">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  16. MODIFICATIONS AND INTERRUPTIONS
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#law" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#law">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">17. GOVERNING LAW</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#disputes" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#disputes">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  18. DISPUTE RESOLUTION
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#corrections" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#corrections">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">19. CORRECTIONS</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#disclaimer" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#disclaimer">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">20. DISCLAIMER</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#liability" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#liability">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  21. LIMITATIONS OF LIABILITY
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#indemnification" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#indemnification">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">22. INDEMNIFICATION</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#userdata" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#userdata">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">23. USER DATA</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#electronic" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#electronic">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#california" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#california">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  25. CALIFORNIA USERS AND RESIDENTS
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#misc" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#misc">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">26. MISCELLANEOUS</span>
              </span>
            </a>{" "}
            <a data-custom-class="link" href="#contact" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">27. CONTACT US</span>
              </span>
            </a>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_b6y29mp52qvx" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="services"
            style={{ lineHeight: "1.5" }}
          >
            <strong>
              <span style={{ fontSize: "19px" }}>1. OUR SERVICES</span>
            </strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.
              <bdt className="block-component" />
            </span>
            <bdt className="block-component">
              <span style={{ fontSize: "15px" }} />
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              The Services are not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use the Services. You may not use the Services in a
              way that would violate the Gramm-Leach-Bliley Act (GLBA).
              <bdt className="block-component" />
              <bdt className="statement-end-if-in-editor" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
        </div>
        <div
          align="center"
          data-custom-class="heading_1"
          style={{ textAlign: "left", lineHeight: "1.5" }}
        >
          <strong>
            <span id="ip" style={{ fontSize: "19px" }}>
              2. INTELLECTUAL PROPERTY RIGHTS
            </span>
          </strong>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_2"
            style={{ lineHeight: "1.5" }}
          >
            <strong>Our intellectual property</strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the{" "}
              <bdt className="block-component" />
              "Content"
              <bdt className="statement-end-if-in-editor" />
              ), as well as the trademarks, service marks, and logos contained
              therein (the <bdt className="block-component" />
              "Marks"
              <bdt className="statement-end-if-in-editor" />
              ).
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Our Content and Marks are protected by copyright and trademark
              laws (and various other intellectual property rights and unfair
              competition laws) and treaties in the United States and around the
              world.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              The Content and Marks are provided in or through the Services{" "}
              <bdt className="block-component" />
              "AS IS"
              <bdt className="statement-end-if-in-editor" /> for your{" "}
              <bdt className="block-component" />
              internal business purpose
              <bdt className="else-block" /> only.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_2"
            style={{ lineHeight: "1.5" }}
          >
            <strong>Your use of our Services</strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              Subject to your compliance with these Legal Terms, including the{" "}
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" />
            </span>
            <a data-custom-class="link" href="#prohibited">
              <span style={{ fontSize: "15px" }}>PROHIBITED ACTIVITIES</span>
            </a>
            <span style={{ fontSize: "15px" }}>
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" /> section below, we
              grant you a non-exclusive, non-transferable, revocable{" "}
              <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> to:
            </span>
          </div>
          <ul>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>access the Services; and</span>
            </li>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>
                download or print a copy of any portion of the Content to which
                you have properly gained access.
              </span>
            </li>
          </ul>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              solely for your <bdt className="block-component" />
              internal business purpose
              <bdt className="else-block" />.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to:{" "}
              <bdt className="question">info@corpguidance.net</bdt>. If we ever
              grant you the permission to post, reproduce, or publicly display
              any part of our Services or Content, you must identify us as the
              owners or licensors of the Services, Content, or Marks and ensure
              that any copyright or proprietary notice appears or is visible on
              posting, reproducing, or displaying our Content.
            </span>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_2"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <strong>
                Your submissions
                <bdt className="block-component" />
              </strong>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              Please review this section and the{" "}
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" />
              <a data-custom-class="link" href="#prohibited">
                PROHIBITED ACTIVITIES
              </a>
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" /> section carefully
              prior to using our Services to understand the (a) rights you give
              us and (b) obligations you have when you post or upload any
              content through the Services.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              <strong>Submissions:</strong> By directly sending us any question,
              comment, suggestion, idea, feedback, or other information about
              the Services (<bdt className="block-component" />
              "Submissions"
              <bdt className="statement-end-if-in-editor" />
              ), you agree to assign to us all intellectual property rights in
              such Submission. You agree that we shall own this Submission and
              be entitled to its unrestricted use and dissemination for any
              lawful purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
              <bdt className="block-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              <strong>You are responsible for what you post or upload:</strong>{" "}
              By sending us Submissions
              <bdt className="block-component" /> through any part of the
              Services
              <bdt className="block-component" /> you:
            </span>
          </div>
          <ul>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>
                confirm that you have read and agree with our{" "}
                <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" />
              </span>
              <a data-custom-class="link" href="#prohibited">
                <span style={{ fontSize: "15px" }}>PROHIBITED ACTIVITIES</span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" /> and will not
                post, send, publish, upload, or transmit through the Services
                any Submission
                <bdt className="block-component" /> that is illegal, harassing,
                hateful, harmful, defamatory, obscene, bullying, abusive,
                discriminatory, threatening to any person or group, sexually
                explicit, false, inaccurate, deceitful, or misleading;
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>
                to the extent permissible by applicable law, waive any and all
                moral rights to any such Submission
                <bdt className="block-component" />;
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>
                warrant that any such Submission
                <bdt className="block-component" /> are original to you or that
                you have the necessary rights and{" "}
                <bdt className="block-component" />
                licenses
                <bdt className="statement-end-if-in-editor" /> to submit such
                Submissions
                <bdt className="block-component" /> and that you have full
                authority to grant us the above-mentioned rights in relation to
                your Submissions
                <bdt className="block-component" />; and
              </span>
            </li>
            <li
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span style={{ fontSize: "15px" }}>
                warrant and represent that your Submissions
                <bdt className="block-component" /> do not constitute
                confidential information.
              </span>
            </li>
          </ul>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            You are solely responsible for your Submissions
            <bdt className="block-component" /> and you expressly agree to
            reimburse us for any and all losses that we may suffer because of
            your breach of (a) this section, (b) any third party’s intellectual
            property rights, or (c) applicable law.
            <bdt className="block-component" />
            <bdt className="block-component" />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="userreps"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_5hg7kgyv9l8z" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: "19px",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            3.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                USER REPRESENTATIONS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              By using the Services, you represent and warrant that:
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />{" "}
                <bdt data-type="body">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    (
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    1
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) all registration information you submit will be true,
                    accurate, current, and complete; (
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    2
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) you will maintain the accuracy of such information and
                    promptly update such registration information as necessary;
                  </span>
                </bdt>
              </bdt>
              <bdt className="statement-end-if-in-editor" data-type="close" />{" "}
            </bdt>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              (
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
              3
            </span>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
              ) you have the legal capacity and you agree to comply with these
              Legal Terms;
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_u13_option"
                  data-type="statement"
                />{" "}
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                4
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you are not a minor in the jurisdiction in which you reside
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="76948fab-ec9e-266a-bb91-948929c050c9"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="user_o18_option"
                      data-type="statement"
                    />
                  </bdt>
                  ; (
                </bdt>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                5
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not access the Services through automated or
                non-human means, whether through a bot, script or otherwise; (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                6
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not use the Services for any illegal or{" "}
                <bdt className="block-component" />
                unauthorized
                <bdt className="statement-end-if-in-editor" /> purpose; and (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                7
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) your use of the Services will not violate any applicable law
                or regulation.
              </span>
              <span
                style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
              />
            </bdt>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Services (or any portion thereof).
                </span>
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "1.1", textAlign: "left" }}
              >
                <bdt className="block-component" />
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <br />
              </div>
            </div>
            <div className="MsoNormal" style={{ lineHeight: 1 }}>
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="userreg"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <strong>
                      <span
                        style={{ lineHeight: "24.5333px", fontSize: "19px" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: "19px",
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "115%",
                                      fontFamily: "Arial",
                                      fontSize: "19px",
                                    }}
                                  >
                                    4.
                                  </span>
                                </strong>
                              </span>
                            </strong>
                          </span>{" "}
                        </strong>
                        USER REGISTRATION
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </div>
            <div
              className="MsoNormal"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <br />
            </div>
            <div className="MsoNormal" style={{ lineHeight: 1 }}>
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ textAlign: "left", lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      You may be required to register to use the Services. You
                      agree to keep your password confidential and will be
                      responsible for all use of your account and password. We
                      reserve the right to remove, reclaim, or change a username
                      you select if we determine, in our sole discretion, that
                      such username is inappropriate, obscene, or otherwise
                      objectionable.
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      />
                    </span>
                  </div>
                </bdt>
              </bdt>
            </div>
            <div
              className="MsoNormal"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <div
              className="MsoNormal"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <div
              className="MsoNormal"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <br />
            </div>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="purchases"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_ynub0jdx8pob" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: "19px",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            5.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                PURCHASES AND PAYMENT
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We accept the following forms of payment:
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: 1 }}
            >
              <br />
            </div>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />-{" "}
              <bdt className="question">Visa</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />-{" "}
              <bdt className="question">Mastercard</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />-{" "}
              <bdt className="question">American Express</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />-{" "}
              <bdt className="question">Discover</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />-{" "}
              <bdt className="question">PayPal</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: "20px" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="forloop-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <br />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases made via the Services. You
              further agree to promptly update account and payment information,
              including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and
              contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be{" "}
            </span>
            <span
              style={{
                fontSize: "15px",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              in <bdt className="question">US dollars</bdt>.
            </span>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you{" "}
              <bdt className="block-component" />
              authorize
              <bdt className="statement-end-if-in-editor" /> us to charge your
              chosen payment provider for any such amounts upon placing your
              order.{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="9c0216a1-d094-fd73-a062-9615dc795ffc"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="recurring_charge_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">
                    If your order is subject to recurring charges, then you
                    consent to our charging your payment method on a recurring
                    basis without requiring your prior approval for each
                    recurring charge, until such time as you cancel the
                    applicable order.{" "}
                  </bdt>
                </bdt>
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
              We reserve the right to correct any errors or mistakes in pricing,
              even if we have already requested or received payment.
            </span>
          </div>
        </div>
        <div align="center" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We reserve the right to refuse any order placed through the
              Services. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household, or per order.
              These restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole{" "}
              <bdt className="block-component" />
              judgment
              <bdt className="statement-end-if-in-editor" />, appear to be
              placed by dealers, resellers, or distributors.
            </span>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt
                  className="block-component"
                  data-record-question-key="return_option"
                  data-type="statement"
                  style={{ fontSize: "15px" }}
                />
              </bdt>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: "15px" }} />
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: "15px" }} />
            </bdt>
            <span style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="free"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "19px" }}>
              <strong>6. FREE TRIAL</strong>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              We offer a <bdt className="question">14</bdt>-day free trial to
              new users who register with the Services.{" "}
              <bdt className="block-component" />
              <bdt className="question">
                The account will be charged according to the user's chosen
                subscription
              </bdt>{" "}
              at the end of the free trial.
              <bdt className="else-block" />
              <bdt className="statement-end-if-in-editor" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="cancel"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "19px" }}>
              <strong>7. CANCELLATION</strong>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <bdt className="block-component">
              <span style={{ fontSize: "15px" }} />
            </bdt>
            <span style={{ fontSize: "15px" }}>
              All purchases are non-refundable.{" "}
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component" />
              You can cancel your subscription at any time by logging into your
              account.
              <bdt className="block-component" /> Your cancellation will take
              effect at the end of the current paid term.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: "15px" }}>
              If you are unsatisfied with our Services, please email us at{" "}
              <bdt className="question">info@corpguidance.net</bdt>
              <bdt className="block-component" /> or call us at{" "}
              <bdt className="question">(+1)7816573317</bdt>
              <bdt className="statement-end-if-in-editor" />.
              <bdt className="statement-end-if-in-editor" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-component" />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    style={{ fontSize: "15px", lineHeight: "1.5" }}
                  >
                    <br />
                  </div>
                </bdt>
              </bdt>
            </span>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="prohibited"
              style={{ textAlign: "left", lineHeight: "1.5" }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              8.
                            </span>
                          </strong>
                        </span>
                      </strong>
                    </span>{" "}
                  </strong>
                  PROHIBITED ACTIVITIES
                </span>
              </strong>
            </div>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                You may not access or use the Services for any purpose other
                than that for which we make the Services available. The Services
                may not be used in connection with any commercial{" "}
                <bdt className="block-component" />
                endeavors
                <bdt className="statement-end-if-in-editor" /> except those that
                are specifically endorsed or approved by us.
              </span>
            </div>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      As a user of the Services, you agree not to:
                    </span>
                  </div>
                </div>
                <ul>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",
                          backgroundColor: "rgb(255, 255, 255)",
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        Systematically retrieve data or other content from the
                        Services to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Trick, defraud, or mislead us and other users,
                              especially in any attempt to learn sensitive
                              account information such as user passwords.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Circumvent, disable, or otherwise interfere with
                              security-related features of the Services,
                              including features that prevent or restrict the
                              use or copying of any Content or enforce
                              limitations on the use of the Services and/or the
                              Content contained therein.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Disparage, tarnish, or otherwise harm, in our
                              opinion, us and/or the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use any information obtained from the Services in
                              order to harass, abuse, or harm another person.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Make improper use of our support services or
                              submit false reports of abuse or misconduct.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use the Services in a manner inconsistent with any
                              applicable laws or regulations.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Engage in <bdt className="block-component" />
                              unauthorized
                              <bdt className="statement-end-if-in-editor" />{" "}
                              framing of or linking to the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) viruses, Trojan horses, or other
                              material, including excessive use of capital
                              letters and spamming (continuous posting of
                              repetitive text), that interferes with any party’s
                              uninterrupted use and enjoyment of the Services or
                              modifies, impairs, disrupts, alters, or interferes
                              with the use, features, functions, operation, or
                              maintenance of the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Engage in any automated use of the system, such as
                              using scripts to send comments or messages, or
                              using any data mining, robots, or similar data
                              gathering and extraction tools.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Delete the copyright or other proprietary rights
                              notice from any Content.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Attempt to impersonate another user or person or
                              use the username of another user.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) any material that acts as a passive or
                              active information collection or transmission
                              mechanism, including without limitation, clear
                              graphics interchange formats (
                              <bdt className="block-component" />
                              "gifs"
                              <bdt className="statement-end-if-in-editor" />
                              ), 1×1 pixels, web bugs, cookies, or other similar
                              devices (sometimes referred to as{" "}
                              <bdt className="block-component" />
                              "spyware" or "passive collection mechanisms" or
                              "pcms"
                              <bdt className="statement-end-if-in-editor" />
                              ).
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Interfere with, disrupt, or create an undue burden
                              on the Services or the networks or services
                              connected to the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Harass, annoy, intimidate, or threaten any of our
                              employees or agents engaged in providing any
                              portion of the Services to you.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Attempt to bypass any measures of the Services
                              designed to prevent or restrict access to the
                              Services, or any portion of the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Copy or adapt the Services' software, including
                              but not limited to Flash, PHP, HTML, JavaScript,
                              or other code.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Except as permitted by applicable law, decipher,
                              decompile, disassemble, or reverse engineer any of
                              the software comprising or in any way making up a
                              part of the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Except as may be the result of standard search
                              engine or Internet browser usage, use, launch,
                              develop, or distribute any automated system,
                              including without limitation, any spider, robot,
                              cheat utility, scraper, or offline reader that
                              accesses the Services, or use or launch any{" "}
                              <bdt className="block-component" />
                              unauthorized
                              <bdt className="statement-end-if-in-editor" />{" "}
                              script or other software.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use a buying agent or purchasing agent to make
                              purchases on the Services.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Make any <bdt className="block-component" />
                              unauthorized
                              <bdt className="statement-end-if-in-editor" /> use
                              of the Services, including collecting usernames
                              and/or email addresses of users by electronic or
                              other means for the purpose of sending unsolicited
                              email, or creating user accounts by automated
                              means or under false{" "}
                              <bdt className="block-component" />
                              pretenses
                              <bdt className="statement-end-if-in-editor" />.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use the Services as part of any effort to compete
                              with us or otherwise use the Services and/or the
                              Content for any revenue-generating{" "}
                              <bdt className="block-component" />
                              endeavor
                              <bdt className="statement-end-if-in-editor" /> or
                              commercial enterprise.
                            </span>
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                              }}
                            >
                              <bdt className="forloop-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <bdt className="question">
                        Use the Services to advertise or offer to sell goods and
                        services.
                      </bdt>
                      <bdt className="forloop-component" />
                    </span>
                  </li>
                </ul>
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <br />
              </div>
            </div>
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <bdt
                  className="block-container if"
                  data-type="if"
                  style={{ textAlign: "left" }}
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="ugc"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "24.5333px",
                              fontSize: "19px",
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "24.5333px",
                                  fontSize: "19px",
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "115%",
                                      fontFamily: "Arial",
                                      fontSize: "19px",
                                    }}
                                  >
                                    <strong>
                                      <span
                                        style={{
                                          lineHeight: "115%",
                                          fontFamily: "Arial",
                                          fontSize: "19px",
                                        }}
                                      >
                                        9.
                                      </span>
                                    </strong>
                                  </span>
                                </strong>
                              </span>{" "}
                            </strong>
                            USER GENERATED CONTRIBUTIONS
                          </span>
                        </strong>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <bdt
                  className="block-container if"
                  data-type="if"
                  style={{ textAlign: "left" }}
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                            style={{ textAlign: "left" }}
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="user_post_content_option"
                                data-type="statement"
                              />
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                The Services does not offer users to submit or
                                post content.
                                <bdt className="block-component" /> We may
                                provide you with the opportunity to create,
                                submit, post, display, transmit, perform,
                                publish, distribute, or broadcast content and
                                materials to us or on the Services, including
                                but not limited to text, writings, video, audio,
                                photographs, graphics, comments, suggestions, or
                                personal information or other material
                                (collectively,{" "}
                                <bdt className="block-component" />
                                "Contributions"
                                <bdt className="statement-end-if-in-editor" />
                                ). Contributions may be viewable by other users
                                of the Services and through third-party
                                websites.
                                <bdt className="block-component" /> As such, any
                                Contributions you transmit may be treated in
                                accordance with the Services' Privacy Policy.
                                <bdt className="statement-end-if-in-editor" />{" "}
                                When you create or make available any
                                Contributions, you thereby represent and warrant
                                that:
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component" />
                                  </bdt>
                                </span>
                              </span>
                            </bdt>
                          </bdt>
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
            </div>
          </div>
          <ul>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    You are the creator and owner of or have the necessary{" "}
                    <bdt className="block-component" />
                    licenses
                    <bdt className="statement-end-if-in-editor" />, rights,
                    consents, releases, and permissions to use and to{" "}
                    <bdt className="block-component" />
                    authorize
                    <bdt className="statement-end-if-in-editor" /> us, the
                    Services, and other users of the Services to use your
                    Contributions in any manner contemplated by the Services and
                    these Legal Terms.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Services and these Legal Terms.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not false, inaccurate, or misleading.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not unsolicited or{" "}
                    <bdt className="block-component" />
                    unauthorized
                    <bdt className="statement-end-if-in-editor" /> advertising,
                    promotional materials, pyramid schemes, chain letters, spam,
                    mass mailings, or other forms of solicitation.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing,{" "}
                    <bdt className="block-component" />
                    libelous
                    <bdt className="statement-end-if-in-editor" />, slanderous,
                    or otherwise objectionable (as determined by us).
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "14px" }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Legal Terms,
                    or any applicable law or regulation.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      Any use of the Services in violation of the foregoing
                      violates these Legal Terms and may result in, among other
                      things, termination or suspension of your rights to use
                      the Services.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="license"
                    style={{ lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        style={{ lineHeight: "24.5333px", fontSize: "19px" }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "24.5333px",
                              fontSize: "19px",
                            }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: "19px",
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "115%",
                                      fontFamily: "Arial",
                                      fontSize: "19px",
                                    }}
                                  >
                                    10.
                                  </span>
                                </strong>
                              </span>
                            </strong>
                          </span>{" "}
                        </strong>
                        CONTRIBUTION <bdt className="block-component" />
                        LICENSE
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_post_content_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                You and Services agree that we may access, store, process, and
                use any information and personal data that you provide
                <bdt className="block-component" /> following the terms of the
                Privacy Policy
                <bdt className="statement-end-if-in-editor" /> and your choices
                (including settings).
              </span>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              By submitting suggestions or other feedback regarding the
              Services, you agree that we can use and share such feedback for
              any purpose without compensation to you.
              <bdt className="block-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Services. You are solely responsible for your Contributions
              to the Services and you expressly agree to exonerate us from any
              and all responsibility and to refrain from any legal action
              against us regarding your Contributions.
              <bdt className="statement-end-if-in-editor" />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    style={{ textAlign: "left" }}
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                </span>
              </span>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="review_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="mobile_app_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="mobile"
            style={{ lineHeight: "1.5" }}
          >
            <strong>
              <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: "19px",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        11.
                      </span>
                    </strong>
                  </span>
                </strong>
              </span>{" "}
              MOBILE APPLICATION <bdt className="block-component" />
              LICENSE
              <bdt className="statement-end-if-in-editor" />
            </strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_2"
            style={{ lineHeight: "1.5" }}
          >
            <strong>
              Use <bdt className="block-component" />
              License
              <bdt className="statement-end-if-in-editor" />
            </strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              If you access the Services via the App, then we grant you a
              revocable, non-exclusive, non-transferable, limited right to
              install and use the App on wireless electronic devices owned or
              controlled by you, and to access and use the App on such devices
              strictly in accordance with the terms and conditions of this
              mobile application <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> contained in these
              Legal Terms. You shall not: (1) except as permitted by applicable
              law, decompile, reverse engineer, disassemble, attempt to derive
              the source code of, or decrypt the App; (2) make any modification,
              adaptation, improvement, enhancement, translation, or derivative
              work from the App; (3) violate any applicable laws, rules, or
              regulations in connection with your access or use of the App; (4)
              remove, alter, or obscure any proprietary notice (including any
              notice of copyright or trademark) posted by us or the licensors of
              the App; (5) use the App for any revenue-generating{" "}
              <bdt className="block-component" />
              endeavor
              <bdt className="statement-end-if-in-editor" />, commercial
              enterprise, or other purpose for which it is not designed or
              intended; (6) make the App available over a network or other
              environment permitting access or use by multiple devices or users
              at the same time; (7) use the App for creating a product, service,
              or software that is, directly or indirectly, competitive with or
              in any way a substitute for the App; (8) use the App to send
              automated queries to any website or to send any unsolicited
              commercial email; or (9) use any proprietary information or any of
              our interfaces or our other intellectual property in the design,
              development, manufacture, licensing, or distribution of any
              applications, accessories, or devices for use with the App.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_2"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <strong>Apple and Android Devices</strong>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              The following terms apply when you use the App obtained from
              either the Apple Store or Google Play (each an{" "}
              <bdt className="block-component" />
              "App Distributor"
              <bdt className="statement-end-if-in-editor" />) to access the
              Services: (1) the <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> granted to you for
              our App is limited to a non-transferable{" "}
              <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> to use the
              application on a device that <bdt className="block-component" />
              utilizes
              <bdt className="statement-end-if-in-editor" /> the Apple iOS or
              Android operating systems, as applicable, and in accordance with
              the usage rules set forth in the applicable App Distributor’s
              terms of service; (2) we are responsible for providing any
              maintenance and support services with respect to the App as
              specified in the terms and conditions of this mobile application{" "}
              <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> contained in these
              Legal Terms or as otherwise required under applicable law, and you
              acknowledge that each App Distributor has no obligation whatsoever
              to furnish any maintenance and support services with respect to
              the App; (3) in the event of any failure of the App to conform to
              any applicable warranty, you may notify the applicable App
              Distributor, and the App Distributor, in accordance with its terms
              and policies, may refund the purchase price, if any, paid for the
              App, and to the maximum extent permitted by applicable law, the
              App Distributor will have no other warranty obligation whatsoever
              with respect to the App; (4) you represent and warrant that (i)
              you are not located in a country that is subject to a US
              government embargo, or that has been designated by the US
              government as a <bdt className="block-component" />
              "terrorist supporting"
              <bdt className="statement-end-if-in-editor" /> country and (ii)
              you are not listed on any US government list of prohibited or
              restricted parties; (5) you must comply with applicable
              third-party terms of agreement when using the App, e.g.
              <bdt className="block-component" />,
              <bdt className="statement-end-if-in-editor" /> if you have a VoIP
              application, then you must not be in violation of their wireless
              data service agreement when using the App; and (6) you acknowledge
              and agree that the App Distributors are third-party beneficiaries
              of the terms and conditions in this mobile application{" "}
              <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> contained in these
              Legal Terms, and that each App Distributor will have the right
              (and will be deemed to have accepted the right) to enforce the
              terms and conditions in this mobile application{" "}
              <bdt className="block-component" />
              license
              <bdt className="statement-end-if-in-editor" /> contained in these
              Legal Terms against you as a third-party beneficiary thereof.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    style={{ textAlign: "left" }}
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                </span>
              </span>
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="socialnetwork_link_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="3rd_party_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="thirdparty"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: "19px",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      12.
                    </span>
                  </strong>
                </span>
              </strong>{" "}
            </span>
            THIRD-PARTY WEBSITES AND CONTENT
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            The Services may contain (or you may be sent via the{" "}
            <bdt className="block-component" />
            Site or App
            <bdt className="block-component" />) links to other websites (
            <bdt className="block-component" />
            "Third-Party Websites"
            <bdt className="statement-end-if-in-editor" />) as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties (
            <bdt className="block-component" />
            "Third-Party Content"
            <bdt className="statement-end-if-in-editor" />
            ). Such <span style={{ fontSize: "14.6667px" }}>
              Third-Party
            </span>{" "}
            Websites and{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
            are not investigated, monitored, or checked for accuracy,
            appropriateness, or completeness by us, and we are not responsible
            for any Third-Party Websites accessed through the Services or any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
            posted on, available through, or installed from the Services,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the <span style={{ fontSize: "14.6667px" }}>Third-Party</span>{" "}
            Websites or the{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content.
            Inclusion of, linking to, or permitting the use or installation of
            any <span style={{ fontSize: "14.6667px" }}>Third-Party</span>{" "}
            Websites or any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party </span>Content
            does not imply approval or endorsement thereof by us. If you decide
            to leave the Services and access the{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            or to use or install any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content,
            you do so at your own risk, and you should be aware these Legal
            Terms no longer govern. You should review the applicable terms and
            policies, including privacy and data gathering practices, of any
            website to which you navigate from the Services or relating to any
            applications you use or install from the Services. Any purchases you
            make through{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            will be through other websites and from other companies, and we take
            no responsibility whatsoever in relation to such purchases which are
            exclusively between you and the applicable third party. You agree
            and acknowledge that we do not endorse the products or services
            offered on{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            and you shall hold us blameless from any harm caused by your
            purchase of such products or services. Additionally, you shall hold
            us blameless from any losses sustained by you or harm caused to you
            relating to or resulting in any way from any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
            or any contact with{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close" />
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="advertiser_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="sitemanage"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          13.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              SERVICES MANAGEMENT
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the Services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the Services.
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="privacy_policy_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="ppyes"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: "19px",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      14.
                    </span>
                  </strong>
                </span>
              </strong>{" "}
            </span>
            PRIVACY POLICY
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            We care about data privacy and security. Please review our Privacy
            Policy:
            <strong>
              {" "}
              <bdt
                className="block-container question question-in-editor"
                data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                data-type="question"
              >
                __________
              </bdt>
            </strong>
            . By using the Services, you agree to be bound by our Privacy
            Policy, which is incorporated into these Legal Terms. Please be
            advised the Services are hosted in{" "}
            <bdt className="block-component" />
            the <bdt className="question">United States</bdt>
            <bdt className="block-component" />
            <bdt className="block-component" />. If you access the Services from
            any other region of the world with laws or other requirements
            governing personal data collection, use, or disclosure that differ
            from applicable laws in{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            <bdt className="block-component" />, then through your continued use
            of the Services, you are transferring your data to{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            <bdt className="block-component" />, and you expressly consent to
            have your data transferred to and processed in{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            <bdt className="block-component" />.
            <bdt className="block-component" />
            <bdt
              className="block-container if"
              data-type="if"
              id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_u13_option"
                  data-type="statement"
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </bdt>
              </bdt>
            </bdt>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close" />
          </bdt>
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="privacy_policy_followup"
                data-type="statement"
                style={{ fontSize: "14.6667px" }}
              />
            </bdt>
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="copyright_agent_option"
                data-type="statement"
              >
                <bdt className="block-component" />
                <bdt className="block-component" />
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                style={{ textAlign: "left" }}
              >
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close">
              <bdt className="block-component" />
            </bdt>
            <bdt className="block-component" />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="terms"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          15.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              TERM AND TERMINATION
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE
            <bdt
              className="block-container if"
              data-type="if"
              id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />
                <bdt data-type="body">YOUR ACCOUNT AND </bdt>
              </bdt>
              <bdt className="statement-end-if-in-editor" data-type="close" />
            </bdt>
            ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
            WARNING, IN OUR SOLE DISCRETION.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="modifications"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          16.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              MODIFICATIONS AND INTERRUPTIONS
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services.
            <bdt className="block-component" />
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="law"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          17.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              GOVERNING LAW
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            <bdt className="block-component" />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)",
            }}
          >
            These Legal Terms and your use of the Services are governed by and
            construed in accordance with the laws of
            <bdt
              className="block-container if"
              data-type="if"
              id="b86653c1-52f0-c88c-a218-e300b912dd6b"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="governing_law"
                  data-type="statement"
                />
                <bdt data-type="body">
                  the Commonwealth of
                  <bdt className="question">Massachusetts</bdt>
                  <bdt className="block-component" />
                </bdt>
                applicable to agreements made and to be entirely performed
                within
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                  style={{ fontSize: "14.6667px" }}
                >
                  <bdt data-type="conditional-block">
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="governing_law"
                            data-type="statement"
                          />
                          <bdt data-type="body">
                            the Commonwealth of
                            <bdt className="question">Massachusetts</bdt>
                            <bdt className="block-component" />
                          </bdt>
                          <span style={{ fontSize: "14.6667px" }}>, </span>
                          without regard to its conflict of law principles.
                          <bdt className="block-component" />
                        </bdt>
                      </bdt>
                    </span>
                  </bdt>
                </bdt>
              </bdt>
            </bdt>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="disputes"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          18.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              DISPUTE RESOLUTION
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Informal Negotiations</strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Legal Terms (each a{" "}
            <bdt className="block-component" />
            "Dispute" and collectively, the "Disputes"
            <bdt className="statement-end-if-in-editor" />) brought by either
            you or us (individually, a <bdt className="block-component" />
            "Party" and collectively, the "Parties"
            <bdt className="statement-end-if-in-editor" />
            ), the Parties agree to first attempt to negotiate any Dispute
            (except those Disputes expressly provided below) informally for at
            least <bdt className="question">thirty (30)</bdt> days before
            initiating arbitration. Such informal negotiations commence upon
            written notice from one Party to the other Party.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Binding Arbitration</strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component">
            <span style={{ fontSize: "15px" }} />
          </bdt>
          <span style={{ fontSize: "15px" }}>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association (
            <bdt className="block-component" />
            "AAA"
            <bdt className="statement-end-if-in-editor" />) and, where
            appropriate, the AAA’s Supplementary Procedures for Consumer Related
            Disputes (<bdt className="block-component" />
            "AAA Consumer Rules"
            <bdt className="statement-end-if-in-editor" />
            ), both of which are available at the{" "}
            <span
              style={{
                fontSize: "15px",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)",
              }}
            >
              <a
                data-custom-class="link"
                href="http://www.adr.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                American Arbitration Association (AAA) website
              </a>
            </span>
            . Your arbitration fees and your share of arbitrator compensation
            shall be governed by the AAA Consumer Rules and, where appropriate,
            limited by the AAA Consumer Rules.{" "}
            <bdt className="block-component" />
            The arbitration may be conducted in person, through the submission
            of documents, by phone, or online. The arbitrator will make a
            decision in writing, but need not provide a statement of reasons
            unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in{" "}
            <bdt className="block-component" />
            <bdt className="question">Norfolk</bdt>,{" "}
            <bdt className="statement-end-if-in-editor" />
            <bdt className="block-component" />
            <bdt className="question">Massachusetts</bdt>
            <bdt className="statement-end-if-in-editor" />. Except as otherwise
            provided herein, the Parties may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm,
            modify, vacate, or enter <bdt className="block-component" />
            judgment
            <bdt className="statement-end-if-in-editor" /> on the award entered
            by the arbitrator.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
          </span>
          <bdt className="block-component" style={{ fontSize: "15px" }} />
          <span style={{ fontSize: "15px" }}> state and federal courts</span>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: "15px" }}
          />
          <span style={{ fontSize: "15px" }}> located in</span>
          <bdt className="block-component" style={{ fontSize: "15px" }} />{" "}
          <bdt className="question">Norfolk</bdt>
          <span style={{ fontSize: "15px" }}>,</span>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: "15px" }}
          />{" "}
          <bdt className="block-component" style={{ fontSize: "15px" }} />
          <bdt className="question" style={{ fontSize: "15px" }}>
            Massachusetts
          </bdt>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: "15px" }}
          />
          <span style={{ fontSize: "15px" }}>
            , and the Parties hereby consent to, and waive all{" "}
            <bdt className="block-component" />
            defenses
            <bdt className="statement-end-if-in-editor" /> of lack of personal
            jurisdiction, and forum non conveniens with respect to venue and
            jurisdiction in such
            <bdt className="block-component" /> state and federal courts
            <bdt className="statement-end-if-in-editor" />. Application of the
            United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA)
            are excluded from these Legal Terms.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component" />
            In no event shall any Dispute brought by either Party related in any
            way to the Services be commenced more than{" "}
            <bdt className="question">one (1)</bdt> years after the cause of
            action arose. <bdt className="statement-end-if-in-editor" />
            If this provision is found to be illegal or unenforceable, then
            neither Party will elect to arbitrate any Dispute falling within
            that portion of this provision found to be illegal or unenforceable
            and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and
            the Parties agree to submit to the personal jurisdiction of that
            court.
            <bdt className="block-component" />
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Restrictions</strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to <bdt className="block-component" />
          utilize
          <bdt className="statement-end-if-in-editor" /> class action
          procedures; and (c) there is no right or authority for any Dispute to
          be brought in a purported representative capacity on behalf of the
          general public or any other persons.
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or <bdt className="block-component" />
          unauthorized
          <bdt className="statement-end-if-in-editor" /> use; and (c) any claim
          for injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor">
            <bdt className="statement-end-if-in-editor" />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="corrections"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ fontSize: "19px" }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          19.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              CORRECTIONS
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="disclaimer"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "19px", color: "rgb(0, 0, 0)" }}>
            <strong>
              <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: "19px",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        20.
                      </span>
                    </strong>
                  </span>
                </strong>
              </span>{" "}
              DISCLAIMER
            </strong>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY{" "}
            <bdt className="block-component" />
            UNAUTHORIZED
            <bdt className="statement-end-if-in-editor" /> ACCESS TO OR USE OF
            OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
            FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
            CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
            THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
            WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST{" "}
            <bdt className="block-component" />
            JUDGMENT
            <bdt className="statement-end-if-in-editor" /> AND EXERCISE CAUTION
            WHERE APPROPRIATE.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="liability"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          21.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              LIMITATIONS OF LIABILITY
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            <span data-custom-class="body_text">
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </span>{" "}
            <bdt
              className="block-container if"
              data-type="if"
              id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
            >
              <span data-custom-class="body_text">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="limitations_liability_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                    OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO{" "}
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="limitations_liability_option"
                          data-type="statement"
                        />
                        <bdt data-type="body">
                          THE AMOUNT PAID, IF ANY, BY YOU TO US
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="19e172cb-4ccf-1904-7c06-4251800ba748"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="limilation_liability_time_option"
                                data-type="statement"
                              >
                                {" "}
                              </bdt>
                              <bdt data-type="body">
                                <span
                                  style={{
                                    fontSize: "11pt",
                                    color: "rgb(89, 89, 89)",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  DURING THE{" "}
                                  <bdt
                                    className="block-container question question-in-editor"
                                    data-id="5dd68d46-ed6f-61c7-cd66-6b3f424b6bdd"
                                    data-type="question"
                                  >
                                    six (6)
                                  </bdt>{" "}
                                  mONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                                  ARISING
                                </span>
                              </bdt>
                            </bdt>
                            <bdt
                              className="statement-end-if-in-editor"
                              data-type="close"
                            />
                          </bdt>
                        </bdt>
                      </bdt>
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="limitations_liability_option"
                          data-type="statement"
                        >
                          .
                        </bdt>
                      </bdt>
                    </bdt>
                  </bdt>
                </bdt>
              </span>
            </bdt>{" "}
          </span>
          <span data-custom-class="body_text">
            CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
            LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
            ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
            HAVE ADDITIONAL RIGHTS.
          </span>
          <bdt className="statement-end-if-in-editor" data-type="close">
            <span data-custom-class="body_text" />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="indemnification"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          22.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              INDEMNIFICATION
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:
            <bdt
              className="block-container if"
              data-type="if"
              id="475fffa5-05ca-def8-ac88-f426b238903c"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_post_content_option"
                  data-type="statement"
                />
              </bdt>
              (<span style={{ fontSize: "14.6667px" }}>1</span>) use of the
              Services; (<span style={{ fontSize: "14.6667px" }}>2</span>)
              breach of these Legal Terms; (
              <span style={{ fontSize: "14.6667px" }}>3</span>) any breach of
              your representations and warranties set forth in these Legal
              Terms; (<span style={{ fontSize: "14.6667px" }}>4</span>) your
              violation of the rights of a third party, including but not
              limited to intellectual property rights; or (
              <span style={{ fontSize: "14.6667px" }}>5</span>) any overt
              harmful act toward any other user of the Services with whom you
              connected via the Services. Notwithstanding the foregoing, we
              reserve the right, at your expense, to assume the exclusive
              <bdt className="block-component" />
              defense
              <bdt className="statement-end-if-in-editor" />
              and control of any matter for which you are required to indemnify
              us, and you agree to cooperate, at your expense, with our
              <bdt className="block-component" />
              defense
              <bdt className="statement-end-if-in-editor" />
              of such claims. We will use reasonable efforts to notify you of
              any such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </bdt>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="userdata"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          23.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              USER DATA
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="electronic"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          24.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="california"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          25.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              CALIFORNIA USERS AND RESIDENTS
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="misc"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                fontSize: "19px",
              }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: "19px",
                          }}
                        >
                          26.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>{" "}
              </strong>
              MISCELLANEOUS
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all
            <bdt className="block-component" />
            defenses
            <bdt className="statement-end-if-in-editor" />
            you may have based on the electronic form of these Legal Terms and
            the lack of signing by the parties hereto to execute these Legal
            Terms.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component">
            <span style={{ fontSize: "15px" }} />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="contact"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
              <span style={{ fontSize: "19px" }}>
                <strong>
                  <span style={{ lineHeight: "24.5333px", fontSize: "19px" }}>
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: "19px",
                            }}
                          >
                            27.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>{" "}
                </strong>
                CONTACT US
              </span>
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166,
            }}
          >
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="question">
                <strong>CorpGuidance LLC</strong>
              </bdt>
              <strong>
                <bdt className="block-component" />
              </strong>
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)",
              }}
            >
              <bdt className="question">
                <strong>
                  <bdt className="question">28 Everett St</bdt>
                </strong>
              </bdt>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="statement-end-if-in-editor" />
              </span>
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            <strong>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="question">Norfolk</bdt>
                <bdt className="block-component" />
                <bdt className="block-component" />,
                <bdt className="question">MA</bdt>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
                <bdt className="block-component" />
                <bdt className="question">02056</bdt>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </strong>
            <strong>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
              <bdt className="block-component" />
              <bdt className="block-component" />
            </strong>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: "15px" }}>
            <bdt className="question">
              <strong>United States</strong>
            </bdt>
            <strong>
              <bdt className="statement-end-if-in-editor">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="statement-end-if-in-editor">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <strong>
                            <span style={{ fontSize: "15px" }}>
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </span>
                          </strong>
                          <bdt className="statement-end-if-in-editor">
                            <strong />
                          </bdt>
                        </span>
                      </bdt>
                    </span>
                  </strong>
                </span>
              </bdt>
            </strong>
          </span>
        </div>
        <strong>
          <strong>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <strong>
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <strong>
                    <bdt className="block-component" />
                    Phone: <bdt className="question">(+1)7816573317</bdt>
                    <bdt className="statement-end-if-in-editor" />
                  </strong>
                </span>
              </strong>
            </div>
          </strong>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <strong>
              <strong>
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <strong>
                    <bdt className="block-component" />
                  </strong>
                </span>
              </strong>
            </strong>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <strong>
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                <strong>
                  <bdt className="question">info@corpguidance.net</bdt>
                </strong>
              </span>
            </strong>
          </div>
        </strong>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\tul {\n\tlist-style-type: square;\n\t}\n\tul > li > ul {\n\tlist-style-type: circle;\n\t}\n\tul > li > ul > li > ul {\n\tlist-style-type: square;\n\t}\n\tol li {\n\tfont-family: Arial ;\n\t}\n",
        }}
      />
    </div>
  );
}
