// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// import libraries..
import qs from "qs";
import md5 from "md5";

// import antd
import { Form, Input, Button, message, Popover } from "antd";

// import models
import User from "models/user/user";

// import utilities
import {
  deleteQueryParam,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";

// import assets
import Logo from "assets/images/logo.png";
import PasswordImg from "assets/images/auth-img.png";
import {
  onlyAlphabetsAndSpecialChars,
  passwordMessage,
  passwordRegex,
} from "utilities/regexUtility";
import PasswordValidation from "features/signUp/Components/PasswordValidation";

export default function ResetPassword(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const invite = new URLSearchParams(window.location.search).get("invite");
  const [password, setPassword] = useState();

  useEffect(() => {
    // User.logoutCall();
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const resetPassword = async (values) => {
    const token = new URLSearchParams(window.location.search).get("token");
    let encryptedPass = md5(values.password);
    let res;
    try {
      if (invite) {
        values.invite = true;
        values.token = token;
        values.password = encryptedPass;
        delete values["confirmPassword"];

        res = await User.resetPassword(values);
        User.logoutCall();
      } else {
        const body = { password: encryptedPass, token };
        res = await User.resetPassword(body);
        navigate("/login", { replace: true });
      }
      // const { from } = location.state || { from: { path: "/" } };
      message.success(res.message);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  return (
    <React.Fragment>
      <div className="auth-container login-wrap">
        <div className="login-img">
          <img className="one-time-img" src={PasswordImg} />
        </div>
        <div className="login-card">
          <div className="lc-logo">
            <img src={Logo} />
          </div>
          <h4>{invite ? "Set Account Information" : "Reset Password"}</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={resetPassword}
            layout="vertical"
          >
            {invite && (
              <>
                {" "}
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name",
                    },

                    {
                      min: 3,
                      message: "First name must be minimum 3 characters.",
                    },
                    {
                      max: 14,
                      message: "First name should be less than 15 character",
                    },
                    {
                      pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                      message:
                        "Field does not accept numbers and leading spaces",
                    },
                  ]}
                >
                  <Input placeholder="First Name" size="large" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "please input your last name",
                    },
                    {
                      pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                      message:
                        "Field does not accept numbers and leading spaces",
                    },
                    {
                      min: 3,
                      message: "Last name must be minimum 3 characters.",
                    },
                    {
                      max: 14,
                      message: "Last name should be less than 15 character",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" size="large" />
                </Form.Item>
              </>
            )}
            <Popover
              content={<PasswordValidation password={password} />}
              trigger={"focus"}
              placement="leftTop"
            >
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "please input password",
                  },
                  {
                    pattern: new RegExp(passwordRegex),
                    message: "",
                  },
                ]}
              >
                <Input.Password
                  suffix={<i className="icon-lock"></i>}
                  placeholder="New Password"
                  size="large"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            </Popover>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "please confirm your password!",
                },
                // {
                //   pattern: new RegExp(passwordRegex),
                //   message: passwordMessage,
                // },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password does not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                suffix={<i className="icon-lock"></i>}
                placeholder="Confirm Password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button block size="large" type="primary" htmlType="submit">
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
