import React, { useEffect, useMemo, useState } from "react";

// Style Import
import "features/dashboard/dashboardGraph.scss";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { getTimeframe } from "redux/settings/settingsSlice";
import { timeframe } from "redux/settings/settingsSlice";

// Utility function imports
import { TimeframeOptions } from "utilities/generalConstants";
import {
  epochToDateSpecialFormat,
  startAndEndDates,
} from "utilities/dateUtility";
import { changeMonthFormate, intervalDates } from "utilities/generalUtility";
import moment from "moment";

// Redux actions and selectors imports
import {
  compareInfractions,
  fetchCompareInfractions,
} from "redux/dashboard/dasboardSlice";

// Ant Design (antd) imports
import { Column } from "@ant-design/plots";
import { Card, DatePicker, Row, Col, Typography, Button } from "antd";
const { Title } = Typography;

export default function DashboardGraph() {
  // Redux States
  const dispatch = useDispatch();
  const timeFrameData = useSelector(timeframe);
  const compare = useSelector(compareInfractions);

  // States
  const [firstInterval, setFirstInterval] = useState({});
  const [secondInterval, setSecondInterval] = useState({});
  const [firstIntervalDisabled, setFirstIntervalDisabled] = useState("");
  const [secondIntervalDisabled, setSecondIntervalDisabled] = useState("");
  const [firstIntervalDisplay, setFirstIntervalDisplay] = useState({});
  const [secondIntervalDisplay, setSecondIntervalDisplay] = useState({});
  const [defaultInterval, setDefaultInterval] = useState({});

  // Variables and Functions
  const config = {
    xField: "date",
    yField: "count",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    // color: () => {
    //   return compare?.infractionTypeColor;
    // },
    tooltip: {
      showMarkers: false,
      fields: ["count"],
    },
    meta: {
      date: {
        alias: "Period",
      },
      count: {
        alias: "Count",
      },
    },
    autoFit: "true",
    minColumnWidth: 8,
    maxColumnWidth: 46,
  };

  const datePicker =
    timeFrameData?.timeframe === TimeframeOptions.MONTHLY
      ? "month"
      : timeFrameData?.timeframe === TimeframeOptions.YEARLY
      ? "year"
      : timeFrameData?.timeframe === TimeframeOptions.QUARTERLY
      ? "quarter"
      : null;

  useEffect(() => {
    dispatch(getTimeframe());
  }, []);

  useEffect(() => {
    if (datePicker) {
      const intervals = intervalDates(datePicker);
      setDefaultInterval(intervals);
      const firstInterval = startAndEndDates(
        intervals?.firstInterval,
        datePicker
      );
      const secondInterval = startAndEndDates(
        intervals?.secondInterval,
        datePicker
      );

      dispatch(
        fetchCompareInfractions({
          firstIntervalStartDate: firstInterval?.startDate,
          firstIntervalEndDate: firstInterval?.endDate,
          secondIntervalStartDate: secondInterval?.startDate,
          secondIntervalEndDate: secondInterval?.endDate,
        })
      );

      setFirstInterval(firstInterval);
      setFirstIntervalDisplay(firstInterval);
      setSecondInterval(secondInterval);
      setSecondIntervalDisplay(secondInterval);
    }
  }, [timeFrameData]);



  const memoizedGraphs = useMemo(
    () =>
      compare?.map((data) => (
        <>
          <Col xs={24} xl={12}>
            <div className="dg-user-info">
              <Title>{data?.infractionType}</Title>
              <p>{data?.firstIntervalTotalCount}</p>
            </div>
            <div className="dg-wrapper">
              <Column
                {...{
                  color: () => {
                    return data?.infractionTypeColor;
                  },
                  data:
                    data?.timeframe === TimeframeOptions.MONTHLY
                      ? changeMonthFormate(data?.firstInterval)
                      : data?.firstInterval,
                  ...config,
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="dg-user-info">
              <Title>{data?.infractionType}</Title>
              <p>{data?.secondIntervalTotalCount}</p>
            </div>
            <div className="dg-wrapper">
              <Column
                {...{
                  color: () => {
                    return data?.infractionTypeColor;
                  },
                  data:
                    data?.timeframe === TimeframeOptions.MONTHLY
                      ? changeMonthFormate(data?.secondInterval)
                      : data?.secondInterval,
                  ...config,
                }}
              />
            </div>
          </Col>
        </>
      )),
    [compare]
  );
  return (
    <>
      {datePicker && timeFrameData && (
        <Card
          title="Company Analytics"
          className="app-card dashboard-graph"
          extra={
            <>
              <DatePicker
                defaultValue={moment(defaultInterval)}
                onChange={(date) => {
                  setFirstInterval(startAndEndDates(date, datePicker));
                  setSecondIntervalDisabled(date);
                }}
                picker={datePicker}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, "day")) ||
                  (firstIntervalDisabled &&
                    firstIntervalDisabled?.isSame(current, datePicker))
                }
                clearIcon={null}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
              <p>VS</p>
              <DatePicker
                defaultValue={
                  datePicker === "month"
                    ? moment(defaultInterval?.secondInterval).subtract(
                        1,
                        "month"
                      )
                    : datePicker === "year"
                    ? moment(defaultInterval?.secondInterval).subtract(
                        1,
                        "year"
                      )
                    : datePicker === "quarter" &&
                      moment(defaultInterval?.secondInterval).subtract(
                        3,
                        "months"
                      )
                }
                onChange={(date) => {
                  setSecondInterval(startAndEndDates(date, datePicker));
                  setFirstIntervalDisabled(date);
                }}
                picker={datePicker}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, "day")) ||
                  (secondIntervalDisabled &&
                    secondIntervalDisabled?.isSame(current, datePicker))
                }
                clearIcon={null}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
              <Button
                type="primary"
                onClick={async () => {
                  await dispatch(
                    fetchCompareInfractions({
                      firstIntervalStartDate: firstInterval?.startDate,
                      firstIntervalEndDate: firstInterval?.endDate,
                      secondIntervalStartDate: secondInterval?.startDate,
                      secondIntervalEndDate: secondInterval?.endDate,
                    })
                  );

                  setFirstIntervalDisplay(firstInterval);
                  setSecondIntervalDisplay(secondInterval);
                }}
              >
                Compare
              </Button>
            </>
          }
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} xl={12}>
              <Title level={2}>{`${epochToDateSpecialFormat(
                firstIntervalDisplay?.startDate
              )} - ${epochToDateSpecialFormat(
                firstIntervalDisplay?.endDate
              )}`}</Title>
            </Col>
            <Col xs={24} xl={12}>
              <Title level={2}>{`${epochToDateSpecialFormat(
                secondIntervalDisplay?.startDate
              )} - ${epochToDateSpecialFormat(
                secondIntervalDisplay?.endDate
              )}`}</Title>
            </Col>
            {compare && memoizedGraphs}
          </Row>
        </Card>
      )}
    </>
  );
}
