import K from "utilities/constants";
import Cookies from "js-cookie";
import User from "models/user/user";
import { getGmtOffset } from "utilities/dateUtility";

export default class Request {
  constructor(
    relativeURL,
    method = K.Network.Method.GET,
    body = null,
    defaultHeaderType = K.Network.Header.Type.Json,
    headers = {},
    isTenant = true
  ) {
    // const token = Cookies.get(K.Cookie.Key.Token);
    const token = User.getToken();
    const gmtOffset = getGmtOffset();
    const domainPrefix = Cookies.get(K.Cookie.Key.Tenant);
    headers = {
      ...(defaultHeaderType === K.Network.Header.Type.Json
        ? {
            ...K.Network.Header.Default(token),
          }
        : K.Network.Header.Authorization(token)),
      gmtOffset: gmtOffset,
      ipAddress: User.getUserIp(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...headers,
    };
    this.url = isTenant
      ? K.Network.URL.TenantURL(domainPrefix) + relativeURL
      : K.Network.URL.BaseAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
  }

  // Tenant calls.
  static getTenant() {
    return new Request(K.Network.URL.GetTenant, K.Network.Method.GET);
  }

  // common services
  static getIndustries() {
    return new Request(
      K.Network.URL.GetIndustry,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
