import K from "utilities/constants";
import Request from "../../request";

export default class superAdminServices {
  static getAdminDashboard(body) {
    console.log({ body });
    return new Request(
      K.Network.URL.AdminDashboard.GetAdminDashboard,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
