import React, { useEffect } from "react";

// Style Imports
import "features/settings/paymentSettings/paymentSettings.scss";

// Ant Design (antd) Imports
import {
  Typography,
  Card,
  Row,
  Col,
  Button,
  Divider,
  List,
  Modal,
  Radio,
} from "antd";

// Redux actions and selectors Imports
import { useDispatch, useSelector } from "react-redux";
import {
  activePlan,
  allPlans,
  checkout,
  fetchPlans,
} from "redux/plan/planSlice";

const { Title } = Typography;

export default function UpgradePlanModal({
  modalUpgradePlanOpen,
  setModalUpgradePlanOpen,
}) {
  // Redux state
  const dispatch = useDispatch();
  const plans = useSelector(allPlans);
  const activePlanData = useSelector(activePlan);

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);
  return (
    <>
      <Modal
        title="Upgrade Plan"
        centered
        width={800}
        okText="Ok"
        cancelText="Cancel"
        open={modalUpgradePlanOpen}
        onOk={() => setModalUpgradePlanOpen(false)}
        onCancel={() => setModalUpgradePlanOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal all-plans-card upgrade-plan-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <div className="upgrade-plan-info-header">
          {/* <Button
              type="button"
              className="ant-btn ant-btn-default ant-btn-background-ghost"
              size="large"
            >
              Cancel Subscription
            </Button> */}
          <Radio.Group defaultValue="month" buttonStyle="solid" size="large">
            <Radio.Button value="month">Monthly</Radio.Button>
            {/* <Radio.Button disabled={true} value="year">
              Yearly
            </Radio.Button> */}
          </Radio.Group>
        </div>

        <Row gutter={[20, 20]}>
          {plans &&
            plans?.map((plan) => (
              <Col xs={24} sm={12} md={12} lg={8}>
                <Card
                  className={
                    plan?.id === activePlanData?.plan?.id
                      ? "payment-card active-card"
                      : "payment-card"
                  }
                >
                  <Title level={2}>{plan?.name}</Title>
                  <p>
                    ${plan?.price}
                    <span>/month</span>
                  </p>
                  <Divider />
                  <List
                    footer={
                      <>
                        <Button
                          type="primary"
                          disabled={
                            plan?.id === activePlanData?.plan?.id ||
                            plan?.id === 1
                          }
                          size="large"
                          onClick={() =>
                            dispatch(checkout({ planId: plan?.id }))
                          }
                        >
                          {plan?.id === activePlanData?.plan?.id
                            ? "Current Plan"
                            : plan?.id === 1
                            ? "Expired"
                            : "Choose Plan"}
                        </Button>
                      </>
                    }
                    className="payment-list-info"
                  >
                    {plan?.description &&
                      plan?.description?.split(".").map((point) => (
                        <List.Item>
                          <i className="icon-tick"></i>
                          <span>{point}</span>
                        </List.Item>
                      ))}
                  </List>
                </Card>
              </Col>
            ))}
        </Row>
      </Modal>
    </>
  );
}
