import K from "utilities/constants";
import Request from "../../request";

export default class companyServices {
  static getAllCompanysList(body) {
    return new Request(
      K.Network.URL.Company.GetAllCompaniesList,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getCompanyById(body) {
    return new Request(
      K.Network.URL.Company.GetCompanyById,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getCompanyRevenueById(body) {
    return new Request(
      K.Network.URL.Company.GetCompanyRevenueData,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static extendingTrial(body) {
    return new Request(
      K.Network.URL.Company.ExtendFreeTrial,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static changeStatus(body) {
    return new Request(
      K.Network.URL.Company.ToggleStatus,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static superAdminSetting(body) {
    return new Request(
      K.Network.URL.Company.SuperAdminCompanySetting,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }
}
