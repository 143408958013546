// import react & redux
import React, { useEffect, useMemo, useState } from "react";

// import antd
import { Typography, Card, Row, Col, DatePicker, Tooltip } from "antd";

// import utilities
import { dateToEpochFormat, endDateToEpochFormat } from "utilities/dateUtility";
import { handleError } from "utilities/generalUtility";

// import models
import superAdminDashboard from "models/superAdminDashboard/superAdminDashboard";

// import components
import RevenueGenerated from "./revenueGeneratedGraph";
import CompanyRegistered from "./companyRegisteredGraph";
import TrialGraph from "./trialGraph";

// import styles
import "features/admin/adminDashboard.scss";

// import libraries
import moment from "moment";

const { Title } = Typography;

export default function AdminDashboard() {
  // states, hooks & variables
  const currentDate = new Date();
  const [allData, setAllData] = useState(null);
  const previousMonthFirstDate = dateToEpochFormat(
    new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
  );
  const previousMonthLastDate = endDateToEpochFormat(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
  );
  const year = currentDate.getFullYear();
  const currentYearFirstDate = dateToEpochFormat(
    moment({ year, month: 0, date: 1 })
  );
  const currentYearLastDate = endDateToEpochFormat(
    moment({ year, month: 11, date: 31 })
  );
  let unitlist = ["", "K", "M", "G"];
  function formatnumber(number) {
    let sign = Math.sign(number);
    let unit = 0;

    while (Math.abs(number) >= 1000) {
      unit = unit + 1;
      number = Math.floor(Math.abs(number) / 100) / 10;
    }
    return sign * Math.abs(number) + unitlist[unit];
  }
  const [payload, setPayload] = useState({
    startDate: currentYearFirstDate,
    endDate: currentYearLastDate,
    monthStartDate: previousMonthFirstDate,
    monthEndDate: previousMonthLastDate,
  });

  const disabledDate = (current) => {
    if (!current) {
      return false;
    }

    const currentYear = moment().year();
    const selectedYear = current.year();

    return selectedYear < 2023 || selectedYear > currentYear;
  };

  const paidTrialGraphConfig = {
    data: allData?.paidTrialGraphData ?? [],
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "name",
    minColumnWidth: 8,
    maxColumnWidth: 16,
    color: ({ name }) => (name === "Paid" ? "#3A6EB2" : "#B8CBE3"),
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  };

  const tenantsGraphConfig = {
    data: allData?.tenantsGraphData ?? [],
    xField: "date",
    yField: "count",
    seriesField: "price",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    legend: false,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: () => "#3A6EB2",
    autoFit: "true",
    minColumnWidth: 8,
    maxColumnWidth: 16,
  };

  const revenueGraphConfig = {
    data: allData?.revenueGraphData ?? [],
    xField: "date",
    yField: "Revenue",
    seriesField: "price",
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: () => "#3A6EB2",
    autoFit: "true",
    minColumnWidth: 8,
    maxColumnWidth: 16,
  };

  // functions
  const handleGetAdminDashboard = (date) => {
    const year = date.year();
    const firstDate = dateToEpochFormat(moment({ year, month: 0, date: 1 }));
    const lastDate = endDateToEpochFormat(
      moment({ year, month: 11, date: 31 })
    );
    setPayload((prev) => ({
      ...prev,
      startDate: firstDate,
      endDate: lastDate,
    }));
  };

  const getDashboardApiCall = async (payload) => {
    try {
      const response = await superAdminDashboard.getAdminDashboard(payload);
      setAllData(response?.data);
    } catch (error) {
      handleError(error);
    }
  };

  // useEffects
  useEffect(() => {
    getDashboardApiCall(payload);
  }, [payload]);

  // useMemo - to stop re-rendering
  const memoizedRevenueGraph = useMemo(
    () => <RevenueGenerated config={revenueGraphConfig}></RevenueGenerated>,
    [allData]
  );
  const memoizedTenantsGraph = useMemo(
    () => <CompanyRegistered config={tenantsGraphConfig}></CompanyRegistered>,
    [allData]
  );
  const memoizedPaidTrialGraph = useMemo(
    () => <TrialGraph config={paidTrialGraphConfig}></TrialGraph>,
    [allData]
  );

  return (
    <>
      <Card title="Dashboard" className="app-card admin-dashboard-card">
        <div className="admin-slider-card">
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card className="revenue-card">
                <p>Total revenue last month</p>
                <Tooltip
                  title={"$ " + allData?.tenantsData?.totalRevenueLastMonth}
                >
                  <Title level={2}>
                    {allData &&
                      "$ " +
                        formatnumber(
                          allData?.tenantsData?.totalRevenueLastMonth
                        )}
                  </Title>
                </Tooltip>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card className="revenue-card">
                <p>Total Revenue generated</p>
                <Tooltip
                  title={"$ " + allData?.tenantsData?.totalRevenueLifeTime}
                >
                  <Title level={2}>
                    {allData &&
                      "$ " +
                        formatnumber(
                          allData?.tenantsData?.totalRevenueLifeTime
                        )}
                  </Title>
                </Tooltip>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card className="revenue-card">
                <p>Companies registered last month</p>
                <Title level={2}>
                  {allData?.tenantsData?.totalTenantsLastMonth}
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <Card className="revenue-card">
                <p>Total no. of companies registered</p>
                <Title level={2}>{allData?.tenantsData?.totalTenants}</Title>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="range-picker">
          <DatePicker
            defaultValue={moment(`${year}-01-01`, "YYYY-MM-DD")}
            onChange={handleGetAdminDashboard}
            picker="year"
            allowClear={false}
            disabledDate={disabledDate}
          />
        </div>
        <Row gutter={[20, 20]}>
          {memoizedRevenueGraph}
          {memoizedTenantsGraph}
          {memoizedPaidTrialGraph}
        </Row>
      </Card>
    </>
  );
}
