// import react & redux
import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

// import libraries
import qs from "qs";

// import antd
import {
  Form,
  Input,
  Button,
  message
} from "antd";

// import models
import User from "models/user/user";

// import utilities
import {
  deleteQueryParam,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";

// import assets
import Logo from "assets/images/logo.png";
import PasswordImg from "assets/images/auth-img.png";

export default function ForgotPassword() {
  const location = useLocation();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    const payload = {
      email: values.email,
      // host: `${URL.Protocol}://${URL.Client.BaseHost}:${URL.Client.BasePort}`,
    };
    try {
      await User.forgotPassword(payload);
      message.success(`An email has been sent to ${values.email}`);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="auth-container login-wrap">
        <div className="login-img">
          <img className="one-time-img" src={PasswordImg} />
        </div>
        <div className="login-card">
          <div className="lc-logo">
            <img src={Logo} />
          </div>
          <h4>Forgot Password</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Enter a valid email format!" },
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                type="email"
                suffix={<i className="icon-email"></i>}
                placeholder="example@example.com"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button block size="large" type="primary" htmlType="submit">
                Send Instructions
              </Button>
            </Form.Item>
            <Form.Item>

              <Link to="/login">
                <Button block size="large" ghost className="btn btn-primary">
                Sign In
                </Button>
              </Link>
            </Form.Item>
            <p className="text-center mb-0">
              Don’t have an Account?
              <Link to="/signup">
                <strong>Sign Up</strong>
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
