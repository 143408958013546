import { Typography } from "antd";
import React from "react";
import SupervisorInvite from "../supervisor/supervisorInvite";

const { Title } = Typography;

export default function ManageInvite() {
  return (
    <>
      <SupervisorInvite />
    </>
  );
}