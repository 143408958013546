// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIndustries, allIndustries } from "redux/user/userSlice";

// import antd
import {
  Form,
  message,
  Row,
  Col,
  Select,
  Upload,
  Button,
  Avatar,
  Tooltip,
} from "antd";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";

// import libraries
import { DebounceInput } from "react-debounce-input";

// import utilities
import { setFieldErrorsFromServer } from "utilities/generalUtility";
import { withoutSpecialCharacters } from "utilities/regexUtility";

// import requests
import NetworkCall from "network/networkCall";
import authServices from "network/services/authServices";

export default function CompanyInfo({ form }) {
  // states, variables and hooks
  const dispatch = useDispatch();
  const industriesList = useSelector(allIndustries);
  const [companyDomainPrefix, setCompanyDomainPrefix] = useState("");
  const [imageFile, setImageFile] = useState(
    form.getFieldValue("tempImgFile") ?? null
  );
  const props = {
    name: "file",
    className: "w-100 d-block bulk-upload-btn",
    showUploadList: false,
    maxCount: 1,
    listType: "picture",
    accept: "image/png, image/jpeg, image/jpg, image/svg, image/gif",
    beforeUpload: (info) => {
      return false;
    },

    onChange: async (info) => {
      const imageSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
      const isLt2M = info.file.size < imageSizeLimit;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return Upload.LIST_IGNORE;
      } else {
        getBase64(info.file).then((value) => {
          setImageFile(value);
          form.setFieldsValue({ tempImgFile: value });
        });
        form.setFieldsValue({
          logo: info?.file,
        });
      }
    },
  };

  // functions
  const suggestDomainPrefix = async (event) => {
    try {
      const response = await NetworkCall.fetch(
        authServices.getSuggestedDomainPrefix({ name: event.target.value }),
        false
      );
      form.setFieldsValue({
        companyDomainPrefix: response?.data?.domainPrefix,
      });
      setCompanyDomainPrefix(response?.data?.domainPrefix);
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };

  const verifyDomainPrefix = async (event) => {
    try {
      const response = await NetworkCall.fetch(
        authServices.verifyDomainPrefix({ domainPrefix: event.target.value }),
        false
      );
      let flag = response?.data?.isDomainPrefixAvailable;
      if (flag) {
        let error = {
          error: true,
          data: null,
          message: {
            companyDomainPrefix: [
              <div style={{ color: "#3A833A" }}>This Domain is Available</div>,
            ],
          },
        };

        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      } else {
        let error = {
          error: true,
          data: null,
          message: {
            companyDomainPrefix: ["This Domain is not Available"],
          },
        };

        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      }
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };

  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    dispatch(fetchIndustries());
  }, []);

  return (
    <React.Fragment>
      <Form
        className="steps-form"
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              name={"companyName"}
              label="Company Name"
              className="add-icon-input"
              rules={[
                {
                  required: true,
                  message: "please input company name",
                },
                {
                  pattern: new RegExp(withoutSpecialCharacters),
                  message:
                    "company name should not contain special characters or spaces",
                },
              ]}
            >
              <DebounceInput
                className="ant-input ant-input-lg add-input"
                minLength={2}
                debounceTimeout={900}
                onChange={(event) => suggestDomainPrefix(event)}
                placeholder="Spectrum Delivery"
                suffix={<i className="icon-company"></i>}
              />
              {/* <i className="icon-company"></i>- */}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              name={"industryId"}
              label="Industry"
              rules={[
                {
                  required: true,
                  message: "please select industry",
                },
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                size="large"
                defaultValue="Select Industry"
                options={industriesList.map((element) => {
                  return {
                    label: element.name,
                    value: element.id,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row profile-settings" xs={24} sm={12}>
            <Form.Item
              className="input-label-wrap"
              initialValue={null}
              label={
                <>
                  <div className="label-input">
                    Company Logo
                    {imageFile ? (
                      <Avatar size={24} src={imageFile} />
                    ) : (
                      <Avatar size={24} icon={<UserOutlined />} />
                    )}
                  </div>
                </>
              }
              name="logo"
            >
              <Upload {...props}>
                <Button size="large" icon={<UploadOutlined />} block>
                  Click to {imageFile ? "Change" : "Upload"}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className="label-input add-icon-input"
              label="Company Domain Prefix"
              name={"companyDomainPrefix"}
              rules={[
                {
                  required: true,
                  message: "domain prefix should not be empty",
                },
              ]}
            >
              <Tooltip
                placement="right"
                title="Domain Prefix is automatically generated. You can customize it if you like."
              >
                <DebounceInput
                  className="ant-input ant-input-lg add-input"
                  minLength={2}
                  debounceTimeout={900}
                  onChange={(event) => verifyDomainPrefix(event)}
                  placeholder="www"
                  value={companyDomainPrefix}
                />
              </Tooltip>

              {/* <i className="icon-company-domain"></i> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}
