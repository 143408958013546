import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Style Imports
import "features/settings/paymentSettings/paymentSettings.scss";

// Ant Design (antd) Imports
import { Typography, Card, Row, Col, Button } from "antd";

// Redux actions and selectors Imports
import { useDispatch, useSelector } from "react-redux";
import { changeUserGlobalDetail, userGlobalDetail } from "redux/user/userSlice";
import {
  activePlan,
  fetchActivePlan,
  fetchInvoice,
  fetchPermissions,
  getPermissions,
  goToCustomerPortal,
} from "redux/plan/planSlice";

// Querry params import
import qs from "qs";

// Components
import UpgradePlanModal from "./upgradePlanModal";

// General constants & Utility functions Imports
import { epochToDateSpecialFormat } from "utilities/dateUtility";
import { SubscriptionStatus } from "utilities/generalConstants";

const { Title } = Typography;

export default function PaymentSettings() {
  // State
  const [modalUpgradePlanOpen, setModalUpgradePlanOpen] = useState(false);

  // Redux state
  const dispatch = useDispatch();
  const planData = useSelector(activePlan);
  const permissions = useSelector(getPermissions);
  const userGlobalData = useSelector(userGlobalDetail);
  // Other Variables and Functions
  const location = useLocation();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchActivePlan());

    navigate({ search: "" });

    if (paramJson?.success) dispatch(fetchInvoice());

    if (paramJson?.stripe || paramJson?.success) dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (permissions?.length > 0 && planData) {
      dispatch(
        changeUserGlobalDetail(userGlobalData, {
          tenant: {
            ...userGlobalData?.data?.tenant,
            subscriptionStatus: planData?.subscriptionStatus,
          },
          role: {
            ...userGlobalData?.data?.role,
            rolePermissionRelations: permissions,
          },
        })
      );
    }
  }, [permissions, planData]);

  return (
    <>
      {planData && (
        <Card title="Payment Plans" className="app-card payment-plan-card">
          <Row gutter={[20, 20]}>
            <Col xs={24} md={24} lg={14} xl={16}>
              <Card className="monthly-plan-card">
                <div className="monthly-plan-card-wrap">
                  <div>
                    <div className="heading-info">
                      <Button type="primary" style={{ pointerEvents: "none" }}>
                        {planData?.plan?.name}
                      </Button>
                    </div>
                    <p className="desc-info">{planData?.plan?.description}.</p>
                  </div>
                  <div className="payment">
                    <p className="payment-info">
                      ${planData?.plan?.price}
                      <span>/month</span>
                    </p>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setModalUpgradePlanOpen(true)}
                      disabled={planData?.plan?.id === 1 ? false : true}
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={24} lg={10} xl={8}>
              <Card className="next-payment-card">
                <p>
                  {planData?.subscriptionStatus === SubscriptionStatus?.TRIAL ||
                  planData?.subscriptionStatus === SubscriptionStatus?.EXTENDED
                    ? "Your Trial Will Expire"
                    : planData?.subscriptionStatus ===
                      SubscriptionStatus?.ACTIVE
                    ? planData?.code
                      ? "Your Plan Will Be Canceled"
                      : "Your Next Payment Is Due"
                    : planData?.subscriptionStatus ===
                      SubscriptionStatus?.PAUSED
                    ? "Your Subscription Will Automatically Resume"
                    : planData?.subscriptionStatus ===
                      SubscriptionStatus?.EXPIRED
                    ? "Your Subscription Has Expired"
                    : //assuming cancel
                      "Your Subscription Has Been Canceled"}
                </p>
                <Title level={2}>
                  On {epochToDateSpecialFormat(planData?.subscriptionEndDate)}
                </Title>
                {planData?.plan?.id > 1 && (
                  <Button
                    type="default"
                    className="ant-btn-background-ghost"
                    size="large"
                    onClick={() => {
                      dispatch(goToCustomerPortal());
                    }}
                  >
                    Manage Payments
                  </Button>
                )}
              </Card>
            </Col>
          </Row>
        </Card>
      )}
      {modalUpgradePlanOpen && (
        <UpgradePlanModal
          modalUpgradePlanOpen={modalUpgradePlanOpen}
          setModalUpgradePlanOpen={setModalUpgradePlanOpen}
        />
      )}
    </>
  );
}
