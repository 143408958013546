import K from "utilities/constants";
import Request from "../request";

export default class authServices {
  static registerUser(body) {
    return new Request(
      K.Network.URL.Auth.SignUp,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static loginUser(email, password, rememberMe) {
    const body = {
      email,
      password,
      rememberMe,
    };
    return new Request(
      K.Network.URL.Auth.LoginUser,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static loginWithGoogle(data) {
    const body = {
      ...data,
    };
    return new Request(
      K.Network.URL.Auth.LoginWithGoogle,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static forgotPassword(body) {
    return new Request(
      K.Network.URL.Auth.ForgotPassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static resetPassword(values) {
    const body = {
      ...values,
    };
    return new Request(
      K.Network.URL.Auth.ResetPassword,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getSuggestedDomainPrefix(body) {
    return new Request(
      K.Network.URL.Tenants.SuggestDomainPrefix,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static verifyDomainPrefix(body) {
    return new Request(
      K.Network.URL.Tenants.VerifyDomainPrefix,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static verifyEmail(email) {
    const body = {
      email,
    };
    return new Request(
      K.Network.URL.Auth.CheckEmail,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static sendOTP(body) {
    return new Request(
      K.Network.URL.Auth.SendOTP,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static verifyOTP(body, accessToken) {
    return new Request(
      K.Network.URL.Auth.VerifyOTP,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      { Authorization: "Bearer " + accessToken },
      false
    );
  }
}
