import NetworkCall from "network/networkCall";
import K from "utilities/constants";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { redirectToLogin } from "utilities/generalUtility";
import { saveUserData } from "redux/user/userSlice";
import authServices from "network/services/authServices";
import axios from "axios";

export default class User {
  // API call using thunk.
  static async register(data) {
    const res = await NetworkCall.fetch(authServices.registerUser(data));
    const { tenant, ...user } = res;
    const encryptedUser = CryptoJS.AES.encrypt(
      // JSON.stringify({ accessToken: user?.data?.accessToken }),
      JSON.stringify(user),
      K.Cookie.Key.EncryptionKey
    );
    Cookies.set(K.Cookie.Key.User, encryptedUser, {
      path: "/",
      domain: "." + K.Network.URL.Client.BaseTenantHost,
      expires: "",
    });
    return user;
  }

  static loginCall(email, password, remember) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(
        authServices.loginUser(email, password, remember)
      );
      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(user),
        K.Cookie.Key.EncryptionKey
      );
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: "." + K.Network.URL.Client.BaseTenantHost,
        expires: remember ? 365 : "",
      });
      // TODO: Dispatch with your reducer
      // dispatch(upsertModel(User, user));
      dispatch(saveUserData(user.data));
      return user;
    };
  }

  static loginWithGoogle(data) {
    return async (dispatch) => {
      const user = await NetworkCall.fetch(
        authServices.loginWithGoogle(data),
        false
      );

      // message.success(user?.message);

      let encryptedUser = CryptoJS.AES.encrypt(
        JSON.stringify(user),
        K.Cookie.Key.EncryptionKey
      );
      Cookies.set(K.Cookie.Key.User, encryptedUser, {
        path: "/",
        domain: K.Network.URL.Client.BaseTenantHost,
        expires: true ? 365 : "",
      });

      // here we can store loggedIn user date to redux store
      // dispatch(saveUserData(user));
      return user;
    };
  }

  static logoutCall(error = "") {
    Cookies.remove(K.Cookie.Key.User, {
      path: "/",
      domain: "." + K.Network.URL.Client.BaseTenantHost,
    });
    Cookies.remove("IPAddress", {
      path: "/",
      domain: K.Network.URL.Client.BaseTenantHost,
    });
    redirectToLogin(error);
  }

  //Forgot password
  static async forgotPassword(payload) {
    const user = await NetworkCall.fetch(authServices.forgotPassword(payload));
    return user;
  }

  //Reset password
  static async resetPassword(body) {
    // return async (dispatch) => {
    const user = await NetworkCall.fetch(authServices.resetPassword(body));
    // Cookies.set(K.Cookie.Key.User, token, {
    //   path: "/",
    //   domain: "." + K.Network.URL.Client.BaseHost,
    // });

    // Cookies.set(K.Cookie.Key.Tenant, user.tenant.domainPrefix, {
    //   path: "/",
    //   domain: "." + K.Network.URL.Client.BaseHost,
    // });

    return user;
    // }
  }

  // Selectors

  // Helpers
  static getUserObjectFromCookies() {
    let cookieUser = Cookies.get(K.Cookie.Key.User);
    let bytes = cookieUser
      ? CryptoJS.AES.decrypt(cookieUser, K.Cookie.Key.EncryptionKey)
      : "{}";
    try {
      let utfBytes = bytes.toString(CryptoJS.enc.Utf8);
      // console.log("Cookie data is: ", JSON.parse(utfBytes));
      // console.log("Cookie Ip data is: ", Cookies.get("IPAddress"));

      return JSON.parse(utfBytes);
    } catch (error) {
      return this.logoutCall("User unauthorized");
    }
  }

  static isTokenAvailable() {
    return this.getUserObjectFromCookies().data?.accessToken ? true : false;
  }

  static getUserDetails() {
    return this.getUserObjectFromCookies();
  }
  static getTenant() {
    return this.getUserObjectFromCookies().data?.tenant?.domainPrefix ?? null;
  }

  static getToken() {
    return this.getUserObjectFromCookies().data?.accessToken ?? "";
  }

  static getName() {
    let user = this.getUserObjectFromCookies()?.data;
    return `${user?.firstName} ${user?.lastName}` ?? "";
  }

  static getThemeColor() {
    return this.getUserObjectFromCookies()?.data?.tenant?.themeColor;
  }

  static getEmail() {
    return this.getUserObjectFromCookies()?.email ?? "";
  }
  static roles() {
    return [K.Roles.User];
  }
  static isSuperAdmin() {
    return this.getUserObjectFromCookies()?.data?.role?.id === 1 ||
      this.getUserObjectFromCookies()?.data?.role?.id === 2
      ? true
      : false;
  }
  static getUserId() {
    return this.getUserObjectFromCookies()?.data?.id;
  }
  static getUserPlanId() {
    return this.getUserObjectFromCookies()?.data?.tenant?.planId ?? null;
  }

  static getUserIp() {
    return Cookies.get("IPAddress");
  }
  static getSubscriptionStatus() {
    return this.getUserObjectFromCookies()?.data?.tenant?.subscriptionStatus;
  }

  static getUserIpFromNetWork = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      Cookies.remove("IPAddress", {
        path: "/",
        domain: K.Network.URL.Client.BaseTenantHost,
      });
      Cookies.set("IPAddress", res.data.ip, {
        path: "/",
        domain: K.Network.URL.Client.BaseTenantHost,
      });
    } catch (error) {}
  };
}
