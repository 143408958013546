const generalConstants = Object.freeze({
  defaultFilters: {
    page: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirectionAsc: false,
    searchArray: [],
  },
  employeeStatusColors: {
    Probation: "warning",
    Active: "green",
    Resigned: "orange",
    Suspended: "purple",
    Terminated: "red",
  },
  subscriptionStatusColors: {
    ACTIVE: "green",
    TRIAL: "gold",
    EXTENDED: "cyan",
    PAUSED: "geekblue",
    CANCELLED: "magenta",
    EXPIRED: "volcano",
    DEACTIVE: "red",
  },

  infractionStatusColors: {
    Accommodated: "green",
    Escalated: "red",
    "Pending Escalation": "orange",
  },
});

export const TimeframeOptions = Object.freeze({
  YEARLY: "YEARLY",
  QUARTERLY: "QUARTERLY",
  MONTHLY: "MONTHLY",
});
export const SubscriptionStatus = Object.freeze({
  EXTENDED: "EXTENDED",
  ACTIVE: "ACTIVE",
  TRIAL: "TRIAL",
  CANCELLED: "CANCELLED",
  PAUSED: "PAUSED",
  EXPIRED: "EXPIRED",
});

export default generalConstants;
