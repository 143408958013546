import K from "utilities/constants";
import Request from "../request";

export default class noticeServices {
  static getVariables() {
    return new Request(
      K.Network.URL.Notice.GetVariables,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static createType(body) {
    return new Request(
      K.Network.URL.Notice.CreateInfractionType,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getInfractionNotices(id) {
    return new Request(
      `${K.Network.URL.Notice.GetInfractionNotice}/${id}`,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static updateInfractionNotices(body) {
    return new Request(
      K.Network.URL.Notice.EditInfractionNotice,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static generateNotice(body) {
    return new Request(
      K.Network.URL.Notice.GenerateNotice,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static markAnEscalation(body) {
    return new Request(
      K.Network.URL.Notice.MarkEscalation,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static toggleArchiveInfractionType(body) {
    return new Request(
      K.Network.URL.Notice.ToggleArchiveInfractionType,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
