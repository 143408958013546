import K from "utilities/constants";
import Request from "../request";

export default class infractionServices {
  static viewEscalationById(id) {
    return new Request(
      `${K.Network.URL.Infraction.ViewEscalation}/${id}`,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getInfractionList(body) {
    return new Request(
      K.Network.URL.Infraction.GetInfractionList,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getInfractionTypeList(body) {
    return new Request(
      K.Network.URL.Infraction.GetInfractionTypeList,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getEmployeeInfractions(body) {
    return new Request(
      K.Network.URL.Infraction.GetEmployeeInfractionsCount,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static createInfraction(body) {
    return new Request(
      K.Network.URL.Infraction.CreateInfraction,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updateInfraction(body) {
    return new Request(
      K.Network.URL.Infraction.EditInfraction,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static deleteInfraction(id) {
    return new Request(
      `${K.Network.URL.Infraction.DeleteInfraction}/${id}`,
      K.Network.Method.DELETE,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static CompareInfraction(body) {
    return new Request(
      K.Network.URL.Infraction.InfractionCompare,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static changeInfractionStatus(body) {
    return new Request(
      K.Network.URL.Infraction.ChangeinfractionStatus,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
