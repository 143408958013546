import React from "react";
import K from "utilities/constants";
import {
  DashboardOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import User from "models/user/user";

// Template a navigation item
// {
//     name: 'User',
//     path: '/user/list',
//     icon: <ProjectOutlined />,
//     roles: [],
//     children: [], // If item has children, then the path field will be ignored.
// }

const defaultChildren = (basePath) => [
  { path: `${basePath}/infractions-list`, name: "Infraction Types" },
  { path: `${basePath}/time-frame`, name: "Infraction Timeframe" },
  { path: `${basePath}/company-policy`, name: "Company Policy" },
  { path: `${basePath}/company-settings`, name: "Company Settings" },
  { path: `${basePath}/company-feedback`, name: "Company Feedback" },
  {
    path: `${basePath}/store/create`,
    name: "Create",
    roles: [K.Roles.Admin],
  },
];
// false only super admin
// true then accoding to permissions
const navigations = [
  // Only Admin's
  {
    name: "Dashboard",
    parentName: "Admin Dashboard",
    path: "/admin-dashboard",
    icon: <DashboardOutlined />,
    superAdmin: true,
  },
  {
    name: "Manage Companies",
    parentName: "Admin Manage Companies",
    path: "/admin-manage-companies",
    icon: (
      <span>
        <i className="icon-user-plus"></i>
      </span>
    ),
    superAdmin: true,

    roles: [],
  },

  // Only Tenant's
  {
    name: "Dashboard",
    parentName: "Dashboard",
    path: "/dashboard",
    icon: <DashboardOutlined />,
    superAdmin: false,
  },
  {
    name: "Manage Employees",
    parentName: "Manage Employees",
    path: "/manage-employees",
    icon: (
      <span>
        <i className="icon-user-group"></i>
      </span>
    ),
    superAdmin: false,
  },
  {
    name: "Manage Infractions",
    parentName: "Manage Infractions",
    path: "/manage-infractions",
    icon: <InfoCircleOutlined />,
    superAdmin: false,
  },
  // Common
  {
    name: "Manage Invites",
    parentName: User.isSuperAdmin() ? "Admin Manage Invites" : "Manage Invites",
    path: "/manage-invite",
    icon: (
      <span>
        <i className="icon-user-plus"></i>
      </span>
    ),
  },
  {
    name: "Manage Payments",
    parentName: "Manage Payments",
    path: "/manage-payments",
    icon: <WalletOutlined />,
    superAdmin: false,
  },
  {
    name: "Settings",
    parentName: "Settings",
    icon: <SettingOutlined />,
    roles: [],
    children: defaultChildren("/settings"),
    superAdmin: false,
  },
];

export default navigations;
