import { Card, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import {
  analytics,
  fetchCompanyAnalytics,
} from "redux/dashboard/dasboardSlice";
import { allInfractions } from "redux/infraction/infractionSlice";
const { Title } = Typography;

export default function DashboardAnalytics({ startDate, endDate }) {
  const infractionUpdates = useSelector(allInfractions);
  const companyAnalytics = useSelector(analytics);
  const dispatch = useDispatch();

  const infractionTypesSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    nextArrow: <i className="icon-arrow-circle"></i>,
    speed: 500,
    slidesToShow: companyAnalytics.length < 3 ? companyAnalytics.length : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:
            companyAnalytics.length < 2 ? companyAnalytics.length : 2,
        },
      },
      // {
      //   breakpoint: 992,
      //   settings: {
      //     slidesToShow:
      //       companyAnalytics.length < 2 ? companyAnalytics.length : 2,
      //   },
      // },
      // {
      //   breakpoint: 767,
      //   settings: {
      //     slidesToShow:
      //       companyAnalytics.length < 2 ? companyAnalytics.length : 2,
      //   },
      // },
      {
        breakpoint: 575,
        settings: {
          slidesToShow:
            companyAnalytics.length < 1 ? companyAnalytics.length : 1,
        },
      },
    ],
  };

  const getCompanyAnalytics = () => {
    try {
      const body = {
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(fetchCompanyAnalytics(body));
    } catch (error) {}
  };

  useEffect(() => {
    if (startDate && endDate) {
      getCompanyAnalytics();
    }
  }, [startDate, endDate, infractionUpdates]);

  const getTotalCount = () => {
    let count = 0;
    companyAnalytics?.map((e) => {
      return (count = count + e?.InfractionCount);
    });
    return count;
  };

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} xl={6}>
        <Card className="dc-infraction-card">
          <Title>{getTotalCount()}</Title>
          <p>Total Number of Infractions</p>
        </Card>
      </Col>
      <Col xs={24} xl={18}>
        <Card className="infraction-type-card">
          <Title>Infraction Types</Title>
          <Slider {...infractionTypesSliderSettings} className="itypes-info">
            {companyAnalytics?.map((e) => {
              return (
                <div className="it-info">
                  <Title>{e?.infractionTypeName}</Title>
                  <p>{e?.InfractionCount}</p>
                </div>
              );
            })}
          </Slider>
        </Card>
      </Col>
    </Row>
  );
}
