import React from "react";
import User from "models/user/user";
import { Navigate } from "react-router-dom";
import {
  isPermissionPresent,
  isRolePresent,
  redirectIfInvalidTenant,
} from "utilities/generalUtility";
import { SubscriptionStatus } from "utilities/generalConstants";

export default function RouteWithSubRoutes({ route }) {
  if (
    !route.authenticated ||
    (route.authenticated && User.isTokenAvailable())
  ) {
    const defaultPathname = User.isSuperAdmin()
      ? "/admin-dashboard"
      : [
          SubscriptionStatus.PAUSED,
          SubscriptionStatus.CANCELLED,
          SubscriptionStatus.EXPIRED,
        ].includes(User.getSubscriptionStatus())
      ? "/manage-payments"
      : "/dashboard";

    // Check domain prefix
    redirectIfInvalidTenant();

    if (window.location.pathname === "/" && route.path === "*") {
      return (
        <Navigate
          replace
          to={{
            pathname: defaultPathname,
            state: { from: route.location },
          }}
        />
      );
    }
    if (
      ["/login", "/signup", "/forgot-password"].includes(route.path) &&
      User.isTokenAvailable()
    )
      return (
        <Navigate
          replace
          to={{
            pathname: defaultPathname,
            state: { from: route.location },
          }}
        />
      );

    // Check roles
    const hasRole = isRolePresent(route.roles, User.roles());
    const user = User.getUserDetails();
    if (hasRole && route.parentName === "guest") {
      const component = (
        <route.component {...route} route={route}></route.component>
      );

      return route.layout ? (
        <route.layout>{component}</route.layout>
      ) : (
        component
      );
    } else {
      if (
        hasRole && route.parentName
          ? isPermissionPresent(route.parentName, user?.data?.role)
          : false
      ) {
        const component = (
          <route.component {...route} route={route}></route.component>
        );

        return route.layout ? (
          <route.layout>{component}</route.layout>
        ) : (
          component
        );
      } else {
        return (
          <Navigate
            replace
            to={{
              pathname: defaultPathname,
            }}
          />
        );
      }
    }
  } else {
    return (
      <Navigate
        replace
        to={{ pathname: "/login", state: { from: route.location } }}
      />
    );
  }
}
