import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  Tag,
  Descriptions,
  Typography,
  DatePicker,
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { Column } from "@ant-design/plots";
import { Link, useParams } from "react-router-dom";
import "./adminCompanyDetail.scss";
import Company from "models/company/company";
import { epochToDateFormat, epochToMomentTime } from "utilities/dateUtility";
import generalConstants from "utilities/generalConstants";
import { dateToEpochFormat } from "utilities/dateUtility";
import moment from "moment";
const { Title, Paragraph } = Typography;

export default function AdminCompanyDetail() {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState();
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [extend, setExtend] = useState(0);
  const [loader, setloader] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [animation, setanimation] = useState(true);
  const [companyStatus, setCompanyStatus] = useState();
  let unitlist = ["", "K", "M", "G"];
  function formatnumber(number) {
    let sign = Math.sign(number);
    let unit = 0;

    while (Math.abs(number) >= 1000) {
      unit = unit + 1;
      number = Math.floor(Math.abs(number) / 100) / 10;
    }
    return sign * Math.abs(number) + unitlist[unit];
  }
  // datail
  const getDetails = async () => {
    const current = new Date();
    current.setMonth(current.getMonth() - 1);

    // Get the start date of the previous month
    const startOfMonth = new Date(current.getFullYear(), current.getMonth(), 1);

    // Get the end date of the previous month
    const endOfMonth = new Date(
      current.getFullYear(),
      current.getMonth() + 1,
      0
    );

    const startInterval = dateToEpochFormat(startOfMonth);
    const endInterval = dateToEpochFormat(endOfMonth);

    const body = {
      tenantId: parseInt(id),
      startDate: startInterval,
      endDate: endInterval,
    };
    try {
      const res = await Company.getCompanyDetail(body);
      setCompanyData(res?.data);
    } catch (error) {}
  };

  const getRevenue = async (year) => {
    const start = dateToEpochFormat(new Date(year, 0, 1));
    const end = dateToEpochFormat(new Date(year, 11, 31));
    const body = {
      tenantId: parseInt(id),
      startDate: start,
      endDate: end,
    };
    try {
      const { data } = await Company.getCompanyRevenue(body);
      setGraphData(data);
    } catch {}
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    getDetails();
    getRevenue(currentYear);
  }, [id]);

  const handleChange = (date, dateString) => {
    getRevenue(dateString);
  };

  const brandColor = "#3A6EB2";

  const config = {
    data: graphData,
    xField: "date",
    yField: "Revenue",
    seriesField: "price",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    color: () => {
      return brandColor;
    },
    meta: {
      type: {
        alias: "",
      },
      sales: {
        alias: "",
      },
    },
    minColumnWidth: 8,
    maxColumnWidth: 22,
  };
  const disabledDate = (current) => {
    return current && current.year() > moment().year();
  };

  const handleExtension = async () => {
    try {
      setloader(true);
      const body = {
        tenantId: parseInt(id),
        days: extend,
      };

      const res = await Company.extendingFreeTrial(body);
      message.success(
        `Trial extented to ${epochToDateFormat(res.data.subscriptionEndDate)}`
      );
      setModal1Open(false);
      setloader(false);
      setExtend(0);
      getDetails();
    } catch (error) {
      setloader(false);
    }
  };

  const toggleStatus = async () => {
    try {
      const body = {
        companyId: parseInt(id),
        active: !companyStatus,
      };
      const res = await Company.changeCompanyStatus(body);
      message.success(res?.message);
      setModal2Open(false);
      getDetails();
    } catch (error) {
      setModal2Open(false);
    }
  };

  useEffect(() => {
    setTimeout(() => setanimation(false), 2000);
  }, [animation]);

  return (
    <>
      <Card
        className="company-details employees-card card-header-responsive app-card"
        title={
          <>
            Company Detail{" "}
            <span className="bold-text">| {companyData?.name}</span>
          </>
        }
        extra={
          <Tooltip
            title={
              companyData?.subscriptionStatus === "CANCEL" ||
              companyData?.subscriptionStatus === "EXTENDED" ||
              companyData?.subscriptionStatus === "TRIAL"
                ? null
                : `Subscription Status : ${companyData?.subscriptionStatus}`
            }
          >
            <Button
              type="primary"
              disabled={
                companyData?.subscriptionStatus === "CANCEL" ||
                companyData?.subscriptionStatus === "EXTENDED" ||
                companyData?.subscriptionStatus === "TRIAL"
                  ? false
                  : true
              }
              onClick={() => setModal1Open(true)}
            >
              Extend Free Trial
            </Button>
          </Tooltip>
        }
      >
        <Card className="inner-card">
          <div className="card-header-wrap">
            <div className="card-header">
              {companyData?.logo ? (
                <Avatar size={55} src={companyData.logo} />
              ) : (
                <Avatar size={55} icon={<UserOutlined />} />
              )}

              <div>
                <span className="delivery-title">{companyData?.name}</span>
                <div>
                  <Tag
                    color={
                      generalConstants.subscriptionStatusColors[
                        companyData?.isActive ? "ACTIVE" : "DEACTIVE"
                      ]
                    }
                  >
                    {companyData?.isActive ? "Active" : "Deactive"}
                  </Tag>
                  <span>{companyData?.industry?.name}</span>
                </div>
              </div>
            </div>
            <div className="card-header-actions">
              <Button
                size="small"
                type="link"
                onClick={() => {
                  setModal2Open(true);
                  setCompanyStatus(companyData?.isActive);
                }}
                icon={<i className="icon-power"></i>}
              />
              <Link
                to={`/admin-edit-company/${id}`}
                className="ant-btn-text"
                type="text"
              >
                <i className="icon-edit"></i>
              </Link>
            </div>
          </div>
          <Descriptions
            size="small"
            colon={false}
            className="card-content"
            layout="vertical"
            column={{ xl: 4, lg: 3, md: 2, xs: 1 }}
          >
            <Descriptions.Item label="Company Admin Name:">
              {companyData?.primaryContact?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Company Admin Email:">
              {companyData?.primaryContact?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Company Admin Number:">
              {companyData?.primaryContact?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Designation:">
              {" "}
              {companyData?.primaryContact?.designation}
            </Descriptions.Item>
            <Descriptions.Item label="Subscription Plan:">
              <div className="">
                <Tag
                  color={
                    generalConstants.subscriptionStatusColors[
                      companyData?.subscriptionStatus
                    ]
                  }
                >
                  {companyData?.subscriptionStatus}
                </Tag>
                {`    ${companyData?.plan?.name}`}
              </div>{" "}
            </Descriptions.Item>
            <Descriptions.Item label="Company Domain Prefix:">
              {companyData?.domainPrefix}
            </Descriptions.Item>
            <Descriptions.Item label="Registered Date:">
              {epochToDateFormat(companyData?.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Subscription End Date:">
              {epochToDateFormat(companyData?.subscriptionEndDate)}
            </Descriptions.Item>
          </Descriptions>
          <div className="last-date">
            <br />
            {/* <Title level={5}>Last Contact Date: 12/08/2022</Title> */}
            <span>
              Number of Extended Free Trial:{" "}
              {companyData?.extendedTrialCount || 0}
            </span>
          </div>
        </Card>
      </Card>
      <Card className="company-report-wrap" title="Company Reports">
        <div className="d-flex company-report">
          <div className="card-left-side">
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12} md={12} lg={24}>
                <Card>
                  <div className="company-report-content">
                    <Title level={5} className="mb-0">
                      Revenue Generated over lifetime
                    </Title>
                    <Tooltip title={"$" + companyData?.lifeTimeRevenue}>
                      <span>
                        {"$" + formatnumber(companyData?.lifeTimeRevenue)}
                      </span>
                    </Tooltip>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={24}>
                <Card>
                  <div className="company-report-content">
                    <Title level={5} className="mb-0">
                      Revenue Generated over last month
                    </Title>
                    <Tooltip title={"$" + companyData?.monthlyRevenueData}>
                      <span>
                        {"$" + formatnumber(companyData?.monthlyRevenueData)}
                      </span>
                    </Tooltip>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={24}>
                <Card>
                  <div className="company-report-content">
                    <Title level={5} className="mb-0">
                      Number of Employees Registered
                    </Title>
                    <span>{companyData?.employeeCount}</span>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={12} lg={24}>
                <Card>
                  <div className="company-report-content">
                    <Title level={5} className="mb-0">
                      Number of Infractions over lifetime
                    </Title>
                    <span> {companyData?.infractionCount}</span>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <Card
            className="card-right-side card-body"
            title="Revenue Generated"
            extra={
              <>
                <DatePicker
                  picker="year"
                  onChange={handleChange}
                  defaultValue={moment()}
                  allowClear={false}
                  disabledDate={(current) => {
                    const createdAtYear = moment(
                      epochToMomentTime(companyData?.createdAt)
                    ).year();
                    const currentYear = moment().year();

                    if (!current) {
                      return false;
                    }

                    const selectedYear = current.year();

                    return (
                      selectedYear < createdAtYear || selectedYear > currentYear
                    );
                  }}
                />
              </>
            }
          >
            {graphData ? <Column animation={animation} {...config} /> : ""}
          </Card>
        </div>
      </Card>
      <Modal
        width={800}
        visible={modal1Open}
        onCancel={() => {
          setModal1Open(false);
          setExtend(0);
        }}
        className="trial-modal"
        closeIcon={<i className="icon-close close-icon"></i>}
        centered
        footer={[
          <Button
            ghost
            onClick={() => {
              setModal1Open(false);
              setExtend(0);
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleExtension}
            disabled={extend === 0}
            loading={loader}
          >
            Extend
          </Button>,
        ]}
      >
        <Title level={5} className="mb-0">
          Extend Free Trial
        </Title>
        <Paragraph className="mb-0">
          Select how many days you want to extend the free trial.
        </Paragraph>
        <div className="number-field">
          <Button
            class="input-number-decrement"
            onClick={() => {
              setExtend((prev) => prev - 1);
            }}
            disabled={extend < 1}
          >
            –
          </Button>
          <input class="input-number" type="text" value={extend} />
          <Button
            class="input-number-increment"
            onClick={() => {
              setExtend((prev) => prev + 1);
            }}
          >
            +
          </Button>
        </div>
      </Modal>
      <Modal
        width={800}
        title={`${companyStatus ? "Deactivate" : "Activate"}`}
        centered
        okText="Yes"
        cancelText="No"
        visible={modal2Open}
        onOk={toggleStatus}
        onCancel={() => setModal2Open(false)}
        className="app-modal"
        closeIcon={<i className="icon-close close-icon"></i>}
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <Paragraph>{`Are you sure you want to ${
          companyStatus ? "deactivate" : "activate"
        } this company?`}</Paragraph>
      </Modal>
    </>
  );
}
