// import react & redux
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  allHistory,
  fetchAllHistoryList,
  totalRecords,
} from "redux/history/historySlice";

// import antd
import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Modal } from "antd";

// import styles
import "./employeeHistory.scss";

// import model
import Employee from "models/employee/employee";

// import utilities
import { epochToDateFormat } from "utilities/dateUtility";
import {
  downloadCsvFile,
  handleError,
  setDisplayFormat,
} from "utilities/generalUtility";

// import components
import GenericTable from "features/common/genericTable";
import { userGlobalDetail } from "redux/user/userSlice";

function EmployeeHistory() {
  // states, hooks and variables
  const list = useSelector(allHistory);
  const location = useLocation();
  const userName = location.state.name;
  const pagination = useSelector(totalRecords);
  const { employeeId } = useParams();
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userDetail = useSelector(userGlobalDetail);
  const datesArray = [
    "joiningDate",
    "statusStartDate",
    "statusEndDate",
    "infractionDate",
  ];

  const showModal = (employeeData) => {
    if (employeeData) {
      const { createdAt, updatedAt, ...restObj } = employeeData;
      setEmployeeDetail(restObj);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const tableProps = {
    scroll: { x: 900 },
    className: "app-table",
  };
  const columns = [
    {
      title: "Activity Date",
      dataIndex: "createdAt",
      type: "range",
      sorter: true,
    },
    {
      title: "Activity Performed",
      dataIndex: "title",
      type: "string",
      sorter: true,
    },
    {
      title: "Details",
      dataIndex: "description",
      type: "string",
      sorter: true,
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      type: "string",
      sorter: true,
      render: (ipAddress) => <span>{ipAddress || "--"}</span>,
    },
    {
      title: "Performed by",
      dataIndex: "userFirstName",
      type: "string",
      sorter: true,
      render: (userFirstName) => <span>{userFirstName || "System"}</span>,
    },
    {
      title: "History Details",
      dataIndex: "employeeData",
      align: "center",
      render: (employeeData) => (
        <EyeOutlined
          style={{ color: userDetail?.data?.tenant?.themeColor || "#1A56A5" }}
          size="large"
          onClick={() => {
            showModal(employeeData);
          }}
        />
      ),
    },
  ];

  // functions
  const handleDownloadEmployeeHistory = async () => {
    try {
      const response = await Employee.downloadEmployeeHistory({
        employeeId: parseInt(employeeId),
      });
      downloadCsvFile(response, "employeeHistory.csv");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Card
        title={
          <>
            Employee History{" "}
            <span className="bold-text">
              | {location.state.firstName + " " + location.state.lastName}{" "}
            </span>{" "}
          </>
        }
        className="history-card card-header-responsive "
        extra={
          <>
            <Button
              type="primary"
              onClick={handleDownloadEmployeeHistory}
              icon={<i className="icon-download"></i>}
            ></Button>
          </>
        }
      >
        <GenericTable
          data={list}
          columns={columns}
          apiCall={fetchAllHistoryList}
          total={pagination}
          tableProps={tableProps}
          idKey={employeeId ? "employeeId" : undefined}
          idValue={employeeId}
        />
      </Card>
      <Modal
        title="History Details"
        open={isModalOpen}
        className="app-modal"
        closeIcon={<i className="icon-close"></i>}
        onCancel={handleCancel}
        footer={null}
      >
        {employeeDetail &&
          Object?.keys(employeeDetail)?.map((key) => (
            <p>
              <b>{`${key
                ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^\w/, (c) => c.toUpperCase())}`}</b>
              :{" "}
              {key === "imageUrl" ? (
                <a
                  href={employeeDetail[key]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {setDisplayFormat(employeeDetail[key])}
                </a>
              ) : datesArray.includes(key) ? (
                epochToDateFormat(employeeDetail[key])
              ) : (
                setDisplayFormat(employeeDetail[key])
              )}
            </p>
          ))}
      </Modal>
    </>
  );
}

export default EmployeeHistory;
