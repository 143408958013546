// import react & redux
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  changeCompanyPolicyStatus,
  changeEmployeeResignStatus,
  employeeDetail,
  fetchEmployeeStatuses,
  getEmployeeDetailById,
} from "redux/employee/employeeSlice";

// import styles
import "features/dashboard/dashboardGraph.scss";
import "./supervisorEmployees.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// import antd
import {
  Card,
  Button,
  Tag,
  Descriptions,
  Checkbox,
  message,
  Avatar,
  Tooltip,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

// import utilities
import { epochToDateFormat } from "utilities/dateUtility";

// import components
import AddEditEmployeeModal from "features/manageEmployees/addEditEmployeeModal";
import generalConstants from "utilities/generalConstants";
import MarkInfractionModal from "features/common/markInfractionModal";
import EmployeeInfractionSummary from "./employeeInfractionSummary";
import InfractionsListing from "features/common/infractionsListing";
import CountInfractionListing from "features/common/countInfractionListing";
import AccommodateInfactionModal from "features/common/accomodateModal";

import { getTimeframe } from "redux/settings/settingsSlice";
import CompareInfractions from "./supervisorCompareInfractions";
import EscalationModal from "features/common/escalationModal";

const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export default function SupervisorEmployees() {
  // states, variables & hooks
  const [modalAccomodateOpen, setModalAccomodateOpen] = useState(false);
  const [infractionId, setinfractionId] = useState();
  const [isAddEditEmployeeModalOpen, setIsAddEditEmployeeModalOpen] =
    useState(false);
  const [compareInfraction, setCompareInfraction] = useState(false);
  const dispatch = useDispatch();
  const [modalCountInfractionOpen, setCountInfractionOpen] = useState(false);
  const [employeeId1, setEmployeeId] = useState(null);
  const data = useSelector(employeeDetail);
  const [modalMarkInfractionOpen, setModalMarkInfractionOpen] = useState(false);
  const { employeeId } = useParams();
  const [modalEsclateOpen, setModalEsclateOpen] = useState(false);
  const [InfractionTypeId, setInfractionTypeId] = useState();

  let [payloadFilters, setPayloadFilters] = useState(
    employeeId
      ? {
          ...generalConstants.defaultFilters,
          searchArray: [
            {
              key: "employeeId",
              value: employeeId,
              type: "string",
            },
          ],
        }
      : generalConstants.defaultFilters
  );

  // functions
  const onChangeResignStatus = (e) => {
    const status = {
      status: e?.target?.checked,
    };
    dispatch(changeEmployeeResignStatus(data?.id, status));
  };

  const onChangeCompanyPolicyStatus = (e) => {
    const status = {
      signedCompanyPolicy: e?.target?.checked,
    };
    dispatch(changeCompanyPolicyStatus(data?.id, status));
  };

  // useEffects
  useEffect(() => {
    dispatch(getTimeframe());
  }, []);

  useEffect(() => {
    dispatch(fetchEmployeeStatuses());
    dispatch(getEmployeeDetailById(employeeId));
  }, [employeeId]);

  return (
    <>
      <Card
        className="company-details employees-card card-header-responsive app-card"
        title={
          <>
            Employees Detail{" "}
            <span className="bold-text">
              |{" "}
              {`${data?.firstName?.trim() || ""} ${
                data?.lastName?.trim() || ""
              }`}
            </span>
          </>
        }
        extra={
          <div className="employees-card-btns">
            <Link
              className="ant-btn ant-btn-default ant-btn-background-ghost"
              to="employee-history"
              state={{
                firstName: data?.firstName || "",
                lastName: data?.lastName || "",
              }}

              // ghost
            >
              Employee History
            </Link>
            <Tooltip
              title={
                data?.status?.id === 3 || data?.status?.id === 5
                  ? "Can't Mark Infraction because employee status is teminated or resigned"
                  : ""
              }
            >
              <Button
                ghost
                onClick={() => setModalMarkInfractionOpen(true)}
                disabled={
                  data?.status?.id === 3 || data?.status?.id === 5
                    ? true
                    : false
                }
              >
                Mark Infraction
              </Button>
            </Tooltip>
            {/* <Button type="primary" onClick={() => setModal2Open(true)}>
              Extend Probation
            </Button> */}
          </div>
        }
      >
        <Card className="inner-card top-card">
          <div className="card-header-wrap">
            <div className="card-header">
              {data?.imageUrl ? (
                <Avatar size={55} src={data?.imageUrl} />
              ) : (
                <Avatar size={55} icon={<UserOutlined />} />
              )}

              {/* <Upload {...props}>
                <Button
                  className="profile-icon"
                  icon={<CameraOutlined className="camera-icon" />}
                ></Button>
              </Upload> */}
              <div>
                <Link to="">{`${data?.firstName?.trim() || ""} ${
                  data?.lastName?.trim() || ""
                }`}</Link>
              </div>
            </div>
            <div className="card-header-actions">
              <Checkbox
                size="small"
                className="checkbox"
                checked={data?.markResigned}
                onChange={onChangeResignStatus}
              >
                Mark as Resigned
              </Checkbox>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  setIsAddEditEmployeeModalOpen(true);
                }}
                icon={<i className="icon-edit"></i>}
              />
            </div>
          </div>
          <Descriptions
            size="small"
            colon={false}
            className="card-content"
            layout="vertical"
            column={{ xl: 4, lg: 3, md: 2, xs: 1 }}
          >
            <Descriptions.Item label="Email:">{data?.email}</Descriptions.Item>
            <Descriptions.Item label="Phone Number:">
              {data?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Joining Date:">
              {epochToDateFormat(data?.joiningDate)}
            </Descriptions.Item>
            <Descriptions.Item label="Signed Form:">
              <Checkbox
                checked={data?.signedCompanyPolicy}
                size="small"
                onChange={onChangeCompanyPolicyStatus}
              >
                Company Policy
              </Checkbox>
            </Descriptions.Item>
            <Descriptions.Item label="Average Infractions per Month:">
              {data?.averageInfractionPerMonth ?? "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Status:">
              <div className="">
                <Tag
                  className="mr-2"
                  color={
                    generalConstants.employeeStatusColors[data?.status?.name]
                  }
                >
                  {data?.status?.name}
                </Tag>
              </div>{" "}
            </Descriptions.Item>

            <Descriptions.Item label="Status Start Date:">
              {epochToDateFormat(data?.status?.startDate)}
            </Descriptions.Item>
            <Descriptions.Item label="Status End Date:">
              {epochToDateFormat(data?.status?.endDate)}
            </Descriptions.Item>

            <Descriptions.Item label="Reason for Status:">
              <div className="">{data?.status?.infractionType ?? "N/A"}</div>{" "}
            </Descriptions.Item>
            <Descriptions.Item label="Comment:">
              <div className="">{data?.status?.comments ?? "N/A"}</div>{" "}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Card>

      {compareInfraction ? (
        <CompareInfractions
          setCompareInfraction={setCompareInfraction}
          employeeId={data?.id}
        />
      ) : (
        <Card
          title="Infractions Detail"
          className="bottom-card app-card"
          extra={
            <button
              className="ant-btn ant-btn-default ant-btn-background-ghost"
              onClick={() => setCompareInfraction(true)}
            >
              Compare infractions
            </button>
          }
        >
          <EmployeeInfractionSummary
            employeeId={employeeId}
            joiningDate={data?.joiningDate}
          />
          <InfractionsListing employeeId={employeeId} markMode={false} />
        </Card>
      )}

      {modalMarkInfractionOpen && (
        <MarkInfractionModal
          open={modalMarkInfractionOpen}
          setOpen={setModalMarkInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          setEmployeeId={setEmployeeId}
          flag={true}
          user={{
            id: data?.id,
            name: data?.firstName,
            joiningDate: data?.joiningDate,
          }}
          setinfractionId={setinfractionId}
          setInfractionTypeId={setInfractionTypeId}
        />
      )}

      {modalCountInfractionOpen && (
        <CountInfractionListing
          modalCountInfractionOpen={modalCountInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          employeeId1={employeeId1}
          infractionId={infractionId}
          setModalAccomodateOpen={setModalAccomodateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
        />
      )}
      {modalAccomodateOpen && (
        <AccommodateInfactionModal
          statusId={infractionId}
          type={1}
          filters={payloadFilters}
          setModalAccomodateOpen={setModalAccomodateOpen}
          modalAccomodateOpen={modalAccomodateOpen}
        ></AccommodateInfactionModal>
      )}
      {modalEsclateOpen && (
        <EscalationModal
          modalEsclateOpen={modalEsclateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
          infractionTypeId={InfractionTypeId}
          infractionId={infractionId}
          filters={payloadFilters}
        ></EscalationModal>
      )}
      {/* <Modal
        width={800}
        centered
        visible={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        className="trial-modal"
        closeIcon={<i className="icon-close close-icon"></i>}
        footer={[
          <Button className="ant-btn-background-ghost">Cancel</Button>,
          <Button type="primary">Create</Button>,
        ]}
      >
        <Title level={5} className="mb-0">
          Extend Free Trial
        </Title>
        <Paragraph className="mb-0">
          Select how many days you want to extend the free trial.
        </Paragraph>
        <div className="number-field">
          <Button class="input-number-decrement">–</Button>
          <input class="input-number" type="text" value="30" min="0" max="10" />
          <Button class="input-number-increment">+</Button>
        </div>
      </Modal> */}

      {isAddEditEmployeeModalOpen && (
        <AddEditEmployeeModal
          isAddEditEmployeeModalOpen={isAddEditEmployeeModalOpen}
          setIsAddEditEmployeeModalOpen={setIsAddEditEmployeeModalOpen}
        />
      )}
    </>
  );
}
