import React, { useEffect, useState } from "react";
import qs from "qs";

import {
  Card,
  Button,
  Input,
  Form,
  message,
  Row,
  Col,
  Upload,
  Select,
  Avatar,
  Tooltip,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";

import {
  handleError,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import "./adminEditCompany.scss";
import {
  alphanumericRegex,
  onlyAlphabetsAdvanced,
  phoneRegex,
  leadingSpacesRegex,
  onlyAlphabetsAndSpecialChars,
} from "utilities/regexUtility";
import { allIndustries, fetchIndustries } from "redux/user/userSlice";
import { dateToEpochFormat } from "utilities/dateUtility";
import Company from "models/company/company";
import { DebounceInput } from "react-debounce-input";
import NetworkCall from "network/networkCall";
import authServices from "network/services/authServices";

const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
export default function AdminEditCompany() {
  const dispatch = useDispatch();
  const industriesList = useSelector(allIndustries);
  const location = useLocation();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [companyDomainPrefix, setCompanyDomainPrefix] = useState("");

  const { id } = useParams();
  const [isDomainPrefixAvailable, setIsDomainPrefixAvailable] = useState(true);
  const [imageFile, setImageFile] = useState(
    form.getFieldValue("tempImgFile") ?? null
  );
  const [img, setImg] = useState();
  const props = {
    name: "file",
    className: "w-100 d-block bulk-upload-btn",
    showUploadList: false,
    maxCount: 1,
    listType: "picture",
    accept: "image/png, image/jpeg, image/jpg, image/svg, image/gif",
    beforeUpload: (info) => {
      return false;
    },

    onChange: async (info) => {
      const imageSizeLimit = 2 * 1024 * 1024;
      const isLt2M = info.file.size < imageSizeLimit;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return Upload.LIST_IGNORE;
      } else {
        getBase64(info.file).then((value) => {
          setImageFile(info.file);
          setImg(value);
          form.setFieldsValue({ tempImgFile: value });
        });
        form.setFieldsValue({
          logo: info?.file,
        });
      }
    },
  };

  const getDetails = async () => {
    const current = new Date();
    current.setMonth(current.getMonth() - 1);

    const startOfMonth = new Date(current.getFullYear(), current.getMonth(), 1);

    const endOfMonth = new Date(
      current.getFullYear(),
      current.getMonth() + 1,
      0
    );

    const startInterval = dateToEpochFormat(startOfMonth);
    const endInterval = dateToEpochFormat(endOfMonth);

    const body = {
      tenantId: parseInt(id),
      startDate: startInterval,
      endDate: endInterval,
    };
    try {
      const { data } = await Company.getCompanyDetail(body);
      setImg(data?.logo);
      form.setFieldsValue({ ...data, industryId: data.industry.id });
      setCompanyDomainPrefix(data?.domainPrefix);
    } catch (error) {}
  };

  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    dispatch(fetchIndustries());
    getDetails();
  }, []);

  const handleSetting = async (values) => {
    const form_data = new FormData();
    form_data.append("tenantId", id);
    form_data.append("name", values.name);
    form_data.append("domainPrefix", companyDomainPrefix);
    form_data.append("address", values.address);
    form_data.append("industryId", values.industryId);
    form_data.append("primaryContactPersonName", values.primaryContact.name);
    form_data.append("designation", values.primaryContact.designation);
    form_data.append("phone", values.primaryContact.phone);
    form_data.append("logo", imageFile);
    try {
      if (isDomainPrefixAvailable) {
        const response = await Company.superAdminCompanySetting(form_data);

        message.success(response.message);
        getDetails();
      } else {
        message.error("Please enter valid domain prefix");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const suggestDomainPrefix = async (event) => {
    setCompanyDomainPrefix(event.target.value);
    try {
      const response = await NetworkCall.fetch(
        authServices.getSuggestedDomainPrefix({ name: event.target.value }),
        false
      );
      form.setFieldsValue({
        domainPrefix: response?.data?.domainPrefix,
      });
      setCompanyDomainPrefix(response?.data?.domainPrefix);
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };
  const verifyDomainPrefix = async (event) => {
    try {
      const response = await NetworkCall.fetch(
        authServices.verifyDomainPrefix({ domainPrefix: event.target.value }),
        false
      );
      let flag = response?.data?.isDomainPrefixAvailable;
      setIsDomainPrefixAvailable(flag);
      if (flag) {
        let error = {
          error: false,
          data: null,
          message: {
            domainPrefix: [
              <div style={{ color: "#3A833A" }}>This Domain is Available</div>,
            ],
          },
        };

        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      } else {
        let error = {
          error: true,
          data: null,
          message: {
            domainPrefix: ["This Domain is not Available"],
          },
        };
        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      }
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };
  return (
    <>
      <Card
        title="Edit Company"
        className="edit-company-card card-header-responsive"
        extra={
          <Button type="primary" onClick={() => form.submit()}>
            Save Changes
          </Button>
        }
      >
        {/*  */}
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSetting}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="name"
                label={
                  <>
                    <div className="label-input">Company Name:</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(alphanumericRegex),
                    message:
                      "company name should not contain special characters",
                  },
                ]}
              >
                <DebounceInput
                  className="ant-input ant-input-lg add-input"
                  minLength={2}
                  debounceTimeout={900}
                  onChange={(event) => suggestDomainPrefix(event)}
                  placeholder="Spectrum Delivery"
                  suffix={<i className="icon-company"></i>}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="comanylogo"
                className="company-logo"
                initialValue={null}
                label={
                  <>
                    <div className="label-input">
                      Company Logo:
                      {img ? (
                        <Avatar size={24} src={img} />
                      ) : (
                        <Avatar size={24} icon={<UserOutlined />} />
                      )}
                    </div>
                  </>
                }
              >
                <Upload {...props}>
                  <Button size="large" className="justify-content-center" block>
                    <span>
                      <UploadOutlined className="mr-2" />
                      Click to {img ? "Change" : "Upload"}
                    </span>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="industryId"
                label={
                  <>
                    <div className="label-input">Industry:</div>
                  </>
                }
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  size="large"
                  placeholder="Select Industry"
                  options={industriesList.map((element) => {
                    return {
                      label: element.name,
                      value: element.id,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="domainPrefix"
                label={
                  <>
                    <div className="label-input">Company Domain Prefix:</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "domain prefix should not be empty",
                  },
                ]}
              >
                <Tooltip
                  placement="right"
                  title="Domain Prefix is automatically generated. You can customize it if you like."
                >
                  <DebounceInput
                    className="ant-input ant-input-lg add-input"
                    minLength={2}
                    debounceTimeout={900}
                    onChange={(event) => verifyDomainPrefix(event)}
                    placeholder="www"
                    value={companyDomainPrefix}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              style={{ display: "none" }}
            >
              <Form.Item
                name={["primaryContact", "phone"]}
                label={
                  <>
                    <div className="label-input">Phone Number:</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input phone number",
                  },
                  {
                    pattern: new RegExp(phoneRegex),
                    message: "Phone number must be valid and without dashes",
                  },
                ]}
              >
                <Input placeholder="202-555-7838" size="middle" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name="address"
                label={
                  <>
                    <div className="label-input">Company Address:</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input the address",
                  },
                  {
                    pattern: new RegExp(leadingSpacesRegex),
                    message: "Leading spaces not allowed",
                  },
                ]}
              >
                <Input
                  placeholder="1522 Cohoe Ct, Kenai, Alaska 99611, USA"
                  size="middle"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name={["primaryContact", "name"]}
                label={
                  <>
                    <div className="label-input">Company Admin Name:</div>
                  </>
                }
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Name",
                //   },

                //   {
                //     min: 3,
                //     message: "Name must be minimum 3 characters.",
                //   },
                //   {
                //     max: 49,
                //     message: "Name should be less than 50 character",
                //   },
                //   {
                //     pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                //     message: "Field does not accept numbers and leading spaces",
                //   },
                // ]}
              >
                <Input placeholder="Darrell Steward" size="middle" disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                name={["primaryContact", "email"]}
                label={
                  <>
                    <div className="label-input">Company Admin Email:</div>
                  </>
                }
              >
                <Input
                  type="email"
                  placeholder="darrell.steward@gmail.com"
                  size="middle"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              style={{ display: "none" }}
            >
              <Form.Item
                name={["primaryContact", "designation"]}
                label={
                  <>
                    <div className="label-input">Designation:</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input primary contact designation",
                  },
                  {
                    pattern: new RegExp(onlyAlphabetsAdvanced),
                    message:
                      "Field does not accept numbers,special chracters and leading spaces",
                  },
                ]}
              >
                <Input placeholder="Manager" size="middle" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
