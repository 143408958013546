import K from "utilities/constants";
import Request from "../request";

export default class dashboardServices {
  static compareAllInfractions(body) {
    return new Request(
      K.Network.URL.Dashboard.CompareAllInfractions,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getTopOffenders(body) {
    return new Request(
      K.Network.URL.Dashboard.TopOffenders,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getCompanyAnalytics(body) {
    return new Request(
      K.Network.URL.Dashboard.CompanyAnalytics,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getDashboardGraph(body) {
    return new Request(
      K.Network.URL.Dashboard.InfractionGraph,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
