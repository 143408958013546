import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInfractions,
  infractionLoading,
  infractionStatus,
} from "redux/infraction/infractionSlice";

// import antd
import { Button, Modal, Row, Col, Input, Form, Divider } from "antd";

// import styles
import "features/managInfractions/manageInfractions.scss";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";
import generalConstants from "utilities/generalConstants";
import { fetchAllEmployeesList } from "redux/employee/employeeSlice";

const { TextArea } = Input;

export default function AccommodateInfactionModal({
  statusId,
  type,
  filters,
  modalAccomodateOpen,
  setModalAccomodateOpen,
  employeeMode,
}) {
  const loading = useSelector(infractionLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleStatus = (values) => {
    dispatch(
      infractionStatus(statusId, type, filters, values.comments, employeeMode)
    );

    setModalAccomodateOpen(false);
    dispatch(saveSearchFilterIndicator(false));
    dispatch(saveSortFilterIndicator(false));
  };
  const handleCancel = () => {
    dispatch(saveSearchFilterIndicator(false));
    dispatch(saveSortFilterIndicator(false));
    setModalAccomodateOpen(false);
    if (employeeMode) {
      dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
    }
  };

  return (
    <Modal
      title="Accommodate"
      centered
      // okText="Yes"
      // cancelText="No"
      width={800}
      open={modalAccomodateOpen}
      onOk={handleStatus}
      onCancel={handleCancel}
      closeIcon={<i className="icon-close"></i>}
      className="app-modal accomodate-modal"
      cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      footer={[
        <Button ghost onClick={handleCancel}>
          No
        </Button>,
        <Button
          form="myForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          Yes
        </Button>,
      ]}
    >
      <Form
        id="myForm"
        name="basic"
        layout="vertical"
        form={form}
        onFinish={handleStatus}
      >
        <Row gutter={20}>
          <Col span={24}>
            <p>Are you sure you want to accommodate this infraction?</p>
          </Col>
          <Divider></Divider>
          <Col span={24}>
            <Form.Item
              label="Comments:"
              name="comments"
              rules={[{ required: true, message: "Please enter some comment" }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
