import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import notificationServices from "network/services/notificationService";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationList: [],
    notificationListScrollably: [],
    hasMore: false,
    notificationCount: 0,
  },
  reducers: {
    saveNotifications: (state, action) => {
      state.notificationList = [...action.payload];
    },
    saveNotificationsScrollably: (state, action) => {
      action?.payload?.flag === 1
        ? (state.notificationListScrollably = [...action.payload.notifications])
        : (state.notificationListScrollably = [
            ...state.notificationListScrollably,
            ...action.payload.notifications,
          ]);
    },
    saveHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    saveNotificationsCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    resetNotificationCount: (state) => {
      state.notificationCount = 0;
    },
    incrementNotificationCount: (state) => {
      state.notificationCount = state.notificationCount + 1;
    },
  },
});
export const fetchNotificationList = (body) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        notificationServices.getAllNotifications(body),
        false
      );
      dispatch(saveNotifications(response?.data?.notifications));

      console.log(response?.data);
    } catch (error) {
      console.log({ error });
    }
  };
};

export const fetchNotificationByScroll = (body) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        notificationServices.getAllNotifications(body),
        false
      );
      dispatch(
        saveNotificationsScrollably({
          notifications: response?.data?.notifications,
          flag: body?.page,
        })
      );

      let pagination = response?.data?.pagination;
      if (pagination?.page * pagination?.pageSize < pagination?.totalCount) {
        dispatch(saveHasMore(true));
      } else {
        dispatch(saveHasMore(false));
      }

      console.log(response?.data);
    } catch (error) {
      console.log({ error });
    }
  };
};

export const getUnseenCount = () => {
  return async (dispatch, getState) => {
    try {
      const res = await NetworkCall.fetch(
        notificationServices.getUnseenCount(),
        false
      );
      dispatch(saveNotificationsCount(res?.data));
    } catch (error) {}
  };
};

export const {
  saveNotifications,
  saveHasMore,
  saveNotificationsScrollably,
  saveNotificationsCount,
  resetNotificationCount,
  incrementNotificationCount,
} = notificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const allNotifications = (state) => state.notifications.notificationList;
export const allNotificationsByScroll = (state) =>
  state.notifications.notificationListScrollably;

export const hasMore = (state) => state.notifications.hasMore;
export const unseen = (state) => state.notifications.notificationCount;

export default notificationSlice.reducer;
