import Dashboard from "features/dashboard/dashboard";
import DashboardGraph from "features/dashboard/dashboardGraph";
import ManageEmployees from "features/manageEmployees/manageEmployeesListing";
import ManageInvite from "features/manageInvite/manageInvite";
import ManageInfractions from "features/managInfractions/manageInfractions";
import InfractionsList from "features/settings/infractionsList/infractionsTypeList";
import CreateInfraction from "features/settings/infractionsList/createInfraction";
import EditAnInfraction from "features/settings/infractionsList/editAnInfraction";
import ViewInfractionType from "features/settings/infractionsList/editInfraction";
import TimeFrame from "features/settings/timeframe/timeframe";
import CompanySettings from "features/settings/companySettings/companySettings";
import CompanyPolicy from "features/settings/companyPolicy/companyPolicy";
import PaymentSettings from "features/settings/paymentSettings/paymentSettings";
import CompanyFeedback from "features/settings/companyFeedback/companyFeedback";
import Login from "features/login/login";
import ForgotPassword from "features/forgotPassword/forgotPassword";
import ResetPassword from "features/resetPassword/resetPassword";
import NotFound from "features/notFound/notFound";
import EmployeeHistory from "features/supervisor/employeeHistory";
import AdminSettings from "features/admin/adminSettings";
import AdminEditCompany from "features/admin/adminEditCompany";
import AdminCompanyDetail from "features/admin/adminCompanyDetail";
import SupervisorEmployees from "features/supervisor/supervisorEmployees";
import SupervisorCompareInfractions from "features/supervisor/supervisorCompareInfractions";
import AdminDashboard from "features/admin/adminDashboard";
import AdminManageCompanies from "features/admin/adminManageCompanies";
import Signup from "features/signUp/signup";
import Unauthorized from "features/unauthorized/unauthorized";
import GuestPageLayout from "layout/guestPageLayout";
import LoggedInPageLayout from "layout/loggedInPageLayout";
import OneTimePass from "features/oneTimePass/oneTimePass";
import Notifications from "features/notifications/notification";
import AdminSignup from "features/signUp/adminSignup";
import AdminInvite from "features/admin/adminInvite";
import User from "models/user/user";
import TermsAndConditions from "features/common/TermsAndConditions";
import PrivacyPolicy from "features/common/PrivacyPolicy";
// import Users from "features/users/users";
// import Projects from "features/projects/projects";
// import K from "utilities/constants";

// Template for a route
// {
//   path: '/login',
//   name: "Login",
//   component: Login,
//   authenticated: false,
//   roles: [],
//   children: [],
//   exact: true,
//   layout: LoggedInPageLayout
// },

const defaultCrudChildren = [
  { path: "/details/:id", name: "Details" },
  { path: "/store/:id", name: "Edit" },
];

const routes = [
  // Guest Layout Routes
  {
    path: "*",
    parentName: "guest",
    name: "Not Found",
    component: NotFound,
    layout: GuestPageLayout,
  },
  {
    path: "/unauthorized",
    parentName: "guest",
    name: "Unauthorized",
    component: Unauthorized,
    authenticated: true,
    roles: [],
    layout: GuestPageLayout,
  },
  {
    path: "/admin-signup",
    parentName: "guest",
    name: "AdminSignup",
    component: AdminSignup,
    layout: GuestPageLayout,
  },
  {
    path: "/login",
    parentName: "guest",
    name: "Login",
    component: Login,
    layout: GuestPageLayout,
  },
  {
    path: "/one-time-pass",
    parentName: "guest",
    name: "OneTimePass",
    component: OneTimePass,
    layout: GuestPageLayout,
  },
  {
    path: "/signup",
    parentName: "guest",
    name: "Signup",
    component: Signup,
    layout: GuestPageLayout,
  },
  {
    path: "/forgot-password",
    parentName: "guest",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/reset-password",
    parentName: "guest",
    name: "ResetPassword",
    component: ResetPassword,
    layout: GuestPageLayout,
  },

  // Tenant Routes Only
  {
    path: "/dashboard",
    name: "Dashboard",
    parentName: "Dashboard",
    exact: true,
    component: Dashboard,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "dashboard/dashboard-graph",
    parentName: "Dashboard",
    name: "DashboardGraph",
    component: DashboardGraph,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "manage-employees/supervisor-employees/:employeeId/supervisor-compare-infractions",
    parentName: "Manage Employees",
    name: "SupervisorCompareInfractions",
    component: SupervisorCompareInfractions,
    layout: LoggedInPageLayout,
  },
  {
    path: "manage-employees/supervisor-employees/:employeeId/employee-history",
    parentName: "Manage Employees",
    name: "EmployeeHistory ",
    component: EmployeeHistory,
    layout: LoggedInPageLayout,
  },
  {
    path: "manage-employees/supervisor-employees/:employeeId",
    parentName: "Manage Employees",
    name: " SupervisorEmployees",
    component: SupervisorEmployees,
    layout: LoggedInPageLayout,
  },
  {
    path: "/manage-employees",
    parentName: "Manage Employees",
    name: "ManageEmployees",
    exact: true,
    component: ManageEmployees,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/manage-infractions",
    parentName: "Manage Infractions",
    name: "ManageInfractions",
    exact: true,
    component: ManageInfractions,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings/time-frame",
    parentName: "Settings",
    name: "TimeFrame",
    exact: true,
    component: TimeFrame,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings/infractions-list",
    parentName: "Settings",
    name: "InfractionsList",
    exact: true,
    component: InfractionsList,
    authenticated: true,
    layout: LoggedInPageLayout,
  },

  {
    path: "/settings/company-policy",
    parentName: "Settings",
    name: "CompanyPolicy",
    exact: true,
    component: CompanyPolicy,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings/company-settings",
    parentName: "Settings",
    name: "CompanySettings",
    exact: true,
    component: CompanySettings,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/manage-payments",
    parentName: "Manage Payments",
    name: "ManagePayments",
    exact: true,
    component: PaymentSettings,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings/company-feedback",
    parentName: "Settings",
    name: "CompanyFeedback",
    exact: true,
    component: CompanyFeedback,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "settings/infractions-list/create-infraction",
    parentName: "Settings",
    name: "CreateInfraction",
    component: CreateInfraction,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "/settings/infractions-list/view-infraction/:typeId/edit-infraction",
    parentName: "Settings",
    name: "CreateInfraction",
    component: CreateInfraction,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "settings/infractions-list/edit-an-infraction",
    parentName: "Settings",
    name: "EditAnInfraction",
    component: EditAnInfraction,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  {
    path: "settings/infractions-list/view-infraction/:typeId",
    parentName: "Settings",
    name: "EditInfraction",
    component: ViewInfractionType,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  // Common
  {
    path: "/admin-settings",
    parentName: User.isSuperAdmin() ? "Admin Dashboard" : "Settings",
    name: "AdminSettings",
    component: AdminSettings,
    layout: LoggedInPageLayout,
  },
  {
    path: "/manage-invite",
    parentName: User.isSuperAdmin() ? "Admin Manage Invites" : "Manage Invites",
    name: "ManageInvite",
    exact: true,
    component: ManageInvite,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  // Only for super-admin
  {
    path: "/admin-dashboard",
    parentName: "Admin Dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    layout: LoggedInPageLayout,
    exact: true,
    authenticated: true,
  },
  {
    path: "/notifications",
    parentName: "Admin Manage Invites",
    name: "Notification",
    exact: true,
    component: Notifications,
    authenticated: true,
    layout: LoggedInPageLayout,
  },

  {
    path: "/admin-manage-companies",
    parentName: "Admin Manage Invites",
    name: "AdminManageCompanies",
    component: AdminManageCompanies,
    layout: LoggedInPageLayout,
  },
  {
    path: "/admin-manage-companies/admin-company-detail/:id",
    parentName: "Admin Manage Companies",
    name: "AdminCompanyDetail",
    component: AdminCompanyDetail,
    layout: LoggedInPageLayout,
  },
  {
    path: "/admin-edit-company/:id",
    parentName: "Admin Manage Companies",
    name: "AdminEditCompany",
    component: AdminEditCompany,
    layout: LoggedInPageLayout,
  },
  // Template Components , not in use but some UI dependencies
  {
    path: "/admin-invite",
    parentName: "Admin Manage Invites",
    name: "AdminInvite",
    component: AdminInvite,
    layout: LoggedInPageLayout,
  },
  {
    path: "/terms-and-conditions",
    parentName: "guest",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    layout: GuestPageLayout,
  },
  {
    path: "/privacy-policy",
    parentName: "guest",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    layout: GuestPageLayout,
  },
  // {
  //   path: "/users",
  //   name: "Users",
  //   component: Users,
  //   authenticated: true,
  //   roles: [],
  //   children: defaultCrudChildren,
  //   layout: LoggedInPageLayout,
  // },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   component: Projects,
  //   authenticated: true,
  //   roles: [K.Roles.Admin],
  //   exact: true,
  //   children: defaultCrudChildren,
  //   layout: LoggedInPageLayout,
  // },
];

export default routes;
