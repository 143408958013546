import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import companyServices from "network/services/admin/companyServices";
import { handleError } from "utilities/generalUtility";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    allCompanies: [],
    totalCompaniesCount: null,
    companyDetail: {},
  },
  reducers: {
    saveAllCompanies: (state, action) => {
      state.allCompanies = [...action.payload.companies];
      state.totalCompaniesCount = action.payload.pagination;
    },
    saveCompanyDetail: (state, action) => {
      state.companyDetail = { ...action.payload };
    },
  },
});

export const fetchAllCompanies = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        companyServices.getAllCompanysList(payload)
      );
      const result = response?.data;
      dispatch(saveAllCompanies(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchCompanyDetailById = (id) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        companyServices.getCompanyDetailById(id)
      );
      const result = response?.data;
      dispatch(saveCompanyDetail(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const { saveAllCompanies, saveCompanyDetail } = companySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const allCompanies = (state) => state?.company?.allCompanies;
export const totalCompaniesCount = (state) => state?.company?.totalCompaniesCount;
export const companyDetail = (state) => state?.company?.companyDetail;

export default companySlice.reducer;
