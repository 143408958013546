import React, { useState, useEffect, useMemo } from "react";

// Style Imports
import "features/dashboard/dashboardGraph.scss";
import moment from "moment";

// Redux actions and selectors Imports
import {
  compareInfraction,
  getCompareInfraction,
} from "redux/infraction/infractionSlice";
import "./supervisorEmployees.scss";
import { useSelector, useDispatch } from "react-redux";
import { timeframe } from "redux/settings/settingsSlice";

// Utility functions Imports
import {
  epochToDateSpecialFormat,
  startAndEndDates,
} from "utilities/dateUtility";
import { TimeframeOptions } from "utilities/generalConstants";
import { changeMonthFormate, intervalDates } from "utilities/generalUtility";

// Ant Design (antd) Imports
import { Column } from "@ant-design/plots";
import { Card, Button, Typography, Row, Col, DatePicker } from "antd";
const { Title } = Typography;

export default function CompareInfractions({
  setCompareInfraction,
  employeeId,
}) {
  // Redux state
  const dispatch = useDispatch();
  const timeFrameData = useSelector(timeframe);
  const infractionTypesList = timeFrameData?.infractionTypes;
  const compareEmployeeInfractions = useSelector(compareInfraction);

  // State
  const [selectedType, setSelectedType] = useState({});
  const [firstInterval, setFirstInterval] = useState({});
  const [secondInterval, setSecondInterval] = useState({});
  const [firstIntervalDisabled, setFirstIntervalDisabled] = useState("");
  const [secondIntervalDisabled, setSecondIntervalDisabled] = useState("");
  const [firstIntervalDisplay, setFirstIntervalDisplay] = useState({});
  const [secondIntervalDisplay, setSecondIntervalDisplay] = useState({});
  const [defaultInterval, setDefaultInterval] = useState({});


  // Variables and Functions
  const datePicker =
    timeFrameData?.timeframe === TimeframeOptions.MONTHLY
      ? "month"
      : timeFrameData?.timeframe === TimeframeOptions.YEARLY
      ? "year"
      : "quarter";

  const config = {
    xField: "date",
    yField: "count",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    color: () => {
      return compareEmployeeInfractions?.infractionTypeColor;
    },
    tooltip: {
      showMarkers: false,
      fields: ["count"],
    },
    meta: {
      date: {
        alias: "Period",
      },
      count: {
        alias: "Count",
      },
    },
    autoFit: "true",
    minColumnWidth: 8,
    maxColumnWidth: 46,
  };

  const memoizedFirstGraph = useMemo(
    () => (
      <div className="dg-wrapper">
        {compareEmployeeInfractions?.data && (
          <Column
            {...{
              data:
                compareEmployeeInfractions?.timeframe ===
                TimeframeOptions.MONTHLY
                  ? changeMonthFormate(compareEmployeeInfractions?.data[0])
                  : compareEmployeeInfractions?.data[0],
              ...config,
            }}
          />
        )}
      </div>
    ),
    [compareEmployeeInfractions]
  );
  const memoizedSecondGraph = useMemo(
    () => (
      <div className="dg-wrapper">
        {compareEmployeeInfractions?.data && (
          <Column
            {...{
              data:
                compareEmployeeInfractions?.timeframe ===
                TimeframeOptions.MONTHLY
                  ? changeMonthFormate(compareEmployeeInfractions?.data[1])
                  : compareEmployeeInfractions?.data[1],
              ...config,
            }}
          />
        )}
      </div>
    ),
    [compareEmployeeInfractions]
  );

  useEffect(() => {
    if (infractionTypesList?.length > 0) {
      const intervals = intervalDates(datePicker);
       setDefaultInterval(intervals);
      const firstInterval = startAndEndDates(
        intervals?.firstInterval,
        datePicker
      );
      const secondInterval = startAndEndDates(
        intervals?.secondInterval,
        datePicker
      );
      dispatch(
        getCompareInfraction({
          firstIntervalStartDate: firstInterval?.startDate,
          firstIntervalEndDate: firstInterval?.endDate,
          secondIntervalStartDate: secondInterval?.startDate,
          secondIntervalEndDate: secondInterval?.endDate,
          employeeId,
          infractionTypeId: infractionTypesList?.[0]?.id,
        })
      );

      setFirstInterval(firstInterval);
      setFirstIntervalDisplay(firstInterval);
      setSecondInterval(secondInterval);
      setSecondIntervalDisplay(secondInterval);
      setSelectedType(infractionTypesList[0]);
    }
  }, [infractionTypesList]);

  return (
    <>
      <Card
        title={
          <button
            className="ant-btn ant-btn-default ant-btn-background-ghost"
            onClick={() => setCompareInfraction(false)}
          >
            Infraction Details
          </button>
        }
        className="app-card dashboard-graph mt-4"
        extra={
          // Date Pickers
          <>
            <DatePicker
              defaultValue={moment(defaultInterval)}
              onChange={(date) => {
                setFirstInterval(startAndEndDates(date, datePicker));
                setSecondIntervalDisabled(date);
            
              }}
              picker={datePicker}
              disabledDate={(current) =>
                current.isAfter(moment().subtract(0, "day")) ||
                (firstIntervalDisabled &&
                  firstIntervalDisabled?.isSame(current, datePicker))
              }
              clearIcon={null}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
            />
            <p> VS </p>
            <DatePicker
              defaultValue={
                datePicker === "month"
                  ? moment(defaultInterval?.secondInterval).subtract(1, "month")
                  : datePicker === "year"
                  ? moment(defaultInterval?.secondInterval).subtract(1, "year")
                  : datePicker === "quarter" &&
                    moment(defaultInterval?.secondInterval).subtract(
                      3,
                      "months"
                    )
              }
              onChange={(date) => {
                setSecondInterval(startAndEndDates(date, datePicker));
                setFirstIntervalDisabled(date);
                
              }}
              picker={datePicker}
              disabledDate={(current) =>
                current.isAfter(moment().subtract(0, "day")) ||
                (secondIntervalDisabled &&
                  secondIntervalDisabled?.isSame(current, datePicker))
              }
              clearIcon={null}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
            />
            <Button
              type="primary"
              onClick={async () => {
                await dispatch(
                  getCompareInfraction({
                    firstIntervalStartDate: firstInterval?.startDate,
                    firstIntervalEndDate: firstInterval?.endDate,
                    secondIntervalStartDate: secondInterval?.startDate,
                    secondIntervalEndDate: secondInterval?.endDate,
                    employeeId,
                    infractionTypeId: selectedType?.id,
                  })
                );
                setFirstIntervalDisplay(firstInterval);
                setSecondIntervalDisplay(secondInterval);
              }}
              disabled={!(infractionTypesList?.length > 0)}
            >
              Compare
            </Button>
          </>
        }
      >
        {/*Types buttons */}
        <div className="btn-wrap">
          {infractionTypesList?.length > 0 &&
            infractionTypesList?.map((type) => (
              <Button
                key={type.id}
                type={selectedType === type ? "primary" : "default"}
                onClick={async () => {
                  await dispatch(
                    getCompareInfraction({
                      firstIntervalStartDate: firstInterval?.startDate,
                      firstIntervalEndDate: firstInterval?.endDate,
                      secondIntervalStartDate: secondInterval?.startDate,
                      secondIntervalEndDate: secondInterval?.endDate,
                      employeeId,
                      infractionTypeId: type?.id,
                    })
                  );
                  setSelectedType(type);
                }}
              >
                {type?.name}
              </Button>
            ))}
        </div>

        {/* tables */}
        <Row gutter={[20, 20]}>
          {/* First table */}
          <Col xs={24} xl={12}>
            <Title level={2}>{`${epochToDateSpecialFormat(
              firstIntervalDisplay?.startDate
            )} - ${epochToDateSpecialFormat(
              firstIntervalDisplay?.endDate
            )}`}</Title>

            <div className="dg-user-info">
              <Title>{selectedType?.name ?? "Infraction type"}</Title>
              <p>
                {compareEmployeeInfractions?.firstIntervalTotalCount ?? "total"}
              </p>
            </div>
            {memoizedFirstGraph}
          </Col>

          {/* Second table */}
          <Col xs={24} xl={12}>
            <Title level={2}>
              {`${epochToDateSpecialFormat(
                secondIntervalDisplay?.startDate
              )} - ${epochToDateSpecialFormat(secondIntervalDisplay?.endDate)}`}
            </Title>
            <div className="dg-user-info">
              <Title>{selectedType?.name ?? "Infraction type"}</Title>
              <p>
                {compareEmployeeInfractions?.secondIntervalTotalCount ??
                  "total"}
              </p>
            </div>
            {memoizedSecondGraph}
          </Col>
        </Row>
      </Card>
    </>
  );
}
