// import react & redux
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeDetail,
  fetchAllEmployeesList,
  getEmployeeDetailById,
} from "redux/employee/employeeSlice";
import { getInfractions } from "redux/infraction/infractionSlice";

// import antd
import { Button, Modal, Tooltip } from "antd";

// import components
import InfractionsListing from "./infractionsListing";
import EmployeeInfractionSummary from "features/supervisor/employeeInfractionSummary";
import generalConstants from "utilities/generalConstants";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";
import { getTimeframe } from "redux/settings/settingsSlice";

function CountInfractionListing({
  modalCountInfractionOpen,
  setCountInfractionOpen,
  employeeId1,
  infractionId,
  setModalAccomodateOpen,
  setModalEsclateOpen,
  employeeMode,
}) {
  // states, variables & hooks
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const data = useSelector(employeeDetail);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let [payloadFilters, setPayloadFilters] = useState(
    employeeId
      ? {
          ...generalConstants.defaultFilters,
          searchArray: [
            {
              key: "employeeId",
              value: employeeId,
              type: "integer",
            },
          ],
        }
      : generalConstants.defaultFilters
  );
  const InfractionStatusChange = async () => {
    setCountInfractionOpen(false);
    setModalAccomodateOpen(true);
    let markMode = false;
    dispatch(getInfractions(payloadFilters, markMode));
  };

  // useEffects
  useEffect(() => {
    dispatch(getEmployeeDetailById(employeeId1));
    dispatch(getTimeframe());
  }, []);

  return (
    <div>
      <Modal
        title={
          <>
            Count of All Infractions
            <small className="sub-title">List of All Infractions Count:</small>
          </>
        }
        centered
        width={800}
        okText="Esclate"
        cancelText="Back"
        open={modalCountInfractionOpen}
        onOk={() => setCountInfractionOpen(false)}
        onCancel={() => {
          setCountInfractionOpen(false);
          let markMode = false;
          dispatch(saveSearchFilterIndicator(false));
          dispatch(saveSortFilterIndicator(false));
          if (employeeMode) {
            dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
          } else {
            dispatch(getInfractions(payloadFilters, markMode));
          }
        }}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal add-employee"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
        footer={
          <div className="count-modal-footer">
            <Button
              type="primary"
              onClick={() => {
                setCountInfractionOpen(false);
                let markMode = false;
                if (employeeMode) {
                  dispatch(
                    fetchAllEmployeesList(generalConstants.defaultFilters)
                  );
                } else {
                  dispatch(getInfractions(payloadFilters, markMode));
                }
                dispatch(saveSearchFilterIndicator(false));
                dispatch(saveSortFilterIndicator(false));
              }}
            >
              {data?.status?.id === 3 || data?.status?.id === 5
                ? "Cancel"
                : "Pending Escalation"}
            </Button>
            <Tooltip
              title={
                data?.status?.id === 3 || data?.status?.id === 5
                  ? "Can't escalate because employee status is teminated or resigned"
                  : ""
              }
            >
              <Button
                disabled={
                  data?.status?.id === 3 || data?.status?.id === 5
                    ? true
                    : false
                }
                type="primary"
                onClick={() => {
                  setCountInfractionOpen(false);
                  setModalEsclateOpen(true);
                }}
              >
                Escalate
              </Button>
            </Tooltip>
            <Tooltip
              title={
                data?.status?.id === 3 || data?.status?.id === 5
                  ? "Can't accomodate because employee status is teminated or resigned"
                  : ""
              }
            >
              <Button
                type="primary"
                onClick={InfractionStatusChange}
                disabled={
                  data?.status?.id === 3 || data?.status?.id === 5
                    ? true
                    : false
                }
              >
                Accommodate
              </Button>
            </Tooltip>
          </div>
        }
      >
        <p className="table-title">
          Time Duration: {startDate} - {endDate}
        </p>
        <EmployeeInfractionSummary
          employeeId={employeeId1}
          joiningDate={data?.joiningDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          countInfractionMode={true}
        />
        {employeeId1 && (
          <InfractionsListing markMode={true} employeeId={employeeId1} />
        )}
      </Modal>
    </div>
  );
}

export default CountInfractionListing;
