import NetworkCall from "network/networkCall";
import noticeServices from "network/services/noticeServices";

export default class Notice {
  static async fetchAllVariables() {
    const res = await NetworkCall.fetch(noticeServices.getVariables());
    return res;
  }

  static async addInfractionType(body) {
    const res = await NetworkCall.fetch(noticeServices.createType(body));
    return res;
  }

  static async getOneInfractionNotice(id) {
    const res = await NetworkCall.fetch(
      noticeServices.getInfractionNotices(id)
    );
    return res;
  }

  static async editInfractionNotice(body) {
    const res = await NetworkCall.fetch(
      noticeServices.updateInfractionNotices(body)
    );
    return res;
  }

  static async generateANotice(body) {
    const res = await NetworkCall.fetch(noticeServices.generateNotice(body));
    return res;
  }

  static async markEscalation(body) {
    const res = await NetworkCall.fetch(noticeServices.markAnEscalation(body));
    return res;
  }

  static async toggleArchiveInfractionType(body) {
    return await NetworkCall.fetch(
      noticeServices.toggleArchiveInfractionType(body)
    );
  }
}
