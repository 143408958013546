import { Column } from "@ant-design/plots";
import { Button, Card, Col } from "antd";

export default function TrialGraph({ config }) {
  return (
    <Col span={24}>
      <Card title="Free Trials vs Paid" className="app-card graph-card">
        <Column {...config} />
      </Card>
    </Col>
  );
}
