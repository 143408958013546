import K from "utilities/constants";
import Request from "../request";

export default class settingServices {
  static changePassword(values) {
    const body = values;
    return new Request(
      K.Network.URL.Auth.ChangePassword,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getTimeframe() {
    return new Request(
      K.Network.URL.Settings.ViewEditTimeframe,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static editTimeframe(values) {
    const body = values;
    return new Request(
      K.Network.URL.Settings.ViewEditTimeframe,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getBasicSetting() {
    return new Request(
      K.Network.URL.Settings.BasicCompanySetting,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static getCompanyPolicy() {
    return new Request(
      K.Network.URL.Settings.ViewCompanyPolicy,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static addCompanyPolicy(body) {
    return new Request(
      K.Network.URL.Settings.UploadCompanyPolicy,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static getPersonalSettings() {
    return new Request(
      K.Network.URL.Settings.ViewPersonalSettings,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static editBasicSetting(body) {
    return new Request(
      K.Network.URL.Settings.EditCompanySetting,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updatePersonalSettings(body) {
    return new Request(
      K.Network.URL.Settings.EditPersonalSettings,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static editCompanyLogo(body) {
    return new Request(
      K.Network.URL.Settings.AddCompanyLogo,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static updateColorBranding(body) {
    return new Request(
      K.Network.URL.Settings.EditColorBranding,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }

  static updateProfilePicture(body) {
    return new Request(
      K.Network.URL.Settings.EditProfileImage,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.File,
      {},
      false
    );
  }

  static submitFeedback(body) {
    return new Request(
      K.Network.URL.Settings.SendFeedback,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
