// import react & redux
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

//import antd
import { Form, Typography } from "antd";

const { Title } = Typography;

export default function OneTimePass({ form, timeReset, setResendFlag }) {
  // states, variables & hooks
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  // useEffects
  useEffect(() => {
    form.setFieldsValue({ otp: null });
    setMinutes(5);
    setSeconds(0);
  }, [timeReset]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          form.setFieldsValue({ otp: null });
          setResendFlag(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  return (
    <React.Fragment>
      <Form form={form} name="basic" layout="vertical">
        <div className="one-time-wrap">
          <Title level={5} className="one-time-title">
            We have sent you{" "}
            <span className="passText">One Time Password </span>to your email
          </Title>
          <span className="pass-time">
            {minutes < 10 ? "0" + minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
          </span>
        </div>
        <div className="verification-code">
          <Form.Item name={"otp"}>
            <OtpInput
              numInputs={6}
              wrapper
              otpType="alphanumeric"
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={"OtpInput"}
            />
          </Form.Item>
        </div>
      </Form>
    </React.Fragment>
  );
}
