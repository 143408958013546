// import network call
import NetworkCall from "network/networkCall";

// import services
import employeeServices from "network/services/employeeServices";

export default class Employee {
  static async AddEmployee(values) {
    return await NetworkCall.fetch(employeeServices.addSingleEmployee(values));
  }
  static async EditEmployee(id, values) {
    return await NetworkCall.fetch(
      employeeServices.editSingleEmployee(id, values)
    );
  }
  static async downloadEmployeeHistory(values) {
    return await NetworkCall.fetch(
      employeeServices.downloadEmployeeHistory(values)
    );
  }
  static async downloadSampleCsv() {
    return await NetworkCall.fetch(employeeServices.downloadSampleCsv());
  }
  static async uploadBulkEmployees(body) {
    return await NetworkCall.fetch(employeeServices.uploadBulkEmployees(body));
  }
}
