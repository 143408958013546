import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import employeeServices from "network/services/employeeServices";
import { handleError } from "utilities/generalUtility";

export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    allEmployeeStatuses: [],
    allEmployees: [],
    totalEmployeesCount: null,
    employeeDetail: {},
    allEmployeesName: [],
  },
  reducers: {
    saveAllEmployeeStatuses: (state, action) => {
      state.allEmployeeStatuses = [...action.payload];
    },
    saveAllEmployees: (state, action) => {
      state.allEmployees = [...action.payload.employees];
      state.totalEmployeesCount = action.payload.pagination;
    },
    saveEmployeeDetail: (state, action) => {
      state.employeeDetail = { ...action.payload };
    },
    saveAllEmployeeName: (state, action) => {
      state.allEmployeesName = [...action.payload];
    },
  },
});
export const fetchEmployeeStatuses = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.getEmployeeStatuses(),
        false
      );
      const newData = response?.data;
      dispatch(saveAllEmployeeStatuses(newData));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchAllEmployeesList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.getAllEmployeesList(payload)
      );
      const result = response?.data;
      dispatch(saveAllEmployees(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchallEmployeesNameList = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.getEmployeeNames(),
        false
      );
      const result = response?.data;
      dispatch(saveAllEmployeeName(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const getEmployeeDetailById = (id) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.getEmployeeDetailById(id)
      );
      const result = response?.data;
      dispatch(saveEmployeeDetail(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const changeEmployeeResignStatus = (employeeId, status) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.employeeResignStatus(employeeId, status)
      );
      const result = response.data;
      dispatch(saveEmployeeDetail(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const changeCompanyPolicyStatus = (employeeId, status) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        employeeServices.markCompanyPolicyStatus(employeeId, status)
      );
      const result = response.data;
      dispatch(saveEmployeeDetail(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const {
  saveAllEmployeeStatuses,
  saveAllEmployees,
  saveEmployeeDetail,
  saveAllEmployeeName,
} = employeeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const allEmployeeStatuses = (state) =>
  state.employee.allEmployeeStatuses;
export const allEmployees = (state) => state?.employee;
export const employeeDetail = (state) => state?.employee?.employeeDetail;
export const allEmployeeNameList = (state) => state?.employee?.allEmployeesName;
export default employeeSlice.reducer;
