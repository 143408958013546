import React, { useEffect, useState } from "react";
import InputColor from "react-input-color";
import {
  Card,
  Avatar,
  Typography,
  Button,
  Tag,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Upload,
  Divider,
  Select,
  Tooltip,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  redirectIfInvalidTenant,
  redirectToUrl,
  setAppThemeColor,
  setCookie,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import NetworkCall from "network/networkCall";
import settingServices from "network/services/settingServices";
import { handleError } from "utilities/generalUtility";
import { epochToDateSpecialFormat } from "utilities/dateUtility";
import {
  allIndustries,
  changeUserGlobalDetail,
  fetchIndustries,
  userGlobalDetail,
} from "redux/user/userSlice";
import Settings from "models/setting/setting";
import {
  onlyAlphabetsAdvanced,
  phoneRegex,
  alphanumericRegex,
  leadingSpacesRegex,
  onlyAlphabetsAndSpecialChars,
} from "utilities/regexUtility";
import { DebounceInput } from "react-debounce-input";
import authServices from "network/services/authServices";

const { Title, Paragraph } = Typography;

export default function CompanySettings() {
  const dispatch = useDispatch();
  const industriesList = useSelector(allIndustries);
  const [form] = Form.useForm();
  const [brandingForm] = Form.useForm();
  const [settingData, setSettingData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isDomainPrefixAvailable, setIsDomainPrefixAvailable] = useState(true);
  const [companyDomainPrefix, setCompanyDomainPrefix] = useState("");
  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const userGlobalData = useSelector(userGlobalDetail);

  const getBasicSetting = async () => {
    const res = await Settings.basicSetting();
    res.industryId = res.industry.id;
    form.setFieldsValue(res);
    setSettingData(res);
    setImageUrl(res?.logo);
    setCompanyDomainPrefix(res?.domainPrefix);
  };

  const setDefaultColor = async () => {
    brandingForm.setFieldsValue({ themeColor: "#1A56A5" });
    colorBrandingCall("#1A56A5");
  };
  const handleColorBranding = () => {
    let color = brandingForm.getFieldValue("themeColor");
    colorBrandingCall(color);
  };

  const colorBrandingCall = async (color) => {
    try {
      const response = await Settings.updateColorBranding({
        themeColor: color,
      });
      message.success(response.message);
      setAppThemeColor(color);
      dispatch(
        changeUserGlobalDetail(userGlobalData, {
          tenant: { ...userGlobalData?.data?.tenant, themeColor: color },
        })
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    dispatch(fetchIndustries());
    getBasicSetting();
  }, []);

  const handleLogo = async (img, url) => {
    try {
      const form_data = new FormData();
      form_data.append("logo", img);
      const res = await Settings.companyLogo(form_data);
      message.success(res?.message);
      setImageUrl(url);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSetting = async (values) => {
    const payload = {
      address: values.address,
      name: values.name,
      domainPrefix: companyDomainPrefix,
      contactPersonName: values.primaryContact.name,
      industryId: values.industryId,
      primaryContactPersonName: values.primaryContact.name,
      designation: values.primaryContact.designation,
      email: values.primaryContact.email,
      phone: values.primaryContact.phone,
    };

    const { name, address, domainPrefix, industryId } = payload;
    const cookieTenantData = {
      name,
      address,
      domainPrefix: companyDomainPrefix,
      industryId,
    };

    let updatedUserGlobalData = {
      ...userGlobalData,
      data: {
        ...userGlobalData?.data,
        tenant: { ...userGlobalData?.data?.tenant, ...cookieTenantData },
      },
    };

    try {
      if (isDomainPrefixAvailable) {
        const response = await NetworkCall.fetch(
          settingServices.editBasicSetting(payload)
        );
        message.success(response.message);
        setCookie(updatedUserGlobalData);

        const hostArray = window.location.hostname.split(".");
        const urlDomainPrefix =
          hostArray.length > 0 ? hostArray[0]?.toLocaleLowerCase() : "";

        if (urlDomainPrefix !== companyDomainPrefix) {
          redirectIfInvalidTenant();
        }
        getBasicSetting();
      } else {
        message.error("Please enter valid domain prefix");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const beforeUpload = (file) => {
    return false;
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    accept: "image/png, image/jpeg, image/jpg, image/svg, image/gif",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      const imageSizeLimit = 2 * 1024 * 1024;
      const isLt2M = info.file.size < imageSizeLimit;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return Upload.LIST_IGNORE;
      } else {
        getBase64(info.file).then((value) => {
          handleLogo(info?.file, value);
        });
      }
    },
    beforeUpload: (file) => beforeUpload(file),
  };
  const suggestDomainPrefix = async (event) => {
    try {
      const response = await NetworkCall.fetch(
        authServices.getSuggestedDomainPrefix({ name: event.target.value }),
        false
      );
      form.setFieldsValue({
        domainPrefix: response?.data?.domainPrefix,
      });
      setCompanyDomainPrefix(response?.data?.domainPrefix);
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };
  const verifyDomainPrefix = async (event) => {
    setCompanyDomainPrefix(event.target.value);
    try {
      const response = await NetworkCall.fetch(
        authServices.verifyDomainPrefix({ domainPrefix: event.target.value }),
        false
      );
      let flag = response?.data?.isDomainPrefixAvailable;
      setIsDomainPrefixAvailable(flag);
      if (flag) {
        let error = {
          error: false,
          data: null,
          message: {
            domainPrefix: [
              <div style={{ color: "#3A833A" }}>This Domain is Available</div>,
            ],
          },
        };

        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      } else {
        let error = {
          error: true,
          data: null,
          message: {
            domainPrefix: ["This Domain is not Available"],
          },
        };
        setFieldErrorsFromServer(error, form, form.getFieldsValue());
      }
    } catch (error) {
      setFieldErrorsFromServer(error, form, form.getFieldsValue());
    }
  };

  return (
    <>
      <Card className="settings-card app-card" title="Basic Settings">
        <div className="profile-wrap ">
          <Card className="profile-card text-center">
            <Title level={4} className="text-center">
              {settingData?.name}
            </Title>
            <div className="position-relative profile-upload">
              {imageUrl ? (
                <Avatar size={150} src={imageUrl} />
              ) : (
                <Avatar size={150} icon={<UserOutlined />} />
              )}
            </div>
            <Upload {...props}>
              <Button type="primary" size="large">
                Upload New Logo
              </Button>
            </Upload>
            <div className="profile-tag">
              <Tag>
                Maximum upload size is <strong>2MB</strong>
              </Tag>
            </div>
            <Paragraph className="mb-0">
              Member Since:{" "}
              <strong>
                {" "}
                {epochToDateSpecialFormat(settingData?.createdAt)}{" "}
              </strong>
            </Paragraph>
          </Card>
          <Card title="Settings" className="profile-settings app-card">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Basic Info" key="1">
                <Form
                  form={form}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSetting}
                  layout="vertical"
                >
                  <Divider orientation="left">Company Info Details</Divider>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        className="input-label-wrap"
                        name="name"
                        label={
                          <>
                            <div className="label-input">Company Name:</div>
                          </>
                        }
                        rules={[
                          {
                            required: true,
                          },
                          {
                            pattern: new RegExp(alphanumericRegex),
                            message:
                              "company name should not contain special characters",
                          },
                        ]}
                      >
                        <DebounceInput
                          className="ant-input add-input"
                          minLength={2}
                          debounceTimeout={900}
                          onChange={(event) => suggestDomainPrefix(event)}
                          placeholder="Spectrum Delivery"
                          suffix={<i className="icon-company"></i>}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        className="input-label-wrap"
                        name="domainPrefix"
                        label={
                          <>
                            <div className="label-input">Domain Prefix:</div>
                          </>
                        }
                        rules={[
                          {
                            required: true,
                            message: "domain prefix should not be empty",
                          },
                        ]}
                      >
                        <Tooltip
                          placement="right"
                          title="Your Domain Prefix is automatically generated. You can customize it if you like."
                        >
                          <DebounceInput
                            className="ant-input add-input"
                            minLength={2}
                            debounceTimeout={900}
                            onChange={(event) => verifyDomainPrefix(event)}
                            placeholder="www"
                            size="medium"
                            value={companyDomainPrefix}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        label="Industry:"
                        name="industryId"
                        className="email-input"
                      >
                        <Select
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          size="medium"
                          placeholder="Select Industry"
                          options={industriesList.map((element) => {
                            return {
                              label: element.name,
                              value: element.id,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        className="input-label-wrap"
                        name="address"
                        label={
                          <>
                            <div className="label-input">Company Address:</div>
                          </>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input company address",
                          },
                          {
                            pattern: new RegExp(leadingSpacesRegex),
                            message: "Leading spaces is not allowed",
                          },
                        ]}
                      >
                        <Input
                          type="name"
                          placeholder="1522 Cohoe Ct, Kenai, Alaska 99611, USA"
                          size="medium"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left">Company Admin Details</Divider>

                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        className="input-label-wrap"
                        name={["primaryContact", "name"]}
                        label={
                          <>
                            <div className="label-input">Person Name:</div>
                          </>
                        }
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please input Name",
                        //   },

                        //   {
                        //     min: 3,
                        //     message: "Name must be minimum 3 characters.",
                        //   },
                        //   {
                        //     max: 49,
                        //     message: "Name should be less than 50 character",
                        //   },
                        //   {
                        //     pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                        //     message:
                        //       "Field does not accept numbers and leading spaces",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder="Nolan Dias"
                          size="middle"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        className="input-label-wrap"
                        name={["primaryContact", "designation"]}
                        label={
                          <>
                            <div className="label-input">Designation:</div>
                          </>
                        }
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please input primary contact designation",
                        //   },
                        //   {
                        //     pattern: new RegExp(onlyAlphabetsAdvanced),
                        //     message:
                        //       "Field does not accept numbers,special chracters and leading spaces",
                        //   },
                        // ]}
                      >
                        <Input
                          type="name"
                          placeholder="Manager"
                          size="middle"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        name={["primaryContact", "email"]}
                        className="input-label-wrap"
                        label={
                          <>
                            <div className="label-input">Email:</div>
                          </>
                        }
                      >
                        <Input
                          placeholder="thall@gmail.com"
                          size="middle"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={12}>
                      <Form.Item
                        name={["primaryContact", "phone"]}
                        className="input-label-wrap"
                        label={
                          <>
                            <div className="label-input">Phone Number:</div>
                          </>
                        }
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please input phone number",
                        //   },
                        //   {
                        //     pattern: new RegExp(phoneRegex),
                        //     message:
                        //       "Phone number must be valid and without dashes",
                        //   },
                        // ]}
                      >
                        <Input
                          type="phone"
                          placeholder="202-555-2849"
                          size="middle"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div className="form-btns">
                    <Button htmlType="submit" type="primary">
                      Save Changes
                    </Button>
                  </div>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Branding" key="2">
                <Form layout="vertical" form={brandingForm}>
                  <Title className="input-title" level={5}>
                    Colors
                  </Title>
                  <Row gutter={16}>
                    <Col xs={24} md={18}>
                      <Form.Item name="themeColor" label={"Primary Color:"}>
                        <Input
                          type="text"
                          className="color-picker"
                          size="large"
                          readOnly={true}
                          suffix={
                            <InputColor
                              initialValue={
                                userGlobalData?.data?.tenant?.themeColor?.toUpperCase() ||
                                "#1A56A5"
                              }
                              style={{ width: "20px" }}
                              onChange={(color) => {
                                brandingForm.setFieldsValue({
                                  themeColor: color?.hex?.toUpperCase(),
                                });
                              }}
                              placement="right"
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="form-btns">
                    <Button ghost onClick={setDefaultColor}>
                      Set Default
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={handleColorBranding}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Card>
    </>
  );
}
