// import react & redux
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAllEmployeesList } from "redux/employee/employeeSlice";

// import antd
import { Divider, Modal, Upload, message } from "antd";

// import utilities
import { downloadCsvFile, handleError } from "utilities/generalUtility";
import { getGmtOffset } from "utilities/dateUtility";

// import models
import Employee from "models/employee/employee";
import Dragger from "antd/lib/upload/Dragger";

// import constants
import generalConstants from "utilities/generalConstants";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";

export default function BulkUploadModal({
  modalUploadCsvOpen,
  setModalUploadCsvOpen,
}) {
  // states, hooks & variables
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = useState();
  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: true,
    maxCount: 1,
    accept: ".csv",
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error("You can only upload CSV file!");
      }
      return false;
    },
    onChange: async (info) => {
      setCsvFile(info?.file);
    },
    onDrop: async (event) => {
      event.preventDefault();
      setCsvFile(event.dataTransfer.files[0]);
    },
    onRemove: () => {
      setCsvFile(null);
    },
  };

  // functions
  const handleDownloadSampleCsv = async () => {
    try {
      const response = await Employee.downloadSampleCsv();
      downloadCsvFile(response, "addEmployees.csv");
    } catch (error) {
      handleError(error);
    }
  };

  const handleUploadBulkEmployees = async () => {
    const gmtOffset = getGmtOffset();
    let form_data = new FormData();
    form_data.append("gmtOffset", gmtOffset);
    form_data.append("csvFile", csvFile);
    if (csvFile) {
      try {
        await Employee.uploadBulkEmployees(form_data);
        setModalUploadCsvOpen(false);
        message.success("Employees Added Successfully");
        dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
      } catch (error) {
        handleError(error);
      }
    } else {
      message.error("Please Add file");
    }
  };

  return (
    <div>
      <Modal
        title="Upload CSV File for Employees"
        destroyOnClose={true}
        centered
        width={800}
        okText="Upload"
        cancelText="Cancel"
        open={modalUploadCsvOpen}
        onOk={handleUploadBulkEmployees}
        onCancel={() => {
          setModalUploadCsvOpen(false);
          uploadProps.onRemove();
        }}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal upload-csv"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <Dragger {...uploadProps} className="uc-content">
          {/* <div className="uc-content"> */}
          <div>
            <span>
              <i className="icon-cloud"></i>
            </span>
            <p>Drop file to upload or browse</p>
          </div>
          {/* </div> */}
        </Dragger>

        <ul className="add-employee-list">
          <li>Only .csv files are acceptable</li>
          <li>Do not add or remove any column</li>
          <li>Do not rename any column</li>
          <li>
            Only these Employee Statuses are valid: Probation, Active, Resigned,
            Suspended, Terminated
          </li>
          <li>
            Note that start date & end date are required in case of Employee
            Status is 'Probation' or 'Suspended'
          </li>
          <li>Make sure start date is always before the end date</li>
          <li>
            Employee Name, Email, Phone, Joining Date, Status are the required
            fields
          </li>
          <li>Make sure provided emails & phone numbers are valid</li>
          <li>Make sure there are no duplicate emails in .csv file</li>
          <li>
            Emails that already exist in database will not get duplicated,
            instead their data will be updated based on the .csv file
          </li>
        </ul>
        <div className="download-csv">
          <a onClick={handleDownloadSampleCsv} className="dc-wrap">
            <span className="documnent-info">
              <i className="icon-document-info"></i>Download Sample CSV File
            </span>
            <span>
              <i className="icon-download"></i>
            </span>
          </a>
        </div>
      </Modal>
    </div>
  );
}
