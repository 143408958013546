import React from 'react';
import { Card, Button, Divider, Form, Row, Col, Input, Table } from "antd";
import "features/settings/infractionsList/infractionsList.scss";
import { EditOutlined } from "@ant-design/icons";


export default function EditAnInfraction() {
    const infractionListCol = [
        {
            title: 'Variable Name',
            dataIndex: 'variable',
            width: 200,
            filterIcon: <i className='icon-table-filter'></i>,
            filters: [],
            render: () => (
                <p className="offender-col-info">
                    [EMP_FIRST_NAME]
                </p>
            ),
        },
        {
            title: 'Example',
            dataIndex: 'example',
            width: 250,
            filterIcon: <i className='icon-table-filter'></i>,
            filters: [],
            render: () => (
                <p className="offender-col-info">
                    Cristofer
                </p>
            ),
        },
        {
            title: '',
            dataIndex: 'copy',
            width: 50,
            render: () => (
                <Button className="btn-col" type="text">
                    <i className="icon-copy"></i>
                </Button>
            ),
        },
    ];
    const infractionListRow = [
        {
            key: '1',
        },
        {
            key: '2',
        },
        {
            key: '3',
        },
        {
            key: '4',
        },
        {
            key: '5',
        },
        {
            key: '6',
        },
        {
            key: '7',
        },
        {
            key: '8',
        },
        {
            key: '9',
        },
        {
            key: '10',
        },
        {
            key: '11',
        },
    ];
    return (
        <>
            <Card title="Edit An Infraction"
                className="app-card create-infraction-card"
                extra={<>
                    <Button type="primary">Save Changes</Button>
                </>}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    className="create-infraction-form infraction-settings"
                >
                    <Row gutter={[20, 6]}>
                        <Col span={24}><Divider orientation="left" orientationMargin="0">
                            Infraction Info
                        </Divider></Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item label={
                                <>
                                    <div className="label-input">
                                        Infraction Name
                                        <Button size="small" type="link" icon={<EditOutlined />} />
                                    </div>
                                </>
                            }
                                name="name">
                                <Input placeholder="Example" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item label={
                                <>
                                    <div className="label-input">
                                        Infraction Name in Template:
                                        <Button size="small" type="link" icon={<EditOutlined />} />
                                    </div>
                                </>
                            } name="name">
                                <Input placeholder="Example" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item label={
                                <>
                                    <div className="label-input">
                                        Created Time Stamp:
                                        <p>You can’t edit ypur email address</p>
                                    </div>
                                </>
                            } name="name">
                                <Input placeholder="By Default" />
                            </Form.Item>
                        </Col>
                        <Col span={24}><Divider orientation="left" orientationMargin="0">
                            Notice Template
                        </Divider></Col>
                        <Col span={24}>
                            <Table className="app-table infraction-list-table create-infraction-table" pagination={false} columns={infractionListCol} dataSource={infractionListRow} scroll={{
                                x: 900,
                            }} />
                        </Col>
                        <Col span={24} className="mt-4">
                            <Form.Item label="End Notice:" name="sample">
                                <div className="infraction-info-wrap">
                                    <p>
                                        [EMPLOYEE NAME],<br></br>

                                        On [DATE] you were absent for your
                                        scheduled shift. In the last [NUMBER]
                                        days, you have missed [NUMBER] days
                                        of work. This is not in accordance with
                                        Company Policy. You acknowledged
                                        and agreed to Company Policy on
                                        [DATE]. Company Policy can be
                                        reviewed [HERE].

                                        As a result of your absence and in
                                        accordance with company policy
                                        [REPERCUSSIONS AUTOMATICALLY
                                        INSETED BASED ON SETTINGS]. You
                                        may reach out to me with any
                                        questions. I will be in contact with you
                                        to schedule a time to speak with you
                                        in person.
                                    </p>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='form-btns'>
                    <Button type='Default' className='ant-btn-background-ghost'>Cancel</Button>
                    <Button type='primary'>Save</Button>
                </div>
            </Card>
        </>
    );
}