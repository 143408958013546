import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Tabs,
  Modal,
  Form,
  Select,
  Typography,
} from "antd";

import "features/dashboard/dashboard.scss";
import { Link } from "react-router-dom";
import InfractionsListing from "features/common/infractionsListing";
import { useEffect } from "react";
import { dateToEpochFormat, endDateToEpochFormat } from "utilities/dateUtility";
import TopOffendersList from "features/common/topOffenders";
import InfractionPerEmployee from "./infractionPerEmployee";

import DashboardAnalytics from "./dashboardAnalytics";
import moment from "moment";
import { useSelector } from "react-redux";
import { totalEmployee } from "redux/dashboard/dasboardSlice";

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function Dashboard() {
  const [modalEsclateOpen, setModalEsclateOpen] = useState(false);
  const [modalAccomodateOpen, setModalAccomodateOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [value, setValue] = useState("");
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [tab, setTab] = useState("1");
  const dateFormat = "MM/DD/YYYY";
  const current = new Date();
  current.setMonth(current.getMonth());

  const startOfMonth = new Date(current.getFullYear(), current.getMonth(), 1);

  const endOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0);
  const total = useSelector(totalEmployee);
  const operations = (
    <Title level={2}>
      {" "}
      Total Employees: <span>{total}</span>
    </Title>
  );

  const getDates = () => {
    const startInterval = dateToEpochFormat(startOfMonth);
    const endInterval = dateToEpochFormat(endOfMonth);
    setstartDate(startInterval);
    setendDate(endInterval);
  };
  const changeInDates = (values) => {
    if (values) {
      setstartDate(dateToEpochFormat(values[0]));
      setendDate(endDateToEpochFormat(values[1]));
    }
  };

  useEffect(() => {
    getDates();
  }, []);

  return (
    <>
      <Card
        title="Company Analytics"
        extra={
          <>
            <RangePicker
              format={dateFormat}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              onChange={changeInDates}
              defaultValue={[moment(startOfMonth), moment(endOfMonth)]}
              allowClear={false}
            />
            <Link className="ant-btn ant-btn-primary" to="dashboard-graph">
              Compare Infractions
            </Link>
          </>
        }
        className="app-card dashboard-card"
      >
        <DashboardAnalytics
          startDate={startDate}
          endDate={endDate}
        ></DashboardAnalytics>
        <Card className="dc-tabs-wrapper">
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => setTab(e)}
            tabBarExtraContent={operations}
          >
            <Tabs.TabPane tab="Infractions per Employee" key="1">
              <InfractionPerEmployee
                startDate={startDate}
                endDate={endDate}
                tab={tab}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Top Offenders" key="2">
              <TopOffendersList
                startDate={startDate}
                endDate={endDate}
                tab={tab}
              ></TopOffendersList>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Pending Escalations" key="3">
              <InfractionsListing
                markMode={false}
                dashboardMode={true}
                startDate={startDate}
                endDate={endDate}
                tab={tab}
              />
            </Tabs.TabPane>
          </Tabs>
          {/* <Button type="primary" size="middle">
            <i className="icon-tab-filter"></i>
          </Button> */}
        </Card>
      </Card>
      <Modal
        title="Escalation"
        centered
        width={800}
        okText="Send Notice"
        cancelText="Back"
        open={modalEsclateOpen}
        onOk={() => setModalEsclateOpen(false)}
        onCancel={() => setModalEsclateOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal escalation-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
        bodyStyle={{ paddingBottom: "0px" }}
      >
        <Form name="basic" layout="vertical" className="escalation-form">
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item label="Employee Status:" name="employee status">
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  suffix={<i className="icon-dropdown"></i>}
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Start and End Date:" name="date">
                <RangePicker
                  format={dateFormat}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Create a Notice:" name="notice">
                <ReactQuill theme="snow" value={value} onChange={setValue} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* <Modal
        title="Accommodate"
        centered
        okText="Yes"
        cancelText="No"
        width={800}
        open={modalAccomodateOpen}
        onOk={() => setModalAccomodateOpen(false)}
        onCancel={() => setModalAccomodateOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal accomodate-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <p>Are you sure you mark this infraction as accommodate?</p>
      </Modal> */}
      <Modal
        title="Delete Infraction"
        centered
        width={800}
        okText="Yes"
        cancelText="No"
        open={modalDeleteOpen}
        onOk={() => setModalDeleteOpen(false)}
        onCancel={() => setModalDeleteOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal accomodate-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <p>Are you sure you want to delete this infraction?</p>
      </Modal>
    </>
  );
}
