import { message } from "antd";
import User from "../models/user/user";
import K from "./constants";
import history from "./history";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import moment from "moment";

export const setCookie = (newCookie) => {
  let encryptedUser = CryptoJS.AES.encrypt(
    JSON.stringify(newCookie),
    K.Cookie.Key.EncryptionKey
  );
  Cookies.set(K.Cookie.Key.User, encryptedUser, {
    path: "/",
    domain: "." + K.Network.URL.Client.BaseTenantHost,
  });
};

export const setAppThemeColor = (brandingColor) => {
  document.documentElement.style.setProperty(
    "--primary-color",
    brandingColor || "#1A56A5"
  );
};

export const handleError = (errorObject) => {
  const errors = errorObject.message;
  if (typeof errors === "object") {
    let resultErrorsArray = [];

    Object.values(errors).forEach((element) => {
      resultErrorsArray = resultErrorsArray.concat(element);
    });

    resultErrorsArray.forEach((error) => {
      message.error(error);
    });
  } else {
    if (errors !== "") {
      message.error(errors);
    }
  }
};

export const downloadCsvFile = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const toCamelCaseToSentence = (string) => {
  return string.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
};

export const snakeCaseToSentence = (string) => {
  return string
    ?.split("_")
    ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    ?.join(" ");
};

export const hasOnlyDigits = (string) => {
  return /^-{0,1}\d+$/.test(string);
};

export const getColor = (value) => {
  //value from 0 to 1
  const hue = ((1 - value) * 120).toString(10);
  return ["hsl(", hue, ",65%,70%)"].join("");
};

export const isNumberRegex = () => {
  return new RegExp("^[0-9]*$");
};

export const isDecimalRegex = () => {
  return new RegExp("^\\d+\\.?\\d*$");
};

export const isRolePresent = (roles, userRoles) => {
  let hasRole = true;
  if (roles && roles.length > 0) {
    let roleFound = false;
    for (const routeRole of roles ?? []) {
      if (userRoles.includes(routeRole)) {
        roleFound = true;
        break;
      }
    }
    hasRole = roleFound;
  }
  return hasRole;
};

export const isPermissionPresent = (permission, userRole) => {
  let hasPermission = true;
  if (permission) {
    let permissionFound = false;
    for (const obj of userRole?.rolePermissionRelations) {
      if (permission === obj?.permission?.name) {
        permissionFound = true;
        break;
      }
    }
    hasPermission = permissionFound;
  }
  return hasPermission;
};

export const redirectToLogin = (error = "") => {
  if (typeof window !== "undefined") {
    let newUrl =
      window.location.protocol +
      "//" +
      K.Network.URL.Client.BaseHost +
      ":" +
      K.Network.URL.Client.BasePort +
      "/login";
    if (error !== "") {
      newUrl += `?err=${error}`;
    }
    window.location = newUrl;
  }
};

export const redirectIfInvalidTenant = () => {
  const cookieDomainPrefix = User.getTenant()?.toLowerCase();
  const hostArray = window.location.hostname.split(".");
  const urlDomainPrefix =
    hostArray.length > 0 ? hostArray[0]?.toLocaleLowerCase() : "";

  const path = window.location.pathname;
  const search = window.location.search;
  if (
    !cookieDomainPrefix &&
    (urlDomainPrefix === "www" ||
      urlDomainPrefix === "localhost" ||
      urlDomainPrefix === K.Network.URL.DomainName)
  )
    return false;
  if (cookieDomainPrefix !== urlDomainPrefix) {
    redirectToUrl(
      User?.isSuperAdmin() ? "app" : cookieDomainPrefix ?? "app",
      path + search
    );
  }
};

export const redirectToUrl = (domainPrefix, path) => {
  window.location =
    window.location.protocol +
    "//" +
    (domainPrefix ? domainPrefix + "." : "") +
    K.Network.URL.Client.BaseTenantHost +
    ":" +
    K.Network.URL.Client.BasePort +
    path;
};

export const setFieldErrorsFromServer = (error, form, values = undefined) => {
  if (error?.error === undefined) return;
  const errors = error.message;
  if (typeof errors === "string" || errors instanceof String) {
    message.error(errors);
    return;
  }
  let fieldErrors = [];
  for (let key in errors) {
    if (errors.hasOwnProperty(key)) {
      fieldErrors.push({
        name: key,
        errors: errors[key],
        value: values && values[key] ? values[key] : undefined,
      });
    }
  }
  form.setFields(fieldErrors);
};

export const snakeToCamel = (str) => {
  return str
    ?.toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );
};

export const camelCaseKeys = (obj) =>
  Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [snakeToCamel(field)]: obj[field],
    }),
    {}
  );

export const deleteQueryParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.delete(key);
  history.push({
    search: queryParams.toString(),
  });
};

export const changeMonthFormate = (data) =>
  data.map((item) => {
    const day = moment(item.date, "DD-MM-YYYY").format("DD");
    return { date: day, count: item.count };
  });

export const setDisplayFormat = (value) => {
  return JSON.stringify(value)
    .replace(/"/g, "")
    .replace(/(<([^>]+)>)/gi, "");
};

export const capitalToPascal = (str) =>
  str?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2?.toLowerCase();
  });

export const intervalDates = (datePicker) => {
  const currentDate = moment();
  let firstInterval, secondInterval;

  if (datePicker === "month") {
    firstInterval = currentDate;
    secondInterval = moment(currentDate).subtract(1, "months");
  } else if (datePicker === "year") {
    firstInterval = currentDate;
    secondInterval = moment(currentDate).subtract(1, "years");
  } else {
    firstInterval = currentDate;
    secondInterval = moment(currentDate).subtract(3, "months");
  }
  return { firstInterval, secondInterval };
};
