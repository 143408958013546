import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

// Style Imports
import "features/dashboard/dashboardGraph.scss";
import "./supervisorEmployees.scss";

// antd Imports
import { Card } from "antd";

// Redux actions and selectors Imports
import {
  allInfractions,
  employeeInfractionCount,
  getEmployeeInfractionsCount,
} from "redux/infraction/infractionSlice";
import { timeframe } from "redux/settings/settingsSlice";

// Utility functions Imports
import {
  dateToEpochFormat,
  endDateToEpochFormat,
  epochToDateFormat,
  numberToMonthName,
} from "utilities/dateUtility";
import { TimeframeOptions } from "utilities/generalConstants";

export default function EmployeeInfractionSummary(props) {
  // Redux state
  const employeeInfractionsCount = useSelector(employeeInfractionCount);
  const timeframeData = useSelector(timeframe);
  const infractionUpdates = useSelector(allInfractions);
  const infractionTypesList = timeframeData?.infractionTypes;

  const dispatch = useDispatch();

  // State
  const [dateValues, setDateValues] = useState({});
  const [preCount, setPreCount] = useState(0);
  const [preDisable, setPreDisable] = useState(false);

  // Determine the number of slides to show in the slider based on the count of infractions
  const sliderCount = (infractins) => {
    if (infractins.length < (props?.countInfractionMode ? 3 : 4)) {
      return infractins.length;
    }
    return props?.countInfractionMode ? 3 : 4;
  };

  // Settings for the infraction types slider
  const infractionTypesSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    nextArrow: <i className="icon-arrow-circle"></i>,
    prevArrow: <i className="icon-arrow-circle"></i>,
    speed: 700,
    slidesToShow:
      employeeInfractionsCount && sliderCount(employeeInfractionsCount),
    slidesToScroll: props?.countInfractionMode ? 3 : 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: employeeInfractionsCount?.length === 1 ? 1 : 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: employeeInfractionsCount?.length === 1 ? 1 : 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Calculate the date range based on the selected timeframe option
  function getDateRange(option, value, validDate) {
    const currentDate = new Date();
    let startDate, endDate;
    let selectedValue = value;
    let startMonth;
    let selectedYear = currentDate.getFullYear();

    if (!value) {
      if (option === TimeframeOptions.MONTHLY) {
        selectedValue = currentDate.getMonth() + 1;
      } else if (option === TimeframeOptions.YEARLY) {
        selectedValue = currentDate.getFullYear();
      } else if (option === TimeframeOptions.QUARTERLY) {
        selectedValue = Math.floor(currentDate.getMonth() / 3) + 1;
      }
    } else {
      if (option === TimeframeOptions.MONTHLY) {
        const currentMonth = currentDate.getMonth() + 1;
        selectedValue = currentMonth - value;
        if (selectedValue < 1) {
          const yearDiff = Math.ceil(Math.abs(selectedValue - 1) / 12);
          selectedYear -= yearDiff;
          selectedValue = 12 - (Math.abs(selectedValue) % 12);
          if (selectedValue === 0) {
            selectedYear -= 1;
            selectedValue = 12;
          }
        }
      } else if (option === TimeframeOptions.YEARLY) {
        selectedValue = currentDate.getFullYear() - value;
      } else if (option === TimeframeOptions.QUARTERLY) {
        const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;
        selectedValue = currentQuarter - value;
        if (selectedValue <= 0) {
          const yearDiff = Math.ceil(Math.abs(selectedValue - 1) / 4);
          selectedYear -= yearDiff;
          selectedValue = 4 - (Math.abs(selectedValue) % 4);
          if (selectedValue === 0) {
            selectedYear -= 1;
            selectedValue = 4;
          }
        }
      }
    }

    if (option === TimeframeOptions.MONTHLY) {
      if (selectedValue >= 1 && selectedValue <= 12) {
        startDate = new Date(selectedYear, selectedValue - 1, 1);
        endDate = new Date(selectedYear, selectedValue, 0);
      }
    } else if (option === TimeframeOptions.YEARLY) {
      if (selectedValue >= 1970 && selectedValue <= currentDate.getFullYear()) {
        startDate = new Date(selectedValue, 0, 1);
        endDate = new Date(selectedValue, 11, 31);
      }
    } else if (option === TimeframeOptions.QUARTERLY) {
      if (selectedValue >= 1 && selectedValue <= 4) {
        startMonth = (selectedValue - 1) * 3;
        startDate = new Date(selectedYear, startMonth, 1);
        endDate = new Date(selectedYear, startMonth + 3, 0);
      }
    }
    startDate = dateToEpochFormat(startDate);
    endDate = endDateToEpochFormat(endDate);

    if (props?.countInfractionMode) {
      props?.setStartDate(epochToDateFormat(startDate));
      props?.setEndDate(epochToDateFormat(endDate));
    }

    if (
      (startDate <= validDate && validDate <= endDate) ||
      validDate > endDate
    ) {
      dispatch(
        getEmployeeInfractionsCount({
          employeeId: props?.employeeId,
          startDate,
          endDate,
        })
      );
      setPreDisable(true);
    } else {
      setPreDisable(false);
      dispatch(
        getEmployeeInfractionsCount({
          employeeId: props?.employeeId,
          startDate,
          endDate,
        })
      );
    }

    return {
      option,
      selectedValue,
      startDate,
      endDate,
      startMonth,
      year: selectedYear,
    };
  }

  useEffect(() => {
    if (infractionTypesList?.length > 0)
      setDateValues(
        timeframeData?.timeframe &&
          props?.joiningDate &&
          getDateRange(timeframeData?.timeframe, preCount, props?.joiningDate)
      );
  }, [timeframeData, preCount, infractionUpdates]);

  return (
    <>
      {!props?.countInfractionMode ? (
        <div className="date-wrap">
          {/* Slider's buttons */}
          <button
            style={{ all: "unset" }}
            onClick={() => setPreCount(preCount + 1)}
            disabled={preDisable}
          >
            <i
              className="icon-left"
              style={{ color: preDisable ? "grey" : undefined }}
            />
          </button>
          <span>
            {dateValues?.option === TimeframeOptions.MONTHLY
              ? `${numberToMonthName(dateValues?.selectedValue)} ${
                  dateValues?.year
                }`
              : dateValues?.option === TimeframeOptions.YEARLY
              ? dateValues?.selectedValue
              : dateValues?.option === TimeframeOptions.QUARTERLY
              ? `${numberToMonthName(
                  dateValues?.startMonth + 1
                )}-${numberToMonthName(dateValues?.startMonth + 3)} ${
                  dateValues?.year
                }`
              : "--"}
          </span>
          <button
            style={{ all: "unset" }}
            onClick={() => setPreCount(preCount - 1)}
            disabled={preCount === 0}
          >
            <i
              className="icon-right"
              style={{ color: preCount === 0 ? "grey" : undefined }}
            />
          </button>
        </div>
      ) : null}
      <Card className="infraction-slider-card">
        <ul>
          <Slider {...infractionTypesSliderSettings}>
            {employeeInfractionsCount.length !== 0 &&
              employeeInfractionsCount?.map((infraction) => (
                <div className="list-wrap" key={infraction?.infractionTypeId}>
                  <li>
                    {infraction?.allowedInfractions !== null && (
                      <i
                        className={
                          infraction?.infractionCount === 0
                            ? "icon-circle green-circle"
                            : infraction?.infractionCount <=
                              infraction?.allowedInfractions
                            ? "icon-circle yellow-circle"
                            : infraction?.infractionCount >=
                              infraction?.allowedInfractions
                            ? "icon-circle red-circle"
                            : ""
                        }
                      />
                    )}
                    {`${infraction?.infractionTypeName}: ${
                      infraction?.infractionCount
                    }${
                      infraction?.allowedInfractions === null
                        ? ""
                        : `/${infraction?.allowedInfractions}`
                    }`}
                  </li>
                </div>
              ))}
          </Slider>
        </ul>
      </Card>
    </>
  );
}
