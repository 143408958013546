import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.less";
import "../../src/styles.scss";

import routes from "routes/routes";
import RouteWithSubRoutes from "routes/routeWithSubRoutes";
import ErrorBoundary from "common/components/errorBoundary/errorBoundary";
import User from "models/user/user";
import { useDispatch } from "react-redux";
import { saveUserGlobalDetail } from "redux/user/userSlice";
import { setAppThemeColor } from "utilities/generalUtility";
import PusherNotification from "features/notifications/pusher";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    // set redux data on refresh
    let cookieData = User?.getUserObjectFromCookies();
    dispatch(saveUserGlobalDetail(cookieData));

    // set App theme color on refresh
    setAppThemeColor(User?.getThemeColor());

    User.getUserIpFromNetWork();
    const intervalCall = setInterval(() => {
      User.getUserIpFromNetWork();
    }, 300000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);
  return (
    <ErrorBoundary>
      <Routes>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <>
                <PusherNotification />
                <RouteWithSubRoutes route={route} />
              </>
            }
          />
        ))}
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
