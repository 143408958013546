import {
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Form,
  Button,
  Input,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import NetworkCall from "network/networkCall";
import infractionServices from "network/services/infractionServices";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allEmployeeNameList,
  fetchallEmployeesNameList,
} from "redux/employee/employeeSlice";
import { getTimeframe, timeframe } from "redux/settings/settingsSlice";
import { dateToEpochFormat, epochToMomentTime } from "utilities/dateUtility";
import { handleError } from "utilities/generalUtility";
import moment from "moment";

export default function MarkInfractionModal({
  open,
  setOpen,
  flag,
  user,
  setCountInfractionOpen,
  setEmployeeId,
  setinfractionId,
  setInfractionTypeId,
}) {
  const dispatch = useDispatch();
  const allEmployees = useSelector(allEmployeeNameList);
  const timeframeData = useSelector(timeframe);
  const [form] = Form.useForm();
  const [joiningDate, setjoiningDate] = useState();
  const dateFormat = "MM/DD/YYYY";
  const handleOk = async (value) => {
    if (flag) {
      value.employeeId = user?.id;
    }
    const date = dateToEpochFormat(value.infractionDate);
    value.infractionDate = date;
    await createInfraction(value);
    form.resetFields();
  };

  const createInfraction = async (values) => {
    setEmployeeId(values?.employeeId);
    setInfractionTypeId(values?.infractionTypeId);
    try {
      const response = await NetworkCall.fetch(
        infractionServices.createInfraction(values)
      );
      message.success(response.message);
      setinfractionId(response?.data?.id);
      setCountInfractionOpen(true);
      setOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const checkJoining = (value, date) => {
    setjoiningDate(date.joiningDate);
  };

  useEffect(() => {
    dispatch(getTimeframe());
    if (flag) {
      form.setFieldsValue({
        employeeId: user?.name,
      });
      setjoiningDate(user?.joiningDate);
    }

    dispatch(fetchallEmployeesNameList());
  }, []);

  return (
    <Modal
      title="Mark Infraction"
      centered
      width={800}
      open={open}
      onCancel={handleCancel}
      destroyOnClose={true}
      closeIcon={<i className="icon-close"></i>}
      className="app-modal add-employee"
      cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      bodyStyle={{ paddingBottom: "0px" }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button form="myForm" key="submit" htmlType="submit" type="primary">
          Create
        </Button>,
      ]}
    >
      <Form
        id="myForm"
        name="basic"
        layout="vertical"
        form={form}
        onFinish={handleOk}
      >
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Employee Name:"
              name="employeeId"
              rules={[
                { required: true, message: "Please Select Employee Name" },
              ]}
            >
              {flag ? (
                <Input placeholder="Employee Name" disabled />
              ) : (
                <Select
                  showSearch
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  onChange={checkJoining}
                  suffix={<i className="icon-select"></i>}
                  placeholder="Employee Name"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                  options={allEmployees.map((item) => ({
                    label:
                      (item?.firstName ? item?.firstName : "") +
                      " " +
                      (item?.lastName ? item?.lastName : ""),
                    value: item?.id,
                    joiningDate: item?.joiningDate,
                  }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Infraction Type:"
              name="infractionTypeId"
              rules={[
                { required: true, message: "Please Select Infraction Type" },
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                suffix={<i className="icon-select"></i>}
                placeholder="Select Infraction Type"
                options={timeframeData?.infractionTypes
                  ?.filter((item) => !item.isArchive)
                  .map((element) => {
                    return {
                      label: element.name,
                      value: element.id,
                    };
                  })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Date:"
              name="infractionDate"
              rules={[{ required: true, message: "Please Select Date" }]}
            >
              <DatePicker
                format={dateFormat}
                disabledDate={(current) =>
                  current.isBefore(moment(epochToMomentTime(joiningDate))) ||
                  current.isAfter(moment().subtract(0, "day"))
                }
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Add Comments:" name="comments">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
