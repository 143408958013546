import { Layout, Grid } from "antd";
import Spinner from "common/components/spinner/spinner";
import Header from "layout/header";
import Sider from "layout/sider";
import Footer from "./footer";
import { useState } from "react";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function LoggedInPageLayout({ children }) {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Header collapsed={collapsed} toggle={toggle} />
      {screens["md"] ? null : collapsed ? null : (
        <div className="sider-overlay" onClick={setCollapsed}></div>
      )}
      <Layout className="loggedin-layout">
        <Sider collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          className={
            collapsed ? "content-wrapper sider-collapsed" : "content-wrapper"
          }
        >
          {children}
          <Spinner></Spinner>
        </Content>
      </Layout>
      <Footer />
    </>
  );
}
