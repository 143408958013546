import {
  Col,
  Modal,
  Row,
  Select,
  Form,
  Button,
  DatePicker,
  message,
  Switch,
} from "antd";
import Notice from "models/notice/notice";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allEmployeeStatuses,
  fetchAllEmployeesList,
  fetchEmployeeStatuses,
  getEmployeeDetailById,
} from "redux/employee/employeeSlice";
import { getInfractions } from "redux/infraction/infractionSlice";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";
import { dateToEpochFormat } from "utilities/dateUtility";
import generalConstants from "utilities/generalConstants";
import { handleError } from "utilities/generalUtility";

export default function EscalationModal({
  modalEsclateOpen,
  setModalEsclateOpen,
  infractionTypeId,
  infractionId,
  filters,
  employeeMode,
  dashboardMode,
}) {
  const { RangePicker } = DatePicker;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { employeeId } = useParams();
  const employeeStatusList = useSelector(allEmployeeStatuses);
  const [rangeRequired, setRangeRequired] = useState(true);
  const [data, setData] = useState();
  const [create, setCreate] = useState(false);
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "MM/DD/YYYY";
  const [noticeFlag, setNoticeFlag] = useState(false);

  const handleEmployeeStatusChange = (value, option) => {
    setRangeRequired(option ? !option.rangeRequired : true);
  };

  const getInfractionNotices = async () => {
    const res = await Notice.getOneInfractionNotice(infractionTypeId);
    setData(res?.data);
  };

  const isNoticeSend = (checked) => {
    setNoticeFlag(checked);
  };

  const getGeneratedNotice = async (values) => {
    const body = {
      infractionId,
      templateId: values.templates,
      newEmployeeStatusId: values?.employeeStatus
        ? values?.employeeStatus
        : null,
      statusStartDate: values?.date ? dateToEpochFormat(values?.date[0]) : null,
      statusEndDate: values?.date ? dateToEpochFormat(values?.date[1]) : null,
    };
    try {
      const res = await Notice.generateANotice(body);
      // message.success(res?.message);
      setValue(res?.data);
      form.setFieldsValue({
        notice: res?.data,
      });
      setCreate(true);
    } catch (error) {
      handleError(error);
    }
  };

  const markEscalcation = async () => {
    const values = form.getFieldValue();
    const body = {
      infractionId,
      notice: noticeFlag ? values.notice ?? null : null,
      employeeStatusId: values?.employeeStatus ? values?.employeeStatus : null,
      statusStartDate: values?.date ? dateToEpochFormat(values?.date[0]) : null,
      statusEndDate: values?.date ? dateToEpochFormat(values?.date[1]) : null,
      isNoticeSend: noticeFlag,
    };

    try {
      const res = await Notice.markEscalation(body);
      message.success(res?.message);
      if (employeeMode) {
        dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
      } else {
        dispatch(getInfractions(filters));
      }
      dispatch(saveSearchFilterIndicator(false));
      dispatch(saveSortFilterIndicator(false));
      setModalEsclateOpen(false);
      employeeId && dispatch(getEmployeeDetailById(employeeId));
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    dispatch(fetchEmployeeStatuses());
    getInfractionNotices();
    // form.setFieldsValue(null);
  }, []);

  return (
    <Modal
      title="Escalation"
      centered
      width={800}
      // okText="Send Notice"
      // cancelText="Cancel"
      open={modalEsclateOpen}
      onOk={markEscalcation}
      onCancel={() => {
        setModalEsclateOpen(false);
        if (!dashboardMode) {
          if (employeeMode) {
            dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
          } else {
            dispatch(getInfractions(filters));
          }
        }
      }}
      closeIcon={<i className="icon-close"></i>}
      className="app-modal escalation-modal"
      cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      bodyStyle={{ paddingBottom: "0px" }}
      footer={[
        <Button
          ghost
          onClick={() => {
            setModalEsclateOpen(false);
            if (!dashboardMode) {
              if (employeeMode) {
                dispatch(
                  fetchAllEmployeesList(generalConstants.defaultFilters)
                );
              } else {
                dispatch(getInfractions(filters));
              }
            }
          }}
        >
          Cancel
        </Button>,
        <Button onClick={markEscalcation} type="primary">
          {noticeFlag ? "Send Notice" : "Escalate"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="myForm"
        name="basic"
        layout="vertical"
        className="escalation-form"
        onFinish={getGeneratedNotice}
      >
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Employee Status:"
              name="employeeStatus"
              requiredMark={"optional"}
            >
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                placeholder="Select Status"
                allowClear
                onChange={handleEmployeeStatusChange}
                options={employeeStatusList?.map((element) => {
                  return {
                    label: element.name,
                    value: element.id,
                    rangeRequired: element.rangeRequired,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Start and End Date:"
              name="date"
              rules={[
                {
                  required: rangeRequired ? false : true,
                  message: "Please Select Status Dates",
                },
              ]}
            >
              <RangePicker
                format={dateFormat}
                disabled={rangeRequired}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-4">
            <span className="mr-2">Do you want to send Notice </span>
            {/* <Form.Item name={"isNoticeSend"}> */}
            <Switch
              onChange={isNoticeSend}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
            {/* </Form.Item> */}
          </Col>
          {noticeFlag ? (
            <>
              <Col xs={19}>
                <Form.Item
                  label="Notice Templates:"
                  name="templates"
                  rules={[
                    {
                      required: true,
                      message: "Please Select any notice template",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    placeholder="Select Notice"
                    options={data?.templates?.map((element) => {
                      return {
                        label: element.name,
                        value: element.id,
                      };
                    })}
                  />
                </Form.Item>
              </Col>

              <Col span={10} sm={5}>
                <Form.Item label=" ">
                  <Button
                    className="ef-submit-btn"
                    form="myForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                  >
                    {!create ? "Generate Notice" : "Regenerate Notice"}
                  </Button>
                </Form.Item>
              </Col>
              {create && (
                <Col span={24}>
                  <Form.Item label="Create a Notice:" name="notice">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          ) : null}
        </Row>
      </Form>
    </Modal>
  );
}
