import NetworkCall from "network/networkCall";
import inviteServices from "network/services/inviteServices";

export default class Invite {
  static async getUsersApi() {
    const res = await NetworkCall.fetch(inviteServices.getUsers());
    return res;
  }
  static async addUserApi(body) {
    const res = await NetworkCall.fetch(inviteServices.addUsers(body), false);
    return res;
  }
  static async updateUser(body, id) {
    const res = await NetworkCall.fetch(inviteServices.updateUser(body, id));
    return res;
  }
}
