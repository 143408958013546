// import react & redux
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInfractions,
  allInfractions,
  infractionPaginationData,
  deleteInfractionById,
  editInfractionById,
  infractionPaginationDataForPopup,
  allInfractionsForPopup,
} from "redux/infraction/infractionSlice";
import { getTimeframe, timeframe } from "redux/settings/settingsSlice";

// import antd
import {
  Button,
  Tag,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  Tooltip,
} from "antd";

// import styles
import "features/managInfractions/manageInfractions.scss";

// import utilities
import {
  dateToEpochFormat,
  epochToDateFormat,
  epochToMomentTime,
} from "utilities/dateUtility";

// import constants
import generalConstants from "utilities/generalConstants";

// import components
import ViewInfractionDetailModal from "features/supervisor/viewInfractionDetailModal";
import AccommodateInfactionModal from "./accomodateModal";
import GenericTable from "./genericTable";

// import  moment
import moment from "moment";
import EscalationModal from "./escalationModal";
import {
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";

const { TextArea } = Input;

export default function InfractionsListing({
  employeeId,
  markMode,
  dashboardMode,
  startDate,
  endDate,
  tab,
}) {
  // states, hooks & variables
  const dispatch = useDispatch();
  let [payloadFilters, setPayloadFilters] = useState(
    employeeId
      ? {
          ...generalConstants.defaultFilters,
          searchArray: [
            {
              key: "employeeId",
              value: employeeId,
              type: "integer",
            },
          ],
        }
      : dashboardMode
      ? {
          ...generalConstants.defaultFilters,
          searchArray: [
            {
              key: "infractionStatusName",
              value: "Pending Escalation",
              type: "string",
            },
            {
              key: "infractionDate",
              fromValue: startDate,
              toValue: endDate,
              type: "range",
            },
          ],
        }
      : generalConstants.defaultFilters
  );
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalEditInfractionOpen, setModalEditInfractionOpen] = useState(false);
  const [modalAccomodateOpen, setModalAccomodateOpen] = useState(false);
  const infractionsList = useSelector(
    markMode ? allInfractionsForPopup : allInfractions
  );
  const infractionPagination = useSelector(
    markMode ? infractionPaginationDataForPopup : infractionPaginationData
  );
  const [accomodate, setAccomodate] = useState(false);

  const [isModalViewEsclationOpen, setIsModalViewEsclationOpen] =
    useState(false);
  const [infractionId, setInfractionId] = useState(null);
  const [update, setUpdate] = useState();
  const [deleteId, setdeleteId] = useState();
  const [statusId, setstatusId] = useState();
  const [form] = Form.useForm();
  const [type, settype] = useState(1);
  const timeframeData = useSelector(timeframe);
  const [modalEsclateOpen, setModalEsclateOpen] = useState(false);
  const [InfractionTypeId, setInfractionTypeId] = useState();
  const dateFormat = "MM/DD/YYYY";

  let tableProps = {
    style: { cursor: "pointer" },
    className: "app-table manage-infraction-table",
    scroll: { x: markMode ? 727 : 900 },
    onRow: markMode
      ? (record, i) => {
          // do nothing
        }
      : (record, i) => {
          return {
            onClick: async (event) => {
              setstatusId(record?.id);
              setInfractionId(record?.id);
              setIsModalViewEsclationOpen(true);
              setAccomodate(true);
              setInfractionTypeId(record?.infractionTypeId);
            },
          };
        },
  };

  const infractionCol = [
    {
      title: "First Name",
      dataIndex: "firstName",
      type: "string",
      sorter: true,
      width: dashboardMode ? 150 : 200,
      hidden: employeeId ? true : false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      type: "string",
      sorter: true,
      width: dashboardMode ? 150 : 200,
      hidden: employeeId ? true : false,
    },
    {
      title:
        employeeId || dashboardMode ? "Infraction Type" : "Infraction Type",
      dataIndex: "infractionTypeName",
      type: "string",
      sorter: true,
      hidden: false,
      width: dashboardMode ? 150 : 200,
    },
    {
      title: employeeId || dashboardMode ? "Date" : "Date of Infraction",
      dataIndex: "infractionDate",
      width: dashboardMode ? 150 : 200,
      type: dashboardMode ? null : "range",
      sorter: dashboardMode ? false : true,
      hidden: false,
      render: (epochSeconds) => epochToDateFormat(epochSeconds),
    },
    {
      title: "Infraction Status",
      dataIndex: "infractionStatusName",
      type: dashboardMode ? null : "string",
      width: dashboardMode ? 150 : 200,
      sorter: dashboardMode ? false : true,
      hidden: false,
      render: (infractionStatusName) => (
        <Tag
          color={generalConstants.infractionStatusColors[infractionStatusName]}
        >
          {infractionStatusName}
        </Tag>
      ),
    },
    {
      title: "Notice",
      dataIndex: "notice",
      hidden: false,
      width: dashboardMode ? 150 : 200,
      align: "center",
      render: (notice) => (
        <Button type="text" className="notice-col-btn">
          <i
            className={notice === null ? "icon-times-circle" : "icon-tick"}
            style={{ color: notice === null ? "green" : "red" }}
          ></i>
        </Button>
      ),
    },
    //
    {
      title: "Comments",
      dataIndex: "comments",
      type: "string",
      ellipsis: true,
      sorter: true,
      hidden: employeeId || dashboardMode ? false : true,
      width: dashboardMode ? 150 : 200,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 300,
      hidden: markMode ? true : false,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
      render: (_, data) => (
        <div className="actions-col">
          {data?.infractionStatusName === "Pending Escalation" ? (
            <Fragment>
              <Tooltip
                title={
                  data?.employeeStatus === 3 || data?.employeeStatus === 5
                    ? "Can't accomodate because employee status is teminated or resigned"
                    : "Accomodate Infraction"
                }
              >
                <Button
                  disabled={
                    data?.employeeStatus === 3 || data?.employeeStatus === 5
                      ? true
                      : false
                  }
                  type="text"
                  onClick={() => {
                    setstatusId(data.id);
                    setModalAccomodateOpen(true);
                    settype(1);
                  }}
                >
                  Accommodate
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  data?.employeeStatus === 3 || data?.employeeStatus === 5
                    ? "Can't escalate because employee status is teminated or resigned"
                    : "Escalate Infraction"
                }
              >
                <Button
                  disabled={
                    data?.employeeStatus === 3 || data?.employeeStatus === 5
                      ? true
                      : false
                  }
                  type="text"
                  onClick={() => {
                    setInfractionTypeId(data?.infractionTypeId);
                    setInfractionId(data?.id);
                    setModalEsclateOpen(true);
                  }}
                >
                  Escalate
                </Button>
              </Tooltip>
            </Fragment>
          ) : null}
          {data?.infractionStatusName !== "Escalated" ? (
            <Fragment>
              <Tooltip title={"Edit Infraction"}>
                <Button
                  type="text"
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  Edit
                </Button>
              </Tooltip>
              <Tooltip title={"Delete Infraction"}>
                <Button
                  type="text"
                  onClick={() => {
                    handleDeleteModal(data);
                  }}
                >
                  Delete
                </Button>
              </Tooltip>
            </Fragment>
          ) : null}
          {data?.infractionStatusName === "Escalated" ? (
            <Button
              type="text"
              onClick={() => {
                setInfractionId(data.id);
                setIsModalViewEsclationOpen(true);
              }}
            >
              View Escalation
            </Button>
          ) : null}
        </div>
      ),
    },
  ].filter((column) => column.hidden === false);

  useEffect(() => {
    !tab && dispatch(getTimeframe());
  }, []);

  useEffect(() => {
    if (tab === "3") {
      dispatch(getInfractions(payloadFilters));
    }
  }, [startDate, endDate, tab]);

  //functions
  const handleEdit = (data) => {
    setUpdate(data);
    setModalEditInfractionOpen(true);
    form.setFieldsValue({
      employeeId: data.firstName,
      infractionTypeId: data.infractionTypeId,
      comments: data.comments,
      infractionDate: epochToMomentTime(data.infractionDate),
    });
  };

  const handleEditInfraction = (value) => {
    const body = {
      id: update.id,
      employeeId: update.employeeId,
      comments: value.comments,
      infractionDate: dateToEpochFormat(value.infractionDate),
      infractionTypeId: value.infractionTypeId,
    };

    dispatch(editInfractionById(body, payloadFilters));
    setModalEditInfractionOpen(false);
    dispatch(saveSearchFilterIndicator(false));
    dispatch(saveSortFilterIndicator(false));
    form.resetFields();
  };

  const handleDeleteModal = (data) => {
    setdeleteId(data.id);
    setModalDeleteOpen(true);
  };

  const handledeleteInfraction = () => {
    dispatch(deleteInfractionById(deleteId, payloadFilters));
    setModalDeleteOpen(false);
    dispatch(saveSearchFilterIndicator(false));
    dispatch(saveSortFilterIndicator(false));
  };

  return (
    <div>
      <GenericTable
        data={infractionsList}
        columns={infractionCol}
        apiCall={getInfractions}
        total={infractionPagination?.totalCount}
        tableProps={tableProps}
        idKey={employeeId ? "employeeId" : undefined}
        idValue={employeeId}
        markMode={markMode}
        dashboardMode={dashboardMode}
        startDate={startDate}
        endDate={endDate}
      />
      {isModalViewEsclationOpen && (
        <ViewInfractionDetailModal
          infractionId={infractionId}
          setInfractionId={setInfractionId}
          isModalViewEsclationOpen={isModalViewEsclationOpen}
          setIsModalViewEsclationOpen={setIsModalViewEsclationOpen}
          setAccomodate={setAccomodate}
          accomodate={accomodate}
          setModalAccomodateOpen={setModalAccomodateOpen}
          settype={settype}
          setModalEsclateOpen={setModalEsclateOpen}
        />
      )}

      <Modal
        title="Edit Infractions"
        centered
        width={800}
        destroyOnClose
        open={modalEditInfractionOpen}
        onCancel={() => setModalEditInfractionOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal add-employee"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
        bodyStyle={{ paddingBottom: "0px" }}
        footer={[
          <Button key="back" onClick={() => setModalEditInfractionOpen(false)}>
            Cancel
          </Button>,
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            Update
          </Button>,
        ]}
      >
        <Form
          id="myForm"
          name="basic"
          layout="vertical"
          form={form}
          onFinish={handleEditInfraction}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item label="Employee Name:" name="employeeId">
                <Input placeholder="Employee Name" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Infraction Type:"
                name="infractionTypeId"
                rules={[
                  { required: true, message: "Please Select Employee Name" },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  suffix={<i className="icon-select"></i>}
                  placeholder="Select Infraction Type"
                  options={timeframeData?.infractionTypes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Date:"
                name="infractionDate"
                rules={[
                  { required: true, message: "Please Select Employee Date" },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) =>
                    current.isAfter(moment().subtract(0, "day"))
                  }
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Add Comments:" name="comments">
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Delete Infraction"
        centered
        width={800}
        okText="Yes"
        cancelText="No"
        open={modalDeleteOpen}
        onOk={handledeleteInfraction}
        onCancel={() => setModalDeleteOpen(false)}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal accomodate-modal"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <p>Are you sure you want to delete this infraction?</p>
      </Modal>
      {modalAccomodateOpen && (
        <AccommodateInfactionModal
          statusId={statusId}
          type={type}
          filters={payloadFilters}
          setModalAccomodateOpen={setModalAccomodateOpen}
          modalAccomodateOpen={modalAccomodateOpen}
        ></AccommodateInfactionModal>
      )}

      {modalEsclateOpen && (
        <EscalationModal
          modalEsclateOpen={modalEsclateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
          infractionTypeId={InfractionTypeId}
          infractionId={infractionId}
          dashboardMode={dashboardMode}
          filters={payloadFilters}
        ></EscalationModal>
      )}
    </div>
  );
}
