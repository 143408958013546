import React, { useEffect } from "react";
import qs from "qs";
import md5 from "md5";
import { Form, Input, Button,message ,Row, Col } from "antd";
import { UserOutlined} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import User from "models/user/user";
import {
  deleteQueryParam,
  redirectToUrl,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import LoginImg from "assets/images/login-img.png";


export default function AdminSignup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    let encryptedPass = md5(values.password);
    try {
      let user = await dispatch(
        User.loginCall(values.email, encryptedPass, values.remember)
      );
      redirectToUrl(user.tenant.domainPrefix, "/");
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="auth-container login-wrap">
      <div className="login-img">
          <img src={LoginImg} />
        </div>
        <div className="login-card">
          <div className="lc-logo">
            <img src="images/site-logo.png" alt="" />
          </div>
          <h4>Sign Up</h4>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
             <Row gutter={16} >
             <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
               name={['user', 'name']}
               label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input
               
                suffix={
                  <UserOutlined/>
                }
                placeholder="Brandon"
                size="large"
              />
            </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
            name={['user', 'lastname']}
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input your Lastname!",
                },
              ]}
            >
                <Input
                type="email"
                suffix={
                  <UserOutlined />
                }
                placeholder="Carder"
                size="large"
              />
            </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
            <Form.Item label="Email" name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}>
              <Input
                type="example@example.com"
                suffix={<i className="icon-email"></i>}
                placeholder="example@example.com"
                size="large"
              
              />
            </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item label="Password" name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}>
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item label="Confirm Password" name="confrimpassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}>
              <Input.Password placeholder="Confirm Password" size="large" />
            </Form.Item>
            </Col>
            </Row>
            <Form.Item>
              <Button className="mb-2" block size="large" type="primary" htmlType="submit">
               Sign Up
              </Button>
            </Form.Item>
          </Form>
          <p className="text-center mb-0">
            Already have an account?
              <Link to="/login">
                <strong>Sign In</strong>
              </Link>
            </p>
        </div>
      </div>
    </React.Fragment>
  );
}
