import React, {  useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
//import styles from "./layout.module.scss";
import navigations from "./navigations";
import { isPermissionPresent, isRolePresent } from "utilities/generalUtility";
import User from "models/user/user";
import {  userGlobalDetail } from "redux/user/userSlice";
import { useSelector } from "react-redux";

const { SubMenu } = Menu;

export default function Sider({ collapsed, setCollapsed }) {
  const { Sider } = Layout;
  const [collapsedWidth, setCollapsedWidth] = React.useState(50);
  const userGlobalData = useSelector(userGlobalDetail);
  const user = User.getUserDetails();
  const location = useLocation();
  const isSuper = User.isSuperAdmin();
  const [openKeys, setOpenKeys] = useState([location.pathname.split("/")[1]]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onOpenChange = (keys) => {
    setOpenKeys([...keys]);
  };

  const onBreakpoint = (broken) => {
    // * Hide sider on screen width less than 768px
    if (broken) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(50);
    }
  };

  useEffect(() => {}, [userGlobalData]);

  return (
    <Sider
      width={210}
      className="sider-wrapper"
      breakpoint="md"
      collapsible
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      onCollapse={onCollapse}
      onBreakpoint={onBreakpoint}
      trigger={null}
    >
      <Menu
        selectedKeys={[`/${location.pathname.split("/")[1]}`]}
        mode="inline"
        className="sider-menu-list"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {navigations.map((navigation, i) => {
          const hasPermission = isPermissionPresent(
            navigation.parentName,
            user?.data?.role
          );

          if (!hasPermission) {
            return null;
          }
          if (isSuper) {
            if (navigation?.superAdmin === false) {
              return null;
            }
          } else {
            if (navigation?.superAdmin) {
              return null;
            }
          }

          if ((navigation.children?.length ?? 0) > 0) {
            const subMenuKey = navigation.children[0].path.split("/")[1];
            return (
              <SubMenu
                key={subMenuKey}
                icon={<SettingOutlined />}
                title={navigation.name}
                mode="inline"
                className="sider-menu-dropdown"
              >
                {navigation.children.map((subNavigation, j) => {
                  const navHasRole = isRolePresent(
                    subNavigation.roles,
                    User.roles()
                  );
                  return navHasRole ? (
                    <Menu.Item
                      key={subNavigation.path}
                      className={
                        location.pathname.includes(subNavigation.path)
                          ? "ant-menu-item-selected"
                          : ""
                      }
                    >
                      <Link to={subNavigation.path}>{subNavigation.name}</Link>
                    </Menu.Item>
                  ) : null;
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={navigation.path} icon={navigation.icon}>
                {<Link to={navigation.path}>{navigation.name}</Link>}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Sider>
  );
}
