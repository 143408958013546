import { createSlice } from "@reduxjs/toolkit";
import NetworkCall from "network/networkCall";
import historyServices from "network/services/historyServices";

export const historySlice = createSlice({
  name: "history",
  initialState: {
    allHistory: [],
    count: null,
  },
  reducers: {
    saveAllHistory: (state, action) => {
      state.allHistory = [...action.payload.employeeActivities];
      state.count = action.payload?.pagination?.totalCount;
    },
  },
});

export const fetchAllHistoryList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        historyServices.getAllHistoryList(payload)
      );
      const result = response?.data;
      dispatch(saveAllHistory(result));
    } catch (error) {}
  };
};

export const { saveAllHistory } = historySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const allHistory = (state) => state?.history?.allHistory;
export const totalRecords = (state) => state?.history?.count;

export default historySlice.reducer;
