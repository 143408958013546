export const phoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const onlyAlphabets = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
export const onlyAlphabetsAdvanced = /^[a-zA-Z]+(?:\s*[a-zA-Z]+)*\s*$/;
export const onlyAlphabetsAndSpecialChars =
  /^(?!.*\s$)(?![\d\s]+$)[a-zA-Z\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;

export const withoutSpecialCharacters = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#^,/()+-=*_<>?`~"'|])[A-Za-z\d@$!%*?&.#^,/()+-=*_<>?`~"'|]{6,}$/;
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#^<>|])[A-Za-z\d@$!%*?&.#^<>|]{8,}$/;

export const passwordMessage = (
  <>
    Password must be at least 8 characters, non alphanumeric character, one
    digit ('0'-'9'), one uppercase ('A'-'Z'), one lowercase ('a'-'z'), one
    special character{" "}
    {/* <li>Password must have at least one non alphanumeric character</li>
      <li>Password must have at least one digit ('0'-'9')</li>
      <li>Password must have at least one uppercase ('A'-'Z')</li>
      <li>Password must have at least one lowercase ('a'-'z')</li>
      <li>Password must have at least one special character</li> */}
  </>
);
export const leadingSpacesRegex = /^(?!\s)/;
export const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9]*([ ][a-zA-Z0-9]+)*$/;
export const feadbackRegex = /^(?!^\s|\s$)[a-zA-Z0-9\s]{5,500}$/;
export const onlyPositive = /^(0|[1-9]\d*)$/;
