import K from "utilities/constants";
import Request from "../request";

export default class planServices {
  static getALLPlans() {
    return new Request(
      K.Network.URL.Plans.GetPlans,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getALLPermissions() {
    return new Request(
      K.Network.URL.Plans.GetPermissions,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getActivePlan() {
    return new Request(
      K.Network.URL.Plans.GetActivePlan,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getInvoice() {
    return new Request(
      K.Network.URL.Plans.GetInvoice,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static goToCustomerPortal() {
    return new Request(
      K.Network.URL.Plans.CustomerPortal,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static cancelSubscription(values) {
    const body = values;
    return new Request(
      K.Network.URL.Plans.CancelSubscription,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static paymentCheckout(values) {
    const body = values;
    return new Request(
      K.Network.URL.Plans.PaymentCheckout,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
