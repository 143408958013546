import NetworkCall from "network/networkCall";
import authServices from "network/services/authServices";
export default class Auth {
  static async sendOTP(values) {
    return await NetworkCall.fetch(authServices.sendOTP(values));
  }
  static async verifyOTP(values, accessToken) {
    return await NetworkCall.fetch(authServices.verifyOTP(values, accessToken));
  }
}
