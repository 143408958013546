import React, { useEffect, useState } from "react";
import { Typography, Card } from "antd";
import { Link, useParams } from "react-router-dom";
import Notice from "models/notice/notice";
import { epochToDateFormat } from "utilities/dateUtility";
import ReactQuill from "react-quill";
const { Title } = Typography;

export default function ViewInfractionType() {
  const { typeId } = useParams();
  const [data, setData] = useState();

  const getInfractionNotices = async () => {
    const res = await Notice.getOneInfractionNotice(typeId);
    setData(res?.data);
  };

  useEffect(() => {
    typeId && getInfractionNotices();
  }, [typeId]);

  return (
    <>
      <Card
        title="View Infraction Type"
        className="app-card edit-infraction-card"
        extra={
          <>
            <Link className="ant-btn ant-btn-primary" to="edit-infraction">
              Edit Infraction Type
            </Link>
          </>
        }
      >
        <div className="edit-infraction-wrap">
          <div>
            <Title level={2}>
              Infraction Name:<span> {data?.name}</span>
            </Title>
            <p>{epochToDateFormat(data?.createdAt)}</p>
          </div>
        </div>
        {data?.templates?.map((e) => {
          return (
            <>
              <Title level={2} className="edit-inf-title">
                {`${e?.name}: `}
              </Title>
              <div className="infraction-info-wrap">
                {/* <p>{e?.noticeTemplate}</p> */}
                <ReactQuill
                  readOnly
                  value={e?.noticeTemplate}
                  theme="snow"
                  placeholder="Write your notice here..."
                />
              </div>
            </>
          );
        })}
        {/* <Title level={2} className="edit-inf-title">
          Notice:
        </Title>
        <div className="infraction-info-wrap">
          <p>
            [EMPLOYEE NAME],<br></br>
            On [DATE] you were absent for your scheduled shift. In the last
            [NUMBER] days, you have missed [NUMBER] days of work. This is not in
            accordance with Company Policy. You acknowledged and agreed to
            Company Policy on [DATE]. Company Policy can be reviewed [HERE]. As
            a result of your absence and in accordance with company policy
            [REPERCUSSIONS AUTOMATICALLY INSETED BASED ON SETTINGS]. You may
            reach out to me with any questions. I will be in contact with you to
            schedule a time to speak with you in person.
          </p>
        </div> */}
      </Card>
    </>
  );
}
