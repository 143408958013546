import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Divider,
  Form,
  Row,
  Col,
  Input,
  Table,
  Popover,
  message,
} from "antd";
import "features/settings/infractionsList/infractionsList.scss";
import ReactQuill from "react-quill";
import Notice from "models/notice/notice";
import { PlusOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { handleError } from "utilities/generalUtility";
import { useNavigate, useParams } from "react-router-dom";
import InputColor from "react-input-color";
import { leadingSpacesRegex } from "utilities/regexUtility";

export default function CreateInfraction() {
  const [form] = Form.useForm();
  const [variables, setvariables] = useState();
  const [visibleTooltip, setVisibleTooltip] = useState(null);
  const { typeId } = useParams();
  const [ids, setId] = useState();

  const [noticeList, setnoticeList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();

  const getVariables = async () => {
    const res = await Notice.fetchAllVariables();
    setvariables(res?.data);
  };

  const addNotice = () => {
    setnoticeList((prev) => [...prev, Math.floor(Math.random() * 100) + 1]);
  };
  const removeNotice = (notice, index) => {
    const tempNotice = [...noticeList];
    tempNotice.splice(index, 1);

    setnoticeList(tempNotice);
  };

  const handleButtonClick = (e, text) => {
    e.stopPropagation();
    setVisibleTooltip(text);
    setTimeout(() => {
      setVisibleTooltip(null);
    }, 2000);
  };

  const handleFinish = async (values) => {
    console.log({ values });
    try {
      setBtnLoading(true);
      const templates = [];
      noticeList?.map((e, i) => {
        return typeId
          ? templates.push({
              id: ids.includes(e) ? e : null,
              name: values[`noticeName${e}`],
              noticeTemplate: values[`noticeDetail${e}`],
            })
          : templates.push({
              name: values[`noticeName${e}`],
              noticeTemplate: values[`noticeDetail${e}`],
            });
      });

      const body = {
        name: values?.name,
        color: values?.color,
        templates,
      };

      typeId ? (body["id"] = +typeId) : (body["allowedInfractions"] = 3);

      const res = typeId
        ? await Notice.editInfractionNotice(body)
        : await Notice.addInfractionType(body);
      message.success(res.message);
      setBtnLoading(false);
      navigate(-1);
    } catch (error) {
      handleError(error);
      setBtnLoading(false);
    }
  };

  const getInfractionNotices = async () => {
    const res = await Notice.getOneInfractionNotice(typeId);
    setData(res?.data?.templates.length);

    let temp = [];
    let notices = {};

    res?.data?.templates?.map((e) => {
      notices[`noticeName${e.id}`] = e.name;
      notices[`noticeDetail${e.id}`] = e.noticeTemplate;
      return temp.push(e.id);
    });
    setnoticeList(temp);
    setId(temp);

    notices.name = res?.data?.name;
    notices.color = res?.data?.color || "#1A56A5";

    form.setFieldsValue(notices);
  };

  useEffect(() => {
    getVariables();
    const arr = [1];
    !typeId && setnoticeList(arr);
    typeId && getInfractionNotices();
  }, []);

  const infractionListCol = [
    {
      title: "Variable Name",
      dataIndex: "variableName",
      width: 200,
      render: (data) => <p className="offender-col-info">{`[${data}]`}</p>,
    },
    {
      title: "Example",
      dataIndex: "example",
      width: 250,
      render: (data) => <p className="offender-col-info">{data}</p>,
    },
    {
      title: "Actions",
      dataIndex: "copy",
      width: 50,
      render: (_, data) => (
        <Popover
          visible={visibleTooltip === data.id}
          content="Text Copied!"
          trigger="click"
          //   onVisibleChange={(visible) =>
          //     handleTooltipClose(visible ? null : visibleTooltip)
          //   }
        >
          <CopyToClipboard text={`[${data.variableName}]`}>
            <Button
              id={data.id}
              className="btn-col"
              type="text"
              onClick={(e) => handleButtonClick(e, data.id)}
            >
              <i className="icon-copy"></i>
            </Button>
          </CopyToClipboard>
        </Popover>
      ),
    },
  ];

  return (
    <>
      <Card
        title={`${typeId ? "Edit" : "Create"} An Infraction`}
        className="app-card create-infraction-card"
        // extra={
        //   <>
        //     <Button type="primary">Create Infraction</Button>
        //   </>
        // }
      >
        <Form
          name="basic"
          layout="vertical"
          className="create-infraction-form"
          form={form}
          onFinish={handleFinish}
          id="myForm"
        >
          <Row gutter={[20, 6]}>
            <Col span={24}>
              <Divider orientation="left" orientationMargin="0">
                Infraction Info
              </Divider>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Infraction Type:"
                name="name"
                rules={[
                  { required: true, message: "Infraction Name is Required" },
                  {
                    pattern: new RegExp(leadingSpacesRegex),
                    message: "Field does not accept leading spaces",
                  },
                  {
                    max: 15,
                    message: "Name must be less than 15 characters",
                  },
                ]}
              >
                <Input placeholder="Enter Infraction type " />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={12}> */}
            <Form.Item
              name="color"
              label={"Color:"}
              rules={[
                {
                  required: true,
                  message: "Infraction Type color is Required",
                },
              ]}
            >
              <Input
                type="text"
                className="color-picker"
                // size="large"
                readOnly={true}
                suffix={
                  <InputColor
                    initialValue={form?.getFieldValue("color") ?? "#1A56A5"}
                    style={{ width: "20px" }}
                    onChange={(color) => {
                      form.setFieldsValue({
                        color: color?.hex?.toUpperCase(),
                      });
                    }}
                    placement="left"
                  />
                }
              />
            </Form.Item>
            {/* </Col> */}
            <Col span={24}>
              <Divider orientation="left" orientationMargin="0">
                Notice Template
              </Divider>
            </Col>
            <Col span={24}>
              <Table
                className="app-table infraction-list-table create-infraction-table"
                pagination={false}
                columns={infractionListCol}
                dataSource={variables}
                scroll={{
                  x: 900,
                }}
              />
            </Col>
            <Col span={24} className="mt-4">
              <Form.Item label="Sample:">
                <div className="infraction-info-wrap">
                  <p>
                    [EMP_FIRST_NAME],<br></br>
                    On [INFRACTION_DATE], you were absent for your scheduled
                    shift. In the last days, you have missed
                    [NUMBER_OF_INFRACTIONS_RECEIVED] days of work. This is not
                    in accordance with Company Policy. You acknowledged and
                    agreed to Company Policy on [EMP_JOINING_DATE]. Company
                    Policy can be reviewed [COMPANY_POLICY_LINK]. As a result of
                    your absence and in accordance with company policy
                    repercussion automatically inserted based on settings. You
                    may reach out to me with any questions. I will be in contact
                    with you to schedule a time to speak with you in person.
                  </p>
                </div>
              </Form.Item>
            </Col>
            {noticeList.map((notice, i) => (
              <Col span={24}>
                <Card
                  className="app-card notice-card mb-4"
                  title={`Notice ${i + 1}`}
                  extra={
                    <>
                      {i !== 0 && (
                        <i
                          style={{ cursor: "pointer" }}
                          className="icon-close"
                          onClick={() => removeNotice(notice, i)}
                        ></i>
                      )}
                    </>
                  }
                >
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Notice Name:"
                      name={`noticeName${notice}`}
                      rules={[
                        { required: true, message: "Notice Name is Required" },
                      ]}
                    >
                      <Input placeholder="Enter Notice Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={`noticeDetail${notice}`}
                      rules={[
                        {
                          required: true,
                          message: "Notice Detail is Required",
                        },
                      ]}
                    >
                      <ReactQuill
                        theme="snow"
                        placeholder="Write your notice here..."
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
            ))}
            <Col span={24}>
              <div
                className="uc-content add-notice mb-3"
                onClick={addNotice}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <span>
                    <PlusOutlined className="icon-plus" />
                  </span>
                  <p>Add Notice</p>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="form-btns">
          <Button
            type="Default"
            className="ant-btn-background-ghost"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Button
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={btnLoading}
          >
            {typeId ? "Save" : "Create"}
          </Button>
        </div>
      </Card>
    </>
  );
}
