import NetworkCall from "network/networkCall";
import companyServices from "network/services/admin/companyServices";

export default class Company {
  static async getCompanyDetail(id) {
    return await NetworkCall.fetch(companyServices.getCompanyById(id));
  }

  static async extendingFreeTrial(body) {
    return await NetworkCall.fetch(companyServices.extendingTrial(body), false);
  }
  static async getCompanyRevenue(body) {
    return await NetworkCall.fetch(companyServices.getCompanyRevenueById(body));
  }
  static async changeCompanyStatus(body) {
    return await NetworkCall.fetch(companyServices.changeStatus(body));
  }
  static async superAdminCompanySetting(body) {
    return await NetworkCall.fetch(companyServices.superAdminSetting(body));
  }
}
