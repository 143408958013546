import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Avatar, Badge, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { createElement } from "react";
import User from "models/user/user";
import { Link, useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import MiniLogo from "assets/images/mini-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { userGlobalDetail } from "redux/user/userSlice";
import { SubscriptionStatus } from "utilities/generalConstants";
import {
  allNotifications,
  fetchNotificationList,
  getUnseenCount,
  resetNotificationCount,
  unseen,
} from "redux/notification/notificationSlice";
import { epochToDateFormat } from "utilities/dateUtility";
import NetworkCall from "network/networkCall";
import notificationServices from "network/services/notificationService";

const { Title } = Typography;

export default function Header({ collapsed, toggle }) {
  const dispatch = useDispatch();
  const { Header } = Layout;
  const userGlobalData = useSelector(userGlobalDetail);
  const navigate = useNavigate();
  const notificationList = useSelector(allNotifications);

  const UnseenCount = useSelector(unseen);
  const [dropDownOpen, setdropDownOpen] = useState(false);

  const markAsRead = async (id) => {
    try {
      const res = await NetworkCall.fetch(
        notificationServices.markRead({
          nrIdList: [id],
        }),
        false
      );
      console.log({ res });
      getNotifications();
    } catch (error) {}
  };

  const notification = (
    <div style={{ maxWidth: "280px" }}>
      <Menu className="notification-dropdwon">
        {notificationList.length > 0 ? (
          notificationList?.map((e) => {
            return (
              <Menu.Item
                key={e?.notification?.id}
                onClick={() => {
                  markAsRead(e?.id);
                  navigate(
                    e?.notification?.type === "user"
                      ? "/manage-invite"
                      : `/admin-manage-companies/admin-company-detail/${e?.notification?.entityId}`
                  );
                  setdropDownOpen(false);
                }}
              >
                <div className="menu-items">
                  <div>
                    {e?.entityData?.profileImage || e?.entityData?.logo ? (
                      <Avatar
                        src={
                          e?.notification?.type === "user"
                            ? e?.entityData?.profileImage
                            : e?.entityData?.logo
                        }
                      />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )}
                  </div>
                  <div className="notification-info">
                    {" "}
                    <Title level={4}>
                      {e?.notification?.title}{" "}
                      {e?.isRead === 0 && <Badge color={"blue"} />}
                    </Title>
                    <p>{e?.notification?.text}</p>
                    <small>
                      {epochToDateFormat(e?.notification?.createdAt)}
                    </small>
                  </div>
                </div>
              </Menu.Item>
            );
          })
        ) : (
          <Menu.Item key={0}>
            <div className="menu-items">
              <div>
                <Title level={4}>No notification found</Title>
              </div>
            </div>
          </Menu.Item>
        )}
        {notificationList.length > 0 && (
          <Link
            to={"/notifications"}
            onClick={() => {
              setdropDownOpen(false);
            }}
          >
            <div className="text-center py-3">See More</div>
          </Link>
        )}
      </Menu>
    </div>
  );

  const menu = (
    <Menu className="header-dropdown">
      {![
        SubscriptionStatus.PAUSED,
        SubscriptionStatus.CANCELLED,
        SubscriptionStatus.EXPIRED,
      ].includes(User.getSubscriptionStatus()) && (
        <Menu.Item key="0">
          <Link to={"/admin-settings"}>Profile Settings</Link>
        </Menu.Item>
      )}
      <Menu.Item key="1" onClick={() => User.logoutCall()}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const getNotifications = () => {
    const body = {
      page: 1,
      pageSize: 5,
    };

    dispatch(fetchNotificationList(body));
  };

  const markSeen = async () => {
    try {
      dispatch(resetNotificationCount());
      const res = await NetworkCall.fetch(
        notificationServices.markAllSeen({}),
        false
      );
      console.log({ res });
    } catch (error) {}
  };

  useEffect(() => {
    if (User.isSuperAdmin()) {
      getNotifications();
      dispatch(getUnseenCount());
    }
  }, []);

  return (
    <Header className="site-header">
      <div className="logo-wrap">
        <Link to="/dashboard">
          <img src={Logo} alt="" className="header-logo" />
          <img src={MiniLogo} alt="" className="mini-logo" />
        </Link>
      </div>
      <div className="app-header-right">
        <div className="loggedin-user-dd">
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger sider-toggle-icon",
            onClick: toggle,
          })}
          {User.isSuperAdmin() && (
            <Dropdown
              overlay={notification}
              trigger={["click"]}
              placement="bottomRight"
              open={dropDownOpen}
              onOpenChange={(op) => {
                setdropDownOpen(op);
                op && UnseenCount && markSeen();
              }}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
            >
              <a
                href="#menu"
                className="ant-btn-link notification-btn"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Badge count={UnseenCount}>
                  <i className="icon-Bell"></i>
                </Badge>
              </a>
            </Dropdown>
          )}
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              href="#menu"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {userGlobalData?.data?.profileImage ? (
                <Avatar src={userGlobalData?.data?.profileImage} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}{" "}
              {userGlobalData?.data?.firstName +
                " " +
                userGlobalData?.data?.lastName}
              <span>
                <i className="icon-dropdown"></i>
              </span>
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
}
