// import libraries
import moment from "moment";

// functions
export const epochToDateFormat = (epochSeconds) => {
  let date = new Date(epochSeconds * 1000);
  if (typeof epochSeconds === "number") {
    return moment(date).format("MM/DD/YYYY");
  } else {
    return "--";
  }
};

export const epochToDateSpecialFormat = (epochSeconds) => {
  let date = new Date(epochSeconds * 1000);
  if (typeof epochSeconds === "number") {
    return moment(date).format("DD MMMM YYYY");
  } else {
    return "--";
  }
};

export const dateToEpochFormat = (date) => {
  return new Date(moment(date).format("YYYY MM DD 00:00:00")).getTime() / 1000;
};

export const endDateToEpochFormat = (date) => {
  return new Date(moment(date).format("YYYY MM DD 23:59:59")).getTime() / 1000;
};

export const epochToMomentTime = (epochSeconds) => {
  if (typeof epochSeconds === "number") {
    return moment.unix(epochSeconds);
  } else {
    return;
  }
};

export const getGmtOffset = () => {
  return moment(new Date()).format("Z");
};

export const numberToMonthName = (num) => {
  return new Date(0, num - 1).toLocaleString("default", {
    month: "long",
  });
};

export const epochToDateFormatWithTime = (epochSeconds) => {
  if (typeof epochSeconds === "number") {
    let date = new Date(epochSeconds * 1000);
    return moment(date).format("h:mm A, MM/DD/YYYY");
  } else {
    return "--";
  }
};

export const startAndEndDates = (date, datePicker) => {
  const startDate = dateToEpochFormat(date.startOf(datePicker).toString());
  const endDate = endDateToEpochFormat(date.endOf(datePicker).toString());

  return { startDate, endDate };
};
