import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import NetworkCall from "network/networkCall";
import infractionServices from "network/services/infractionServices";
import { fetchAllEmployeesList } from "redux/employee/employeeSlice";
import generalConstants from "utilities/generalConstants";
import { handleError } from "utilities/generalUtility";

export const infractionSlice = createSlice({
  name: "infraction",
  initialState: {
    data: {},
    allInfractions: [],
    allInfractionsForPopup: [],
    infractionPagination: null,
    infractionPaginationForPopup: null,
    infractionType: [],
    employeeInfractionCount: [],
    loading: false,
    compareEmployeeInfraction: {},
  },
  reducers: {
    saveInfractionsCount: (state, action) => {
      state.employeeInfractionCount = [...action?.payload];
    },
    saveEscalationDetails: (state, action) => {
      state.data = { ...action.payload };
    },
    saveAllInfractions: (state, action) => {
      state.allInfractions = [...action?.payload?.infractionsList];
      state.infractionPagination = { ...action.payload.pagination };
    },
    saveAllInfractionsForPopup: (state, action) => {
      state.allInfractionsForPopup = [...action?.payload?.infractionsList];
      state.infractionPaginationForPopup = { ...action.payload.pagination };
    },
    saveAllInfractionsType: (state, action) => {
      state.infractionType = { ...action.payload };
      state.infractionPagination = action.payload.pagination;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    saveInfractionCompare: (state, action) => {
      state.compareEmployeeInfraction = { ...action?.payload };
    },
  },
});

export const getEscalationById = (id) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.viewEscalationById(id)
      );
      const result = response?.data;
      dispatch(saveEscalationDetails(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const getInfractions = (values, markMode) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.getInfractionList(values)
      );
      const result = response?.data;
      markMode
        ? dispatch(saveAllInfractionsForPopup(result))
        : dispatch(saveAllInfractions(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const getInfractionsType = (values) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.getInfractionTypeList(values)
      );
      const result = response?.data;
      dispatch(saveAllInfractionsType(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const getEmployeeInfractionsCount = (values) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.getEmployeeInfractions(values)
      );
      const result = response?.data;
      dispatch(saveInfractionsCount(result));
    } catch (error) {
      handleError(error);
    }
  };
};
export const deleteInfractionById = (id, filter) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.deleteInfraction(id)
      );
      message.success(response.message);
      dispatch(getInfractions(filter, false));
    } catch (error) {
      handleError(error);
    }
  };
};
export const editInfractionById = (body, filters) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.updateInfraction(body)
      );
      message.success(response.message);
      dispatch(getInfractions(filters, false));
    } catch (error) {
      handleError(error);
    }
  };
};

export const infractionStatus = (
  id,
  status,
  filters,
  comments,
  employeeMode
) => {
  return async (dispatch, getState) => {
    try {
      // dispatch(setLoading(true));
      const body = { id: id, infractionStatusId: status, comments: comments };

      const response = await NetworkCall.fetch(
        infractionServices.changeInfractionStatus(body)
      );
      message.success(response.message);
      let markMode = false;
      if (employeeMode) {
        dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
      } else {
        dispatch(getInfractions(filters, markMode));
      }

      // dispatch(setLoading(false));
    } catch (error) {
      // dispatch(setLoading(false));

      handleError(error);
    }
  };
};
export const getCompareInfraction = (values) => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        infractionServices.CompareInfraction(values)
      );
      const result = response?.data;
      dispatch(saveInfractionCompare(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const {
  saveEscalationDetails,
  saveAllInfractionsForPopup,
  saveAllInfractions,
  saveInfractionsCount,
  saveAllInfractionsType,
  setLoading,
  saveInfractionCompare,
} = infractionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const escalationDetails = (state) => state?.infraction.data;
export const allInfractions = (state) => state?.infraction.allInfractions;
export const allInfractionsForPopup = (state) =>
  state?.infraction.allInfractionsForPopup;
export const infractionPaginationData = (state) =>
  state?.infraction.infractionPagination;
export const infractionPaginationDataForPopup = (state) =>
  state?.infraction.infractionPaginationForPopup;
export const allInfractionsType = (state) =>
  state?.infraction.infractionType.infractionTypes;
export const employeeInfractionCount = (state) =>
  state?.infraction?.employeeInfractionCount;
export const compareInfraction = (state) =>
  state?.infraction?.compareEmployeeInfraction;

// export
export const employeeInfractionCompare = (state) =>
  state?.infraction?.compareEmployeeInfraction;

export const infractionLoading = (state) => state?.infraction.loading;

export default infractionSlice.reducer;
