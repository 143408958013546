import { Column } from "@ant-design/plots";
import { Empty } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGraphData, graphData } from "redux/dashboard/dasboardSlice";

export default function InfractionPerEmployee({ startDate, endDate, tab }) {
  const dispatch = useDispatch();
  const data = useSelector(graphData);

  const config = {
    data,
    isStack: true,
    xField: "employeeName",
    yField: "infractionCount",
    seriesField: "infractionTypesName",
    legend: {
      padding: [20, 0, 0, 30], // Adjust the padding values as needed [top, right, bottom, left]
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },

    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    color: ({ infractionTypesName }) => {
      return data.find((e) => e?.infractionTypesName === infractionTypesName)
        .infractionTypesColor;
    },

    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: "rgba(0,0,0,0.25)",
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
    scrollbar: {
      type: "horizontal",
    },
    columnStyle: {
      radius: [100, 100, 100, 100],
    },
    minColumnWidth: 6,
    maxColumnWidth: 11,
  };

  const getGraphData = () => {
    try {
      const body = {
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(fetchGraphData(body));
    } catch (error) {}
  };

  useEffect(() => {
    if (startDate && endDate) {
      tab === "1" && getGraphData();
    }
  }, [startDate, endDate, tab]);

  console.log({ data });
  return data.length ? (
    <Column {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
}
