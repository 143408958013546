import K from "utilities/constants";
import Request from "../request";

export default class inviteServices {
  static getUsers() {
    return new Request(
      K.Network.URL.Invite.UsersList,
      K.Network.Method.GET,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static addUsers(value) {
    const body = { ...value };
    return new Request(
      K.Network.URL.Invite.AddUser,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static getRoles() {
    return new Request(
      K.Network.URL.Invite.GetRoles,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
  static removeUserById(userId){
    return new Request(
      K.Network.URL.Invite.RemoveUser + userId,
      K.Network.Method.DELETE,
      {},
      K.Network.Header.Type.Json,
      {},
      false
    )
    }
  static updateUser(value, id) {
    const body = { ...value };

    return new Request(
      `${K.Network.URL.Invite.UpdateUser}/${id}`,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {},
      false
    );
  }
}
