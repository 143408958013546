import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import NetworkCall from "network/networkCall";
import planServices from "network/services/planServices";
import { handleError } from "utilities/generalUtility";

export const planSlice = createSlice({
  name: "plans",
  initialState: {
    plans: [],
    activePlan: {},
    permissions: [],
  },
  reducers: {
    saveAllPlans: (state, action) => {
      state.plans = [...action.payload];
    },
    savePermissions: (state, action) => {
      state.permissions = [...action.payload];
    },
    saveActivePlan: (state, action) => {
      state.activePlan = { ...action.payload };
    },
  },
});

export const fetchPlans = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(planServices.getALLPlans());
      const result = response?.data;
      dispatch(saveAllPlans(result));
    } catch (error) {
      handleError(error);
    }
  };
};
export const fetchPermissions = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        planServices.getALLPermissions()
      );
      const result = response?.data;
      dispatch(savePermissions(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchActivePlan = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(planServices.getActivePlan());
      const result = response?.data;
      dispatch(saveActivePlan(result));
    } catch (error) {
      handleError(error);
    }
  };
};

export const fetchInvoice =  () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(planServices.getInvoice());
      window.open(response?.data, "_blank");
      message.success(response.message);
    } catch (error) {
      handleError(error);
    }
  };
};

export const goToCustomerPortal = () => {
  return async (dispatch, getState) => {
    try {
      const response = await NetworkCall.fetch(
        planServices.goToCustomerPortal()
      );
      const result = response?.data;
      window.location.href = result;
    } catch (error) {
      handleError(error);
    }
  };
};

export const cancelSubscription = (body) => {
  return async (dispatch, getState) => {
    try {
      const result = await NetworkCall.fetch(
        planServices.cancelSubscription(body)
      );
      message.success(result.message);
      dispatch(fetchActivePlan());
    } catch (error) {
      handleError(error);
    }
  };
};

export const checkout =  (body) => {
  return async (dispatch, getState) => {
    try {
      const result = await NetworkCall.fetch(planServices.paymentCheckout(body));
      window.location.href = result?.data;
    } catch (error) {
      handleError(error);
    }
  }
};

export const { saveAllPlans, saveActivePlan, savePermissions } =
  planSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const allPlans = (state) => state?.plans?.plans;
export const activePlan = (state) => state?.plans?.activePlan;
export const getPermissions = (state) => state?.plans?.permissions;

export default planSlice.reducer;
