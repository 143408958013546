import NetworkCall from "network/networkCall";
import settingServices from "network/services/settingServices";

export default class Settings {
  static async changePassword(values) {
    const res = await NetworkCall.fetch(settingServices.changePassword(values));
    return res;
  }
  static async basicSetting() {
    const { data } = await NetworkCall?.fetch(
      settingServices?.getBasicSetting()
    );

    return data;
  }
  static async companyLogo(values) {
    const res = await NetworkCall?.fetch(
      settingServices?.editCompanyLogo(values)
    );
    return res;
  }
  static async getPersonalSettings(values) {
    return await NetworkCall.fetch(settingServices.getPersonalSettings());
  }
  static async updatePersonalSettings(values) {
    return await NetworkCall.fetch(
      settingServices.updatePersonalSettings(values)
    );
  }
  static async updateColorBranding(values) {
    return await NetworkCall.fetch(settingServices.updateColorBranding(values));
  }
  static async updateProfilePicture(values) {
    return await NetworkCall.fetch(
      settingServices.updateProfilePicture(values)
    );
  }
  static async submitFeedback(values) {
    return await NetworkCall.fetch(settingServices.submitFeedback(values));
  }
}
