// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeUserGlobalDetail, userGlobalDetail } from "redux/user/userSlice";

// import antd
import {
  Card,
  Avatar,
  Typography,
  Button,
  Tag,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Upload,
  Popover,
} from "antd";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";

// import models
import Settings from "models/setting/setting";

// import utilities
import {
  handleError,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import {
  onlyAlphabetsAndSpecialChars,
  passwordMessage,
  passwordRegex,
  phoneRegex,
} from "utilities/regexUtility";
import { epochToDateSpecialFormat } from "utilities/dateUtility";

// import libraries
import md5 from "md5";

// import styles
import "./adminSettings.scss";
import PasswordValidation from "features/signUp/Components/PasswordValidation";

const { Title, Paragraph } = Typography;

export default function AdminSettings() {
  // states, hooks & variables
  const userGlobalData = useSelector(userGlobalDetail);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [imageURL, setImageURL] = useState(null);
  const [personalSettings, setPersonalSettings] = useState(null);
  const [password, setPassword] = useState();

  const props = {
    name: "file",
    className: "w-100 d-block bulk-upload-btn",
    showUploadList: false,
    maxCount: 1,
    listType: "picture",
    accept: "image/png, image/jpeg, image/jpg, image/svg, image/gif",
    beforeUpload: (info) => {
      return false;
    },

    onChange: async (info) => {
      const imageSizeLimit = 2 * 1024 * 1024;
      const isLt2M = info.file.size < imageSizeLimit;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return Upload.LIST_IGNORE;
      } else {
        getBase64(info.file).then((value) => {
          handleEditProfilePicture(info?.file, value);
        });
      }
    },
  };

  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangePassword = async (values) => {
    const payload = {
      oldPassword: md5(values.oldPassword),
      newPassword: md5(values.newPassword),
    };
    try {
      const res = await Settings.changePassword(payload);
      message.success(res.message);
      passwordForm.resetFields();
    } catch (error) {
      handleError(error);
      console.error(error);
    }
  };

  const handleEditPersonalSettings = async (values) => {
    let { email, ...restValues } = values;
    try {
      const response = await Settings.updatePersonalSettings(restValues);
      message.success(response?.message);
      setPersonalSettings(response?.data);
      dispatch(changeUserGlobalDetail(userGlobalData, restValues));
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const handleGetPersonalSettings = async () => {
    try {
      const response = await Settings.getPersonalSettings();
      form.setFieldsValue(response?.data);
      setImageURL(response?.data?.profileImage);
      setPersonalSettings(response?.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleEditProfilePicture = async (file, url) => {
    let form_data = new FormData();
    form_data.append("profileImage", file);
    try {
      const response = await Settings.updateProfilePicture(form_data);
      message.success(response?.message);
      setImageURL(url);
      dispatch(
        changeUserGlobalDetail(userGlobalData, {
          profileImage: response?.data?.image,
        })
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    handleGetPersonalSettings();
  }, []);

  return (
    <>
      <div className="profile-wrap ">
        <Card className="profile-card text-center">
          <Title level={4} className="text-center">
            {personalSettings?.firstName + " " + personalSettings?.lastName}
          </Title>
          <div className="position-relative profile-upload">
            {imageURL ? (
              <Avatar size={150} src={imageURL} />
            ) : (
              <Avatar size={150} icon={<UserOutlined />} />
            )}
          </div>
          <Upload {...props}>
            <Button type="primary" size="large">
              Upload New Photo
            </Button>
          </Upload>
          <div className="profile-tag">
            <Tag>
              Maximum upload size is <span className="bold-text">2MB</span>
            </Tag>
          </div>
          <Paragraph className="mb-0">
            Member Since:
            <span className="bold-text">
              {" "}
              {epochToDateSpecialFormat(personalSettings?.createdAt)}{" "}
            </span>
          </Paragraph>
        </Card>
        <Card title="Settings" className="profile-settings app-card">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Profile Info" key="1">
              <Form
                form={form}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleEditPersonalSettings}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      className="input-label-wrap"
                      rules={[
                        {
                          required: true,
                          message: "Please input first name",
                        },

                        {
                          min: 3,
                          message: "First name must be minimum 3 characters.",
                        },
                        {
                          max: 14,
                          message:
                            "First name should be less than 15 character",
                        },
                        {
                          pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                          message:
                            "Field does not accept numbers and leading spaces",
                        },
                      ]}
                      name={"firstName"}
                      label={
                        <>
                          <div className="label-input">
                            First Name
                            <Button size="small" type="link" />
                          </div>
                        </>
                      }
                    >
                      <Input
                        suffix={<UserOutlined />}
                        placeholder="Brandon"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      className="input-label-wrap"
                      rules={[
                        {
                          required: true,
                          message: "Please input last name",
                        },
                        {
                          pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                          message:
                            "Field does not accept numbers and leading spaces",
                        },
                        {
                          min: 3,
                          message: "Last name must be minimum 3 characters.",
                        },
                        {
                          max: 14,
                          message:
                            "Last name should be less than 15 characters",
                        },
                      ]}
                      name={"lastName"}
                      label={
                        <>
                          <div className="label-input">
                            Last Name
                            <Button size="small" type="link" />
                          </div>
                        </>
                      }
                    >
                      <Input
                        type="name"
                        suffix={<UserOutlined />}
                        placeholder="Carder"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      className="email-input"
                    >
                      <Input
                        disabled
                        type="email"
                        suffix={<i className="icon-email"></i>}
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      name="phone"
                      className="input-label-wrap"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number",
                        },
                        {
                          pattern: new RegExp(phoneRegex),
                          message:
                            "Phone number must be valid and without dashes",
                        },
                      ]}
                      label={
                        <>
                          <div className="label-input">
                            Phone Number
                            <Button size="small" type="link" />
                          </div>
                        </>
                      }
                    >
                      <Input
                        type="phone"
                        suffix={<PhoneOutlined />}
                        placeholder="202-555-2849"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="form-btns">
                  <Button type="primary" onClick={() => form.submit()}>
                    Save Changes
                  </Button>
                </div>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Change Password" key="2">
              <Form
                form={passwordForm}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onChangePassword}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      label="Old Password"
                      name="oldPassword"
                      rules={[
                        {
                          required: true,
                          message: "Old Password is required",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Old Password"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Popover
                      content={<PasswordValidation password={password} />}
                      trigger={"focus"}
                      placement="bottomLeft"
                    >
                      <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "New Password is required",
                          },
                          {
                            pattern: new RegExp(passwordRegex),
                            message: "",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="New Password"
                          size="middle"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Item>
                    </Popover>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={12}>
                    <Form.Item
                      label="Confirm New Password"
                      name="confirmPassword"
                      dependencies={["newPassword"]}
                      rules={[
                        {
                          required: true,
                          message: "Confirm Password is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "The passwords does not match."
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm New Password"
                        size="middle"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="form-btns">
                  <Button type="primary" onClick={passwordForm.submit}>
                    Save Changes
                  </Button>
                </div>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    </>
  );
}
