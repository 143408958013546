import React, { useState } from "react";

// Style Imports
import "features/admin/adminManageCompanies.scss";

// React Router
import { useNavigate } from "react-router-dom";

// Custom Components
import GenericTable from "features/common/genericTable";

// Redux actions and selectors Imports
import {
  allCompanies,
  totalCompaniesCount,
  fetchAllCompanies,
} from "redux/admin/companySlice";
import { useDispatch, useSelector } from "react-redux";

// Utility functions and constants Imports
import generalConstants from "utilities/generalConstants";
import { capitalToPascal } from "utilities/generalUtility";

// Model Import
import Company from "models/company/company";

// Antd Imports
import { Card, Tag, Button, Modal, message, Typography, Tooltip } from "antd";
const { Paragraph } = Typography;

export default function AdminManageCompanies() {
  const navigate = useNavigate();

  // Redux States
  const companiesList = useSelector(allCompanies);
  const companiesTotalCount = useSelector(totalCompaniesCount);

  // States
  const [companyStatus, setCompanyStatus] = useState();
  const [modal2Open, setModal2Open] = useState(false);
  const [comapanyId, setCompanyId] = useState();
  const dispatch = useDispatch();

  const tableProps = {
    className: "app-table",
    scroll: {
      x: 900,
    },
    style: { cursor: "pointer" },
    onRow: (record, i) => {
      return {
        onClick: async (event) => {
          navigate(`admin-company-detail/${record.id}`);
        },
      };
    },
  };

  const escalationCol = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: 200,
      type: "string",
      sorter: true,
    },
    {
      title: "Company Admin Email",
      dataIndex: "primaryContactEmail",
      width: 250,
      type: "string",
      sorter: true,
    },
    {
      title: "Company Admin Number",
      dataIndex: "primaryContactPhone",
      width: 250,
      type: "string",
      sorter: true,
    },
    {
      title: "Subscription Status",
      dataIndex: "subscriptionStatus",
      width: 250,
      type: "string",
      sorter: true,
      render: (status) => (
        <Tag color={generalConstants.subscriptionStatusColors[status]}>
          {capitalToPascal(status)}
        </Tag>
      ),
    },
    {
      title: "Subscription type",
      dataIndex: "subscriptionType",
      width: 250,
      type: "string",
      sorter: true,
      render: (subscriptionType) => <p>{capitalToPascal(subscriptionType)}</p>,
    },
    {
      title: "Company Status",
      dataIndex: "isActive",
      width: 250,
      sorter: true,
      render: (status) => (
        <Tag
          color={
            generalConstants.subscriptionStatusColors[
              status ? "ACTIVE" : "DEACTIVE"
            ]
          }
        >
          {capitalToPascal(status ? "ACTIVE" : "DEACTIVE")}
        </Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 100,
      render: (_, data) => (
        <div className="actions-col">
          <Tooltip title="Active/Deactive">
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                setModal2Open(true);
                setCompanyId(data?.id);
                setCompanyStatus(data?.isActive);
              }}
            >
              <i className="icon-power"></i>
            </Button>
          </Tooltip>
          <Tooltip title="Edit Company">
            <i
              className="icon-edit-alt"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/admin-edit-company/${data?.id}`);
              }}
            ></i>
          </Tooltip>
        </div>
      ),
    },
  ];

  const toggleStatus = async () => {
    try {
      const body = {
        companyId: parseInt(comapanyId),
        active: !companyStatus,
      };
      const res = await Company.changeCompanyStatus(body);
      message.success(res?.message);
      setModal2Open(false);
      dispatch(fetchAllCompanies(generalConstants.defaultFilters));
    } catch (error) {}
  };

  return (
    <>
      <Card title="All Companies" className="app-card">
        <GenericTable
          data={companiesList}
          columns={escalationCol}
          apiCall={fetchAllCompanies}
          total={companiesTotalCount}
          tableProps={tableProps}
        />
      </Card>
      <Modal
        width={800}
        title={`${companyStatus ? "Deactivate" : "Activate"}`}
        centered
        okText="Yes"
        cancelText="No"
        visible={modal2Open}
        onOk={toggleStatus}
        onCancel={() => setModal2Open(false)}
        className="app-modal"
        closeIcon={<i className="icon-close close-icon"></i>}
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <Paragraph>{`Are you sure you want to ${
          companyStatus ? "deactivate" : "activate"
        } this company?`}</Paragraph>
      </Modal>
    </>
  );
}
