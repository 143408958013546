import React from 'react'
import { Card,Input ,Form ,Select ,Button ,Table } from 'antd';
import './adminInvite.scss';
const handleChange = (value) => {
  };
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];
  const dataSource = [
    {
      key: '1',
      name: 'jonthan@summit.com',
      action:<div className='admin-invite-action'>
      <Button size='small' type='primary'>Super Admin</Button>
      <Button size='small' ghost>Admin</Button>
      <Button size='small' type='link' icon={<i className='icon-times-circle'></i>} /> 
    </div>,
    },
    {
        key: '2',
        name: 'rickross5678@summit.com',
        action:<div className='admin-invite-action'>
        <Button size='small'  ghost>Super Admin</Button>
        <Button size='small'type='primary'>Admin</Button>
         <Button size='small' type='link' icon={<i className='icon-times-circle'></i>} /> 
      </div>,
      },
      {
        key: '3',
        name: <span>rdiagoforte43@summit.com <span className="invitation-text">(Invitation awaiting response)</span></span>,
        action: <div className='admin-invite-action'>
        <Button size='small'  ghost>Super Admin</Button>
        <Button size='small'type='primary'>Admin</Button>
         <Button size='small' type='link' icon={<i className='icon-times-circle'></i>} /> 
      </div>,
      },
  ];
export default function AdminInvite() {
    return(
        <>
        <Card className='manage-invite-card app-card'
            title="Invite Super Admin"
            bordered={false}
            >
            <Form>
                <Form.Item
                name={ 'name'}
                >
                <Input
                    placeholder="Enter Email to Invite"
                    size="middle"
                />
                </Form.Item>
                <Form.Item
                name={'industry'} className="select-role"
                >
                <Select
                size="middle"
                defaultValue="Select Role"
                onChange={handleChange}
                getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                }}
                options={[
                {
                    label: 'Manager',
                    options: [
                    {
                        label: 'Jack',
                        value: 'jack',
                    },
                    {
                        label: 'Lucy',
                        value: 'lucy',
                    },
                    ],
                },
                
                ]}
                 />
                </Form.Item>
                {/* </div> */}
                <Button type='primary'>Add</Button>
           </Form>
           <Table className='admin-invite-table' dataSource={dataSource} columns={columns} pagination={false} showHeader={false}
           scroll={{ x: 575 }} />
        </Card>
        </>
    );
}  