import React, { useEffect } from "react";
import { Typography, Tag, Modal, Row, Col, Button, Tooltip } from "antd";
import "features/managInfractions/manageInfractions.scss";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getEscalationById,
  escalationDetails,
} from "redux/infraction/infractionSlice";
import { epochToDateFormat } from "utilities/dateUtility";
import generalConstants from "utilities/generalConstants";

const { Title } = Typography;

export default function ViewInfractionDetailModal(prop) {
  const dispatch = useDispatch();
  const data = useSelector(escalationDetails);
  useEffect(() => {
    dispatch(getEscalationById(prop?.infractionId));
  }, []);

  const handleCancel = () => {
    prop?.setIsModalViewEsclationOpen(false);
    prop?.setAccomodate(false);
    prop?.setInfractionId(null);
  };

  const changeStatusFunction = (type) => {
    prop?.settype(type);
    prop?.setModalAccomodateOpen(true);
    prop?.setIsModalViewEsclationOpen(false);
    prop?.setAccomodate(false);
    prop?.setInfractionId(null);
  };

  const handlrEscalation = () => {
    prop?.setModalEsclateOpen(true);
    prop?.setIsModalViewEsclationOpen(false);
    prop?.setAccomodate(false);
  };

  return (
    <Modal
      title={
        prop?.accomodate
          ? data?.employee?.employeeStatus[0]?.employmentStatus?.name
          : "View Escalation"
      }
      centered
      width={800}
      // okText={prop?.accomodate ? "Escalate" : "Close"}
      // cancelText={prop?.accomodate ? "Accomodate" : "Back"}
      open={prop?.isModalViewEsclationOpen}
      // onOk={() => {

      // }}
      onCancel={handleCancel}
      closeIcon={<i className="icon-close"></i>}
      className="app-modal view-escalation-modal"
      cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      footer={[
        prop?.accomodate ? (
          <>
            {data?.infractionStatus?.id === 2 && (
              <Button type="primary" onClick={handleCancel}>
                Close
              </Button>
            )}

            {data?.infractionStatus?.id === 3 && (
              <Tooltip
                title={
                  data?.employee?.employeeStatus[0]?.employmentStatus?.name ===
                    "Resigned" ||
                  data?.employee?.employeeStatus[0]?.employmentStatus?.name ===
                    "Terminated"
                    ? "Can't accomodate because employee status is teminated or resigned"
                    : ""
                }
              >
                <Button
                  type="ghost"
                  onClick={() => changeStatusFunction(1)}
                  disabled={
                    data?.employee?.employeeStatus[0]?.employmentStatus
                      ?.name === "Resigned" ||
                    data?.employee?.employeeStatus[0]?.employmentStatus
                      ?.name === "Terminated"
                      ? true
                      : false
                  }
                >
                  Accommodate
                </Button>
              </Tooltip>
            )}
            {(data?.infractionStatus?.id === 1 ||
              data?.infractionStatus?.id === 3) && (
              <Tooltip
                title={
                  data?.employee?.employeeStatus[0]?.employmentStatus?.name ===
                    "Resigned" ||
                  data?.employee?.employeeStatus[0]?.employmentStatus?.name ===
                    "Terminated"
                    ? "Can't escalate because employee status is teminated or resigned"
                    : ""
                }
              >
                <Button
                  type="primary"
                  onClick={handlrEscalation}
                  disabled={
                    data?.employee?.employeeStatus[0]?.employmentStatus
                      ?.name === "Resigned" ||
                    data?.employee?.employeeStatus[0]?.employmentStatus
                      ?.name === "Terminated"
                      ? true
                      : false
                  }
                >
                  Escalate
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <Button type="primary" onClick={handleCancel}>
            Close
          </Button>
        ),
      ]}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div className="description-title">
            <Title level={2}>{`${data?.employee?.firstName} ${
              data?.employee?.lastName ?? ""
            }`}</Title>
            <div className="desc-info">
              <p>
                Date of Infraction: {epochToDateFormat(data?.infractionDate)}
              </p>
            </div>
          </div>
        </Col>
        <Col sm={19} md={19}>
          <div className="esculation-info">
            <Title>Employee Status:</Title>
            <Tag
              color={
                generalConstants.employeeStatusColors[
                  data?.employee?.employeeStatus[0]?.employmentStatus?.name
                ]
              }
            >
              {data?.employee?.employeeStatus[0]?.employmentStatus?.name}
            </Tag>
          </div>
        </Col>
        <Col sm={5} md={5}>
          <div className="esculation-info">
            <Title>Infraction Status:</Title>
            <Tag
              color={
                generalConstants.infractionStatusColors[
                  data?.infractionStatus?.name
                ]
              }
            >
              {data?.infractionStatus?.name}
            </Tag>
          </div>
        </Col>
        <Col sm={19} md={19}>
          <div className="esculation-info">
            <Title>Infraction Type:</Title>
            <p>{data?.infractionType?.name}</p>
          </div>
        </Col>
        <Col sm={5} md={5}>
          <div className="esculation-info">
            <Title>Notice:</Title>
            <p>
              <span>
                <i
                  className={data?.notice ? "icon-tick" : "icon-times-circle"}
                ></i>
              </span>
              {/* {data?.infractionType?.notice} */}
            </p>
          </div>
        </Col>
        <Col sm={19} md={19}>
          <div className="esculation-info">
            <Title>Status Start Date:</Title>
            <p>
              {epochToDateFormat(data?.employee?.employeeStatus[0]?.startDate)}
            </p>
          </div>
        </Col>
        <Col sm={5} md={5}>
          <div className="esculation-info">
            <Title>Status End Date:</Title>
            <p>
              {epochToDateFormat(data?.employee?.employeeStatus[0]?.endDate)}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <div className="esculation-info">
            <Title>Comments:</Title>
            <p>{data?.comments}</p>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
