// import react & redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allEmployeeStatuses,
  fetchAllEmployeesList,
  fetchEmployeeStatuses,
} from "redux/employee/employeeSlice";
import { employeeDetail } from "redux/employee/employeeSlice";
import { getEmployeeDetailById } from "redux/employee/employeeSlice";
import {
  saveIconColorFilterFlag,
  saveSearchFilterIndicator,
  saveSortFilterIndicator,
} from "redux/user/userSlice";

// import antd
import {
  Modal,
  Row,
  Col,
  Form,
  Select,
  Input,
  DatePicker,
  message,
} from "antd";

// import utilities
import { setFieldErrorsFromServer } from "utilities/generalUtility";
import {
  dateToEpochFormat,
  endDateToEpochFormat,
  epochToMomentTime,
} from "utilities/dateUtility";
import {
  onlyAlphabetsAndSpecialChars,
  phoneRegex,
} from "utilities/regexUtility";

// import models
import Employee from "models/employee/employee";

// import router-dom
import { useParams } from "react-router-dom";

// import components
import EmployeeProfileImage from "./employeeProfileImage";
import moment from "moment";

// import constants
import generalConstants from "utilities/generalConstants";

export default function AddEditEmployeeModal(props) {
  // states, hooks & variables
  const employeeData = useSelector(employeeDetail);
  const { employeeId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const employeeStatusList = useSelector(allEmployeeStatuses);
  const [rangeRequired, setRangeRequired] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [joiningDate, setJoiningDate] = useState(moment());
  const [probationStartDate, setProbationStartDate] = useState(null);
  const [probationEndDate, setProbationEndDate] = useState(null);
  const dateFormat = "MM/DD/YYYY";
  // const [firstDate, setFirstDate] = useState(moment());
  // const [secondDate, setSecondDate] = useState();phoneRegex

  // functions
  const handleJoiningDateChange = (date) => {
    setJoiningDate(date);
    setProbationStartDate(null);
    setProbationEndDate(null);
    form.setFieldsValue({ statusStartDate: null, statusEndDate: null });
  };

  const handleProbationStartDateChange = (date) => {
    setProbationStartDate(date);
    setProbationEndDate(null);
    form.setFieldsValue({ statusEndDate: null });
  };

  const handleProbationEndDateChange = (date) => {
    setProbationEndDate(date);
  };

  const isJoiningDateDisabled = (current) => {
    return (
      probationStartDate && current && current < moment(probationStartDate)
    );
  };

  const isProbationStartDateDisabled = (current) => {
    return joiningDate && current && current < moment(joiningDate);
  };

  const isProbationEndDateDisabled = (current) => {
    return (
      (joiningDate && current && current < moment(joiningDate)) ||
      (probationStartDate && current && current < moment(probationStartDate))
    );
  };
  const handleAddEditEmployee = async (values) => {
    // converting to epoch format
    values.joiningDate = values.joiningDate
      ? dateToEpochFormat(values.joiningDate)
      : null;
    values.statusStartDate = values.statusStartDate
      ? dateToEpochFormat(values.statusStartDate)
      : null;
    values.statusEndDate = values.statusEndDate
      ? endDateToEpochFormat(values.statusEndDate)
      : null;

    values.photo = imageFile;
    let form_data = new FormData();
    for (let key in values) {
      form_data.append(
        key,
        key === "firstName" || key === "lastName"
          ? values[key].trim()
          : values[key]
      );
    }
    // post-edit employee
    try {
      if (employeeId) {
        await Employee.EditEmployee(employeeData?.id, form_data);
        message.success("Employee Updated Successfully");
        dispatch(getEmployeeDetailById(employeeData?.id));
      } else {
        await Employee.AddEmployee(form_data);
        message.success("Employee Added Successfully");
        dispatch(fetchAllEmployeesList(generalConstants.defaultFilters));
      }
      props?.setIsAddEditEmployeeModalOpen(false);
      setImageFile(null);
      form.resetFields();
      dispatch(saveSearchFilterIndicator(false));
      dispatch(saveSortFilterIndicator(false));
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const handleEmployeeStatusChange = (value, option) => {
    setRangeRequired(option ? !option.rangeRequired : true);
  };

  // useEffects
  useEffect(() => {
    dispatch(fetchEmployeeStatuses());
    employeeId && dispatch(getEmployeeDetailById(employeeId));
  }, []);

  useEffect(() => {
    if (employeeStatusList?.length && employeeData && employeeId) {
      let employee = {
        ...employeeData,
        statusId: employeeData?.status?.id,
        statusStartDate: epochToMomentTime(employeeData?.status?.startDate),
        statusEndDate: epochToMomentTime(employeeData?.status?.endDate),
        joiningDate: epochToMomentTime(employeeData?.joiningDate),
      };
      console.log({ employee });
      setJoiningDate(epochToMomentTime(employeeData?.joiningDate));
      setProbationStartDate(epochToMomentTime(employeeData?.status?.startDate));
      setProbationEndDate(epochToMomentTime(employeeData?.status?.endDate));
      form.setFieldsValue(employee);
      const initialRangeRequired = employeeStatusList?.find(
        (data) => data?.id === employeeData?.status?.id
      )?.rangeRequired;
      setRangeRequired(employeeId ? !initialRangeRequired : true);
      setImageFile(employeeData?.imageUrl);
    }
  }, [employeeData]);

  return (
    <div>
      <Modal
        title={`${employeeId ? "Edit" : "Add"} Employee`}
        centered
        width={800}
        okText={`${employeeId ? "Save Changes" : "Add"}`}
        cancelText="Cancel"
        destroyOnClose={true}
        open={props?.isAddEditEmployeeModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          props?.setIsAddEditEmployeeModalOpen(false);
          form.resetFields();
          setImageFile();
          setJoiningDate(moment());
          setProbationStartDate(null);
          setProbationEndDate(null);
        }}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal upload-csv add-employee profile-settings"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
        bodyStyle={{ paddingBottom: "0px" }}
      >
        <EmployeeProfileImage
          setImageFile={setImageFile}
          imageFile={employeeId ? employeeData?.imageUrl : null}
        />
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={handleAddEditEmployee}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name:"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "please input your first name",
                  },

                  {
                    min: 3,
                    message: "first name must be minimum 3 characters.",
                  },
                  {
                    max: 14,
                    message: "first name should be less than 15 character",
                  },
                  {
                    pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                    message: "Field does not accept numbers and leading spaces",
                  },
                ]}
              >
                <Input placeholder="Skylar Madsen" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name:"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "please input your last name",
                  },

                  {
                    min: 3,
                    message: "last name must be minimum 3 characters.",
                  },
                  {
                    max: 14,
                    message: "last name should be less than 15 character",
                  },
                  {
                    pattern: new RegExp(onlyAlphabetsAndSpecialChars),
                    message: "Field does not accept numbers,special chracters",
                  },
                ]}
              >
                <Input placeholder="Skylar Madsen" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Email:"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "please input your email",
                  },
                  {
                    type: "email",
                    message: "email must be valid",
                  },
                ]}
              >
                <Input placeholder="lnguyen@aol.com" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Phone Number:"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "please input your phone number",
                  },
                  {
                    pattern: new RegExp(phoneRegex),
                    message: "phone number must be valid",
                  },
                ]}
              >
                <Input placeholder="202-555-0310" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Joining Date:"
                name="joiningDate"
                rules={[
                  {
                    required: true,
                    message: "please select joining date",
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  // disabledDate={isJoiningDateDisabled}
                  onChange={handleJoiningDateChange}
                  placeholder="Select joining date"
                  suffixIcon={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"statusId"}
                label="Current Status:"
                rules={[
                  {
                    required: true,
                    message: "please select status",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  placeholder="Select Status"
                  allowClear
                  onChange={handleEmployeeStatusChange}
                  options={employeeStatusList?.map((element) => {
                    return {
                      label: element.name,
                      value: element.id,
                      rangeRequired: element.rangeRequired,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Status Start Date:"
                name="statusStartDate"
                rules={[
                  {
                    required: rangeRequired ? false : true,
                    message: "please select start date",
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  disabled={rangeRequired}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  suffixIcon={false}
                  disabledDate={isProbationStartDateDisabled}
                  onChange={handleProbationStartDateChange}
                  placeholder="Select start date"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Status End Date:"
                name="statusEndDate"
                rules={[
                  {
                    required: rangeRequired ? false : true,
                    message: "please select end date",
                  },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  disabled={rangeRequired}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabledDate={isProbationEndDateDisabled}
                  onChange={handleProbationEndDateChange}
                  placeholder="Select end date"
                  suffixIcon={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
