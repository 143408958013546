// import react & redux
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  allEmployees,
  fetchAllEmployeesList,
} from "redux/employee/employeeSlice";

// import antd
import { Card, Button, Tag } from "antd";

// import styles
import "features/manageEmployees/manageEmployees.scss";

// import modal
import AddEditEmployeeModal from "./addEditEmployeeModal";
import BulkUploadModal from "./bulkUploadModal";
import MarkInfractionModal from "features/common/markInfractionModal";

// import utilities
import generalConstants from "utilities/generalConstants";

// import components
import CountInfractionListing from "features/common/countInfractionListing";
import AccommodateInfactionModal from "features/common/accomodateModal";
import GenericTable from "features/common/genericTable";
import EscalationModal from "features/common/escalationModal";

export default function ManageEmployees() {
  // states, variables & hooks
  const navigate = useNavigate();
  const employee = useSelector(allEmployees);
  const [employeeId, setEmployeeId] = useState(null);
  const [infractionId, setinfractionId] = useState();
  const [modalCountInfractionOpen, setCountInfractionOpen] = useState(false);
  const [modalUploadCsvOpen, setModalUploadCsvOpen] = useState(false);
  const [isAddEditEmployeeModalOpen, setIsAddEditEmployeeModalOpen] =
    useState(false);
  const [modalMarkInfractionOpen, setModalMarkInfractionOpen] = useState(false);
  const [modalAccomodateOpen, setModalAccomodateOpen] = useState(false);
  const [modalEsclateOpen, setModalEsclateOpen] = useState(false);
  const [InfractionTypeId, setInfractionTypeId] = useState();

  const tableProps = {
    className: "app-table employees-table",
    scroll: {
      x: 900,
    },
    style: { cursor: "pointer" },
    onRow: (record, i) => {
      return {
        onClick: async (event) => {
          navigate(`/manage-employees/supervisor-employees/${record.id}`);
        },
      };
    },
  };

  const employeeCol = [
    {
      title: "First Name",
      dataIndex: "firstName",
      type: "string",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      type: "string",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      type: "string",
      sorter: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      type: "string",
      sorter: true,
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      type: "string",
      sorter: true,
      render: (_, data) => (
        <Tag color={generalConstants.employeeStatusColors[data?.currentStatus]}>
          {data?.currentStatus}
        </Tag>
      ),
    },
    {
      title: "Joining Date",
      dataIndex: "joiningDate",
      type: "range",
      sorter: true,
    },
    {
      title: "Start Date",
      dataIndex: "statusStartDate",
      type: "range",
      sorter: true,
    },
    {
      title: "End Date",
      dataIndex: "statusEndDate",
      type: "range",
      sorter: true,
    },
    {
      title: "Infraction Count",
      dataIndex: "infractionCount",
      align: "center",
    },
  ];
  return (
    <>
      <Card
        title="All Employees"
        className="app-card employees-card"
        extra={
          <>
            <Button
              type="primary"
              className="emp-btn"
              onClick={() => setModalUploadCsvOpen(true)}
            >
              {"Bulk Upload"}
              {/* <i className="icon-user-plus"></i> */}
            </Button>
            <Button
              type="default"
              className="ant-btn-background-ghost"
              onClick={() => setIsAddEditEmployeeModalOpen(true)}
            >
              Add Employee
            </Button>
            <Button
              type="primary"
              onClick={() => setModalMarkInfractionOpen(true)}
            >
              Mark Infraction
            </Button>
          </>
        }
      >
        <GenericTable
          data={employee.allEmployees}
          columns={employeeCol}
          apiCall={fetchAllEmployeesList}
          total={employee?.totalEmployeesCount?.totalCount}
          tableProps={tableProps}
        />
      </Card>
      <BulkUploadModal
        modalUploadCsvOpen={modalUploadCsvOpen}
        setModalUploadCsvOpen={setModalUploadCsvOpen}
      />
      {isAddEditEmployeeModalOpen && (
        <AddEditEmployeeModal
          isAddEditEmployeeModalOpen={isAddEditEmployeeModalOpen}
          setIsAddEditEmployeeModalOpen={setIsAddEditEmployeeModalOpen}
          filters={generalConstants.defaultFilters}
        />
      )}

      {modalMarkInfractionOpen && (
        <MarkInfractionModal
          open={modalMarkInfractionOpen}
          setOpen={setModalMarkInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          setEmployeeId={setEmployeeId}
          setinfractionId={setinfractionId}
          setInfractionTypeId={setInfractionTypeId}
        />
      )}
      {modalCountInfractionOpen && (
        <CountInfractionListing
          modalCountInfractionOpen={modalCountInfractionOpen}
          setCountInfractionOpen={setCountInfractionOpen}
          employeeId1={employeeId}
          infractionId={infractionId}
          setModalAccomodateOpen={setModalAccomodateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
          employeeMode={true}
        />
      )}

      {modalAccomodateOpen && (
        <AccommodateInfactionModal
          statusId={infractionId}
          type={1}
          filters={generalConstants.defaultFilters}
          setModalAccomodateOpen={setModalAccomodateOpen}
          modalAccomodateOpen={modalAccomodateOpen}
          employeeMode={true}
        ></AccommodateInfactionModal>
      )}
      {modalEsclateOpen && (
        <EscalationModal
          modalEsclateOpen={modalEsclateOpen}
          setModalEsclateOpen={setModalEsclateOpen}
          infractionTypeId={InfractionTypeId}
          infractionId={infractionId}
          employeeMode={true}
        ></EscalationModal>
      )}
    </>
  );
}
