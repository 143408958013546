import { Column } from "@ant-design/plots";
import { Button, Card, Col } from "antd";

export default function RevenueGenerated({ config }) {
  return (
    <Col xs={24} sm={24} md={12}>
      <Card title="Revenue Generated" className="app-card graph-card">
        <Column {...config} />
      </Card>
    </Col>
  );
}
