import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Card,
  Button,
  Table,
  Modal,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  message,
  Upload,
} from "antd";
import "features/settings/companyPolicy/companyPolicy.scss";
import "react-quill/dist/quill.snow.css";
import { companypolicy, getCompanyPolicy } from "redux/settings/settingsSlice";
import { leadingSpacesRegex } from "utilities/regexUtility";
import NetworkCall from "network/networkCall";
import settingServices from "network/services/settingServices";
import { handleError } from "utilities/generalUtility";
import { useSelector } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import { epochToDateFormatWithTime } from "utilities/dateUtility";
import { useForm } from "antd/lib/form/Form";
const { TextArea } = Input;

export default function CompanyPolicy() {
  const [modalUploadCsvOpen, setModalUploadCsvOpen] = useState(false);
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [title, setTitle] = useState();
  const dispatch = useDispatch();
  const companyPolicyData = useSelector(companypolicy);
  const [form] = useForm();

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const onChangetitle = (e) => {
    setTitle(e.target.value);
  };

  const [pdfFile, setpdfFile] = useState();
  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: true,

    maxCount: 1,
    accept: ".pdf",

    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload pdf file!");
        form.resetFields();
        return false;
      }
      return false;
    },

    onChange: async (info) => {
      const isLt5M = info?.file?.size / 1024 / 1024 <= 5;
      if (!isLt5M) {
        message.error("File must smaller than 5MB!");
        form.resetFields();
        return false;
      } else {
        setpdfFile(info?.file);
      }
    },

    onDrop: (event) => {
      event.preventDefault();
      setpdfFile(event?.DataTransfer?.files[0]);
    },
  };

  const handleUploadCompanyPolicy = async () => {
    let form_data = new FormData();
    form_data.append("document", pdfFile);
    form_data.append("name", title);
    form_data.append("includeInNotice", checked);
    if (title && pdfFile) {
      try {
        const response = await NetworkCall.fetch(
          settingServices.addCompanyPolicy(form_data)
        );
        setModalUploadCsvOpen(false);
        message.success(response.message);
        dispatch(getCompanyPolicy());
        form.resetFields();
      } catch (error) {
        handleError(error);
      }
    }
  };
  const companyPolicyCol = [
    {
      title: "Document Name",
      dataIndex: "name",
      width: 200,

      render: (name, url) => (
        <p className="doucument-col">
          <a href={url?.url} target="_blank">
            <i className="icon-document-info"></i>
            {name}
          </a>
        </p>
      ),
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      width: 100,
      render: (createdAt) => <p>{epochToDateFormatWithTime(createdAt)}</p>,
    },
    {
      title: "File Size",
      dataIndex: "fileSize",
      width: 200,

      render: (fileSize, url) => (
        <div className="file-size-col">
          <p>{fileSize}</p>
          <span>
            <a href={url?.url} target="_blank">
              <i className="icon-outlink"></i>
            </a>
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getCompanyPolicy());
  }, []);

  return (
    <>
      <Card
        title="Company Documents"
        className="app-card company-policy-card"
        extra={
          <>
            <Button type="primary" onClick={() => setModalUploadCsvOpen(true)}>
              Add New Document
            </Button>
          </>
        }
      >
        <Table
          className="app-table comapny-policy-table"
          columns={companyPolicyCol}
          dataSource={companyPolicyData.length && companyPolicyData}
          pagination={false}
          scroll={{ x: 900 }}
        />
      </Card>
      <Modal
        title="Add New Document"
        centered
        width={800}
        okText="Add"
        cancelText="Cancel"
        open={modalUploadCsvOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          setModalUploadCsvOpen(false);
          form.resetFields();
        }}
        closeIcon={<i className="icon-close"></i>}
        className="app-modal upload-csv add-new"
        cancelButtonProps={{ className: "ant-btn-background-ghost" }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleUploadCompanyPolicy}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"document"}
                rules={[
                  {
                    required: true,
                    message: "Please Add File",
                  },
                ]}
              >
                <Dragger {...uploadProps} className="uc-content">
                  <div>
                    <span>
                      <i className="icon-cloud"></i>
                    </span>
                    <p>Drop file to upload or browse</p>
                  </div>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label="Document Title"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your title",
                  },

                  {
                    pattern: new RegExp(leadingSpacesRegex),
                    message: "Leading Spaces Not Allowed",
                  },
                ]}
              >
                <Input placeholder="Name" onChange={onChangetitle} />
              </Form.Item>
              <Form.Item>
                <Checkbox onChange={onChange}>Include in notice</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
