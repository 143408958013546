import { Table } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopOffenders, topOffenders } from "redux/dashboard/dasboardSlice";

export default function TopOffendersList({ startDate, endDate, tab }) {
  const offendersRow = useSelector(topOffenders);
  const dispatch = useDispatch();

  const offendersCol = [
    {
      title: "Employee Name",
      dataIndex: "firstName",
      render: (_, data) => (
        <p className="offender-col-info">
          {data?.firstName + " " + data?.lastName}
        </p>
      ),
    },

    {
      title: "Total Number of Infractions",
      dataIndex: "InfractionCount",
      render: (InfractionCount) => (
        <p className="offender-col-info">{InfractionCount}</p>
      ),
    },
  ];
  const getAllTopOffenders = () => {
    try {
      const body = {
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(fetchTopOffenders(body));
    } catch (error) {}
  };

  useEffect(() => {
    tab === "2" && getAllTopOffenders();
  }, [startDate, endDate, tab]);
  return (
    <Table
      columns={offendersCol}
      dataSource={offendersRow}
      className="app-table"
      pagination={false}
      scroll={{
        x: 900,
      }}
    />
  );
}
